import './styles.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, AvatarButton } from '@edx/paragon';
import classNames from 'classnames';
import {
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import { getLmsLink } from '@utils/edxLinks';
import NewBadge from '@prospectus/common/ui/NewBadge';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import enterprisePortalLinkShape from '../../enterprisePortalLinkShape';

const messages = defineMessages({
  'prospectus.header.site_header.logged_in_user_menu.dashboard_label': {
    id: 'prospectus.header.site_header.logged_in_user_menu.dashboard_label',
    defaultMessage: 'Dashboard',
    description: 'A link to the user dashboard.',
  },
  'prospectus.header.site_header.logged_in_user_menu.account_label': {
    id: 'prospectus.header.site_header.logged_in_user_menu.account_label',
    defaultMessage: 'Account',
    description: 'A link to the user account settings.',
  },
  'prospectus.header.site_header.logged_in_user_menu.career_link_text': {
    id: 'prospectus.header.site_header.logged_in_user_menu.career_link_text',
    defaultMessage: 'Career',
    description:
      'Link text for the Career link in the menu for logged-in users',
  },
});

function UserMenu({
  onIsClient,
  intl,
  userDetails,
  userEnterprisePortalLinks,
  userLoggedIn,
  alignDropdownRight,
  className,
  showMegaNav,
}) {
  const [isClient, setIsClient] = useState(false);

  const markComponentAsRendered = () => {
    setIsClient(true);
    onIsClient(true);
    // SpeedCurve render tracking
    if (
      'performance' in window
      && Object.prototype.hasOwnProperty.call(performance, 'mark')
      && performance.mark !== undefined
    ) {
      performance.mark('user menu rendered');
    }
  };

  useEffect(() => {
    markComponentAsRendered();
  }, []);

  const linkClick = (event, label = event.currentTarget.innerText) => {
    window.analytics.track('edx.bi.link.header_navigation.clicked', {
      category: 'navigation',
      label,
      link: event.currentTarget.href,
      mobile: false,
    });
  };

  const getResumeBlock = (resumeBlockURL) => (
    <ProspectusLink
      href={resumeBlockURL}
      className="btn btn-tertiary w-100 text-left dropdown-item"
      onClick={linkClick}
      analytics={{
        eventName: 'edx.bi.user.dropdown.resume-course.clicked',
      }}
    >
      <FormattedMessage
        id="prospectus.header.site_header.logged_in_user_menu.resume_last_course_link_text"
        defaultMessage="Resume your last course"
        description="Text for the link to 'Resume your last course' in the logged-in user menu"
      />
    </ProspectusLink>
  );

  if (!isClient) {
    return <div key="server" />;
  }

  const hasResumeCourseURL = userDetails
    && userDetails.header_urls
    && userDetails.header_urls.resume_block;

  let dashboardLinks = [
    <ProspectusLink
      key="dashboard-home-lms"
      href={getLmsLink('/dashboard')}
      className="btn btn-tertiary w-100 text-left dropdown-item"
      onClick={linkClick}
      analytics={{
        eventName: 'edx.bi.user.dropdown.dashboard.clicked',
      }}
    >
      <FormattedMessage
        id="prospectus.header.site_header.logged_in_user_menu.dashboard_link_text"
        defaultMessage="Dashboard"
        description="Link text for the Dashboard link in the menu for logged-in users"
      />
    </ProspectusLink>,
  ];

  if (userEnterprisePortalLinks.length > 0) {
    dashboardLinks = userEnterprisePortalLinks.map(({ url, title }) => (
      <ProspectusLink
        key={`${title}${url}`}
        href={url}
        className="btn btn-tertiary w-100 text-left dropdown-item"
        onClick={linkClick}
        analytics={{
          eventName: 'edx.bi.user.dropdown.user-enterprise-portal-link.clicked',
        }}
      >
        <FormattedMessage
          id="prospectus.header.site_header.logged_in_user_menu.enterprise_dashboard_label"
          defaultMessage="{enterpriseName} Dashboard"
          values={{ enterpriseName: title }}
          description="Link text for the Dashboard link in the menu for logged-in users"
        />
      </ProspectusLink>
    ));
  } else {
    dashboardLinks.push(
      <ProspectusLink
        href="https://careers.edx.org/"
        className="btn btn-tertiary w-100 text-left dropdown-item"
        onClick={linkClick}
        analytics={{
          eventName: 'edx.bi.user.dropdown.career.clicked',
        }}
      >
        {intl.formatMessage(
          messages[
            'prospectus.header.site_header.logged_in_user_menu.career_link_text'
          ],
        )}
        <NewBadge className="mx-2" />
      </ProspectusLink>,
    );
  }

  return (
    <div key={isClient ? 'client' : 'server'} className={className}>
      {userLoggedIn && userDetails && (
        <Dropdown
          className={classNames('user-menu', {
            'd-lg-flex': showMegaNav,
          })}
        >
          <Dropdown.Toggle
            as={AvatarButton}
            size="md"
            src={
              userDetails.user_image_urls
              && Object.keys(userDetails.user_image_urls).length > 0
                ? userDetails.user_image_urls.medium
                : ''
            }
          >
            <span className="sr-only">
              <FormattedMessage
                id="prospectus.header.site_header.logged_in_user_menu.screen_reader_menu_overview_text"
                defaultMessage="User options"
                description="Screen reader text describing the logged-in user menu"
              />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu alignRight={alignDropdownRight}>
            {hasResumeCourseURL
              && getResumeBlock(userDetails.header_urls.resume_block)}
            {dashboardLinks}
            <ProspectusLink
              href={
                userDetails && userDetails.header_urls
                  ? userDetails.header_urls.learner_profile
                  : ''
              }
              className="btn btn-tertiary w-100 text-left dropdown-item"
              onClick={linkClick}
              analytics={{
                eventName: 'edx.bi.user.dropdown.profile.clicked',
              }}
            >
              <FormattedMessage
                id="prospectus.header.site_header.logged_in_user_menu.profile_link_text"
                defaultMessage="Profile"
                description="Link text for the Profile link in the menu for logged-in users"
              />
            </ProspectusLink>
            <ProspectusLink
              href={getLmsLink('/account/settings')}
              className="btn btn-tertiary w-100 text-left dropdown-item"
              onClick={linkClick}
              analytics={{
                eventName: 'edx.bi.user.dropdown.account.clicked',
              }}
            >
              <FormattedMessage
                id="prospectus.header.site_header.logged_in_user_menu.account_link_text"
                defaultMessage="Account"
                description="Link text for the Account link in the menu for logged-in users"
              />
            </ProspectusLink>
            <ProspectusLink
              href={getLmsLink('/logout')}
              className="btn btn-tertiary w-100 text-left dropdown-item"
              onClick={linkClick}
              analytics={{
                eventName: 'edx.bi.user.dropdown.logout.clicked',
              }}
            >
              <FormattedMessage
                id="prospectus.header.site_header.logged_in_user_menu.sign_out_link_text"
                defaultMessage="Sign Out"
                description="Link text for the Sign-out link in the menu for logged-in users"
              />
            </ProspectusLink>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {userLoggedIn && !userDetails && (
        <nav
          className={classNames('user-cta', {
            'd-lg-flex': showMegaNav,
          })}
          aria-label={intl.formatMessage(
            messages[
              'prospectus.header.site_header.logged_in_user_menu.dashboard_label'
            ],
          )}
        >
          <ProspectusLink
            href={getLmsLink('/dashboard')}
            className="btn btn-blue dropdown-item"
            onClick={linkClick}
            analytics={{
              eventName: 'edx.bi.user.dashboard.link.clicked',
            }}
          >
            <FormattedMessage
              id="prospectus.header.site_header.logged_in_user_menu.dashboard_link_text"
              defaultMessage="Dashboard"
              description="Link text for the Dashboard link in the menu for logged-in users"
            />
          </ProspectusLink>
        </nav>
      )}
      {!userLoggedIn && (
        <nav
          className={classNames('user-cta', {
            'd-lg-flex': showMegaNav,
          })}
          aria-label={intl.formatMessage(
            messages[
              'prospectus.header.site_header.logged_in_user_menu.account_label'
            ],
          )}
        >
          <ProspectusLink
            href={getLmsLink('/login')}
            className="btn btn-tertiary user-link sign-in-link"
            onClick={linkClick}
            analytics={{
              eventName: 'edx.bi.user.login.clicked',
            }}
          >
            <FormattedMessage
              id="prospectus.header.site_nav.user_menu.sign_in"
              defaultMessage="Sign in"
              description="Sign in link text in the user portion of the menu - when the user is logged out"
            />
          </ProspectusLink>

          <ProspectusLink
            href={getLmsLink('/register')}
            className="btn btn-brand user-link mx-1 register-link"
            onClick={linkClick}
            analytics={{
              eventName: 'edx.bi.user.register.clicked',
            }}
          >
            <div className="register-link-text">
              <FormattedMessage
                id="prospectus.header.site_nav.user_menu.register.for.free"
                defaultMessage="Register for free"
                description="Text for a link that will lead the user to our registration flow"
              />
            </div>
          </ProspectusLink>
        </nav>
      )}
    </div>
  );
}

UserMenu.propTypes = {
  userLoggedIn: PropTypes.bool,
  userDetails: PropTypes.oneOfType([
    PropTypes.shape({
      username: PropTypes.string,
      header_urls: PropTypes.shape({
        account_settings: PropTypes.string,
        learner_profile: PropTypes.string,
        logout: PropTypes.string,
        resume_block: PropTypes.string,
      }),
      image_urls: PropTypes.shape({
        small: PropTypes.string,
      }),
    }),
    PropTypes.bool,
  ]),
  intl: intlShape.isRequired,
  userEnterprisePortalLinks: PropTypes.arrayOf(
    PropTypes.shape(enterprisePortalLinkShape),
  ),
  alignDropdownRight: PropTypes.bool,
  className: PropTypes.string,
  onIsClient: PropTypes.func,
  showMegaNav: PropTypes.bool,
};

UserMenu.defaultProps = {
  userLoggedIn: false,
  userDetails: false,
  userEnterprisePortalLinks: [],
  alignDropdownRight: true,
  className: '',
  onIsClient: undefined,
  showMegaNav: true,
};

export default injectIntl(UserMenu);
