// Disable no-danger because we pull in the footer and load the HTML directly
/* eslint react/no-danger: 0 */

import '@prospectus/common/styles/global.scss';
import React, { useRef, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { isEmpty } from 'lodash';
import track from 'react-tracking';
import { useLocation } from '@gatsbyjs/reach-router';
import { defineMessages, injectIntl } from 'gatsby-plugin-react-intl';

import SitewideBanner, {
  SitewideBannerProps,
} from '@prospectus/common/ui/SitewideBanner';
import { allCurrencyShape } from '@prospectus/common/types/allCurrencyShape';
import Header from '@prospectus/common/ui/Header';
import Footer from '@prospectus/common/ui/Footer';
import config from '@config';
import { setAutoCompletePreQuery } from '@data/actions/autoComplete';
import setShowBootCampsNavLink from '@data/actions/bootCamps';
import { intlShape } from '@prospectus/common/types/intlShape';
import getOptimizelyProps from '@utils/thirdParty';
import { i18nEnabled, redirectToUserLanguage } from '@utils/i18n';
import { SiteNavContext } from '@utils/context';
import { getUserLocation } from '@utils/location';
import { getAutoCompletePreQuery } from '@utils/search';

// import countries from 'iso-3166-1/dist/iso-3166';
import { getLocationRestrictionMaps } from '@build-utils/helpers/productLists';

// XPERT related experiments
import ChatFloatingActionButton from '@prospectus/common/ui/xpert/experiments/ChatFloatingActionButton';
import { SURVEY } from '@prospectus/common/ui/xpert/experiments/ChatFloatingActionButton/ChatbotType';
import SkillsBuilderFloatingActionButton from '@prospectus/common/ui/xpert/experiments/SkillsBuilderFloatingActionButton';
import OCMFloatingActionButton from '@prospectus/common/ui/xpert/experiments/OCMFloatingActionButton';
import ZendeskChat from '@prospectus/common/ui/xpert/experiments/ZendeskChat';
import XpertChatbot from '@prospectus/common/ui/xpert/XpertChatbot';

import { MegaNavProvider } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import VerticalNavigationMenu from '@prospectus/common/ui-strict/MegaNav/components/VerticalNavigationMenu';

// Make sure this is before any other `fontawesome` API calls
const TAXI_FORM_SCRIPT_HREF = 'https://prospect-form-plugin.2u.com/cask-latest/lead.form.plugin.js';

function BaseTemplateWrapper({
  children,
  optimizely,
  mainClassname,
  pageClassname,
  title,
  intl,
  userLanguage,
  lang,
  allCurrency,
  webview,
  allCourses,
  bootcampsLinkCardlists,
  saveShowBootCampsNavLink,
  removeTrailingCompanyName, // note, this should only be used if 100% necessary
  hideHeaderAndFooter,
  requiresTaxiFormScript,
  customHeader: CustomHeader,
  customFooter: CustomFooter,
  location,
  tracking,
  saveAutoCompletePreQuery,
  autoCompletePreQuery,
  sitewideBanners: { edges: bannerEdges },
}) {
  const [checkUserLanguage, setCheckUserLanguage] = useState(false);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const [mobileSearchbarIsOpen, setMobileSearchbarIsOpen] = useState(false);
  const mainContentRef = useRef(null);
  const gatsbyLocation = useLocation();
  const [showMegaNav] = useState(true);

  const enableI18n = i18nEnabled();
  const { href } = location;

  const compareUserLanguage = () => {
    redirectToUserLanguage(window.location, intl.locale, userLanguage);
  };

  const activateUserLanguageCheck = () => {
    setCheckUserLanguage(true);
    compareUserLanguage();
  };

  const shouldShowBootCampsNavLink = (cardLists, courses) => {
    // We do not want to show the boot camps nav link if, after filtering based on the user's county,
    // the user would not see any cards in the page's card list.
    const userCountry = getUserLocation();
    // If cloudflare didn't set the user country, show the BC navlink.
    if (!userCountry) {
      return true;
    }

    // Get all the uuids of the Bootcamps that would be rendered as a Card in a CardList on the BC container page.
    const bootCampsPageCardsUuids = cardLists.reduce(
      (cardList, module) => cardList.concat(module.cardList),
      [],
    );

    // get all of the course restrictions
    const [courseLocationRestrictions] = getLocationRestrictionMaps(courses.edges.map((edge) => edge.node));
    // using a for-loop so we can bail once we find a BC the user can view
    for (let i = 0; i < bootCampsPageCardsUuids.length; i++) {
      const uuid = bootCampsPageCardsUuids[i];
      if (
        courseLocationRestrictions.allowlist[uuid]
        && courseLocationRestrictions.allowlist[uuid].countries?.includes(
          userCountry,
        )
      ) {
        return true;
      }
      if (
        courseLocationRestrictions.blocklist[uuid]
        && !courseLocationRestrictions.blocklist[uuid].countries?.includes(
          userCountry,
        )
      ) {
        return true;
      }
    }
    // return false if none of the cards on the boot camps page are viewable in the user's country
    return false;
  };

  useEffect(() => {
    if (enableI18n) {
      activateUserLanguageCheck();
    }

    saveAutoCompletePreQuery(autoCompletePreQuery);
    // we're saving this to redux so that the programsSubmenu knows whether or display the link or not.
    saveShowBootCampsNavLink(
      shouldShowBootCampsNavLink(bootcampsLinkCardlists, allCourses),
    );
  }, []);

  // Hide the header and footer if we’re looking at the webview on mobile
  // (this is different than just 'mobile view')
  const showHeader = !webview && !hideHeaderAndFooter;
  const showFooter = !webview && !hideHeaderAndFooter;

  let banners = [];
  if (!webview) {
    banners = bannerEdges.map(edge => edge.node);
  }

  if (checkUserLanguage) {
    compareUserLanguage();
  }

  const messages = defineMessages({
    'prospectus.layout.ariaLabel.content': {
      id: 'prospectus.layout.ariaLabel.content',
      defaultMessage: 'Content',
      description: 'aria-label for Content',
    },
  });

  const languageCode = lang || intl.locale;

  function mobileNavContext(mobileNavIsOpenState, mobileSearchbarIsOpenState) {
    return {
      mobileNavIsOpen: mobileNavIsOpenState,
      setMobileNavIsOpen: isOpen => setMobileNavIsOpen(isOpen),
      mobileSearchbarIsOpen: mobileSearchbarIsOpenState,
      setMobileSearchbarOpen: isOpen => setMobileSearchbarIsOpen(isOpen),
    };
  }

  const memoizedMobileNavContext = memoizeOne(mobileNavContext);

  // Temp trick to avoid search engines to index
  // prod-edx-prospectus.edx.org & stage-edx-prospectus.edx.org
  const DOMAIN_REGEX = /(prod|stage)-edx-prospectus\.edx\.org/;
  const ADD_NOINDEX = DOMAIN_REGEX.test(gatsbyLocation.hostname);

  return (
    <SiteNavContext.Provider
      value={memoizedMobileNavContext(mobileNavIsOpen, mobileSearchbarIsOpen)}
    >
      <Helmet
        titleTemplate={
          removeTrailingCompanyName ? '' : `%s | ${config.company}`
        }
      >
        <html lang={languageCode} />
        <title>{title}</title>

        <link rel="stylesheet" href="/fonts/primary-fonts.css" />
        <link
          rel="stylesheet"
          media="print"
          onLoad="this.onload=null;this.removeAttribute('media');"
          href="/fonts/async-fonts.css"
        />
        <link rel="stylesheet" href="https://chatbot-frontend.prod.ai.2u.com/@latest/index.min.css" />

        {/* Preconnect to required origins */}
        <link rel="preconnect" href="https://api.segment.io" />
        <link rel="preconnect" href="https://cdn.segment.io" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://logx.optimizely.com" />
        <link rel="dns-prefetch" href="https://prod-discovery.edx-cdn.org/" />

        {optimizely.load && <script {...getOptimizelyProps(optimizely)} />}
        {ADD_NOINDEX && <meta name="robots" content="noindex" />}
        <base href={config.url} />

        {/* OpenGraph tags */}
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={config.locale} />
        <meta property="og:site_name" content={config.siteName} />

        <meta property="fb:app_id" content={config.fbAppID} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={config.twitter} />

        {/* Apple tags */}
        <meta
          name="apple-itunes-app"
          content={`app-id=${config.apple.id}`}
          app-argument={config.apple.url}
        />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />

        {requiresTaxiFormScript ? <script src={TAXI_FORM_SCRIPT_HREF} /> : null}

        <script type="application/ld+json">
          {`
              {
                  "@context": "https://schema.org",
                  "@type" : "Organization",
                  "name" : "edX",
                  "url" : "https://www.edx.org",
                  "logo": "/images/logos/edx-logo-elm.svg",
                  "sameAs" : [
                    "https://www.facebook.com/edX",
                    "https://twitter.com/edXOnline",
                    "https://www.linkedin.com/company/edx",
                    "http://www.instagram.com/edxonline",
                    "https://www.youtube.com/user/edxonline"
                  ]
              }
          `}
        </script>
      </Helmet>
      <div id="page" className={classNames('page', pageClassname)}>
        <MegaNavProvider>
          {showHeader && !CustomHeader && (
            <Header
              allCurrency={allCurrency}
              location={location}
              showMegaNav={showMegaNav}
            />
          )}
          {CustomHeader && <CustomHeader />}
          {showMegaNav && <VerticalNavigationMenu />}
        </MegaNavProvider>

        {!webview && !CustomHeader && <SitewideBanner banners={banners} />}
        <main
          id="main-content"
          name="main-content"
          className={mainClassname}
          tabIndex="-1"
          ref={mainContentRef}
          aria-label={intl.formatMessage(
            messages['prospectus.layout.ariaLabel.content'],
          )}
        >
          {children}
        </main>

        <ZendeskChat key={href} pageName="layout" tracking={tracking} />
        <ChatFloatingActionButton
          key={href}
          pageName="layout"
          tracking={tracking}
          chatbotType={SURVEY}
        />
        <SkillsBuilderFloatingActionButton
          key={href}
          tracking={tracking}
          pageName="layout"
        />
        <XpertChatbot xpertKey="edx" />
        <OCMFloatingActionButton
          key={href}
          pageName="layout"
          tracking={tracking}
        />
        {showFooter && !CustomFooter && <Footer />}
        {CustomFooter && <CustomFooter />}
      </div>
    </SiteNavContext.Provider>
  );
}

const mapStateToProps = store => ({
  userLanguage: store.userLanguage,
});

const mapDispatchToProps = dispatch => ({
  saveAutoCompletePreQuery: payload => dispatch(setAutoCompletePreQuery(payload)),
  saveShowBootCampsNavLink: payload => dispatch(setShowBootCampsNavLink(payload)),
});

const TemplateWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseTemplateWrapper);

BaseTemplateWrapper.propTypes = {
  optimizely: PropTypes.shape({
    load: PropTypes.bool,
    sync: PropTypes.bool,
  }),
  pageClassname: PropTypes.string,
  mainClassname: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  allCurrency: allCurrencyShape,
  webview: PropTypes.bool,
  intl: intlShape.isRequired,
  lang: PropTypes.string,
  userLanguage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  allCourses: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          uuid: PropTypes.string,
          courseType: PropTypes.string,
          locationRestriction: PropTypes.shape({
            restrictionType: PropTypes.string,
            countries: PropTypes.arrayOf(PropTypes.string),
          }),
        }),
      }),
    ),
  }).isRequired,
  saveShowBootCampsNavLink: PropTypes.func.isRequired,
  removeTrailingCompanyName: PropTypes.bool,
  bootcampsLinkCardlists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      cardList: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  sitewideBanners: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        nodes: PropTypes.shape(SitewideBannerProps),
      }),
    ),
  }),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
  hideHeaderAndFooter: PropTypes.bool,
  requiresTaxiFormScript: PropTypes.bool,
  customHeader: PropTypes.elementType,
  customFooter: PropTypes.elementType,
  tracking: PropTypes.shape().isRequired,
  autoCompletePreQuery: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ),
  saveAutoCompletePreQuery: PropTypes.func.isRequired,
};

BaseTemplateWrapper.defaultProps = {
  optimizely: {
    load: true,
    sync: false,
  },
  pageClassname: '',
  mainClassname: '',
  allCurrency: {},
  webview: false,
  lang: undefined,
  removeTrailingCompanyName: false,
  sitewideBanners: {
    edges: [],
  },
  location: {},
  hideHeaderAndFooter: false,
  requiresTaxiFormScript: false,
  customHeader: null,
  customFooter: null,
  autoCompletePreQuery: {
    default: {
      products: [],
      popularSearchTerms: [],
    },
  },
};

export default track(() => ({
  component: 'layout',
}))(
  injectIntl(props => {
    const data = useStaticQuery(graphql`
      query {
        allContentfulSitewideBanner(filter: { active: { eq: true } }) {
          edges {
            node {
              active
              languageCode
              targetCountry
              backgroundColor
              campaignId
              textColor
              content {
                raw
              }
            }
          }
        }
        contentfulProductLandingPage(slug: { eq: "boot-camps" }) {
          modules {
            ... on ContentfulCardListModule {
              id
              name
              cardList
            }
          }
        }
        allCourse(
          filter: {
            inProspectus: { eq: true }
            locationRestriction: { restrictionType: { ne: null } }
          }
        ) {
          edges {
            node {
              locationRestriction {
                restrictionType
                countries
                states
              }
              uuid
            }
          }
        }
        allContentfulAutoCompletePreQuery {
          edges {
            node {
              pageType
              uuidList
              popularSearchTerms
              programs {
                cardImageUrl
                degree {
                  additionalMetadata {
                    externalUrl
                  }
                }
                marketingUrl
                organizationShortCodeOverride
                owners: authoringOrganizations {
                  key
                }
                productSource {
                  slug
                }
                title
                type
                uuid
              }
              courses {
                title
                uuid
                additionalMetadata {
                  externalUrl
                }
                courseType
                image {
                  src
                }
                marketingUrl
                organizationShortCodeOverride
                owners {
                  key
                }
                productSource {
                  slug
                }
              }
            }
          }
        }
      }
    `);

    const bootcampsLinkCardlists = data.contentfulProductLandingPage.modules.filter(
      module => !isEmpty(module),
    );

    const allContentfulAutoCompletePreQuery = data.allContentfulAutoCompletePreQuery.edges.map(edge => edge.node);

    const autoCompletePreQuery = getAutoCompletePreQuery(allContentfulAutoCompletePreQuery);

    return (
      <TemplateWrapper
        sitewideBanners={data.allContentfulSitewideBanner}
        bootcampsLinkCardlists={bootcampsLinkCardlists}
        allCourses={data.allCourse}
        autoCompletePreQuery={autoCompletePreQuery}
        {...props}
      />
    );
  }),
);
