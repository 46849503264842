import './styles.scss';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import track from 'react-tracking';
import algoliasearch from 'algoliasearch/lite';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import useGeotargeting from '@prospectus/common/hooks/useGeotargeting';

import { AutoCompleteContext } from '@utils/context';
import { getUserLocation } from '@utils/location';
import {
  MIN_CHARS_FOR_AUTOCOMPLETE,
  autocompleteSearch,
} from '@utils/search';

import ManagedAutoComplete from './components/ManagedAutoComplete';

const focusOnInput = (element) => {
  const input = element.querySelector('input');
  if (input) {
    input.focus();
  } else {
    element.focus();
  }
};

function BaseSearchBoxWithAutoComplete(props) {
  const { geotargetingEnabled } = useGeotargeting();

  const {
    indexName,
    id,
    searchBoxComponent,
    additionalSearchBoxProps,
    className,
  } = props;

  const autoCompleteContext = useContext(AutoCompleteContext);
  const {
    query,
    setQuery,
    autocompleteOpen,
    setAutocompleteOpen,
    preQueryOpen,
    setPreQueryOpen,
    autocompleteDisabled,
    autocompleteResults,
    setAutocompleteDisabled,
    setAutocompleteResults,
  } = autoCompleteContext;

  const currentPage = useSelector(state => state.currentPage);
  const { preQuery } = useSelector(state => state.autoComplete);

  const [focusableElements, setFocusableElements] = useState([]);
  const [activeDescendant, setActiveDescendant] = useState(null);
  const [prevContext, setPrevContext] = useState(autoCompleteContext);

  const searchInputId = `${id}-search-input`;
  const viewAllId = `${id}-search-view-all`;
  const preQueryString = 'prequery-product';
  const popularSearchString = 'popular-search';
  const resultString = 'product-result';
  const searchRef = useRef();
  const prevQuery = prevContext?.query;

  const handleKeyPress = event => {
    if (preQueryOpen || autocompleteOpen) {
      const { key, shiftKey } = event;
      const goForward = (key === 'ArrowDown' || (!shiftKey && key === 'Tab'));

      switch (key) {
        case 'ArrowDown':
        case 'ArrowUp':
        case 'Tab': {
          event.preventDefault();
          const activeIndex = focusableElements.indexOf(activeDescendant);
          if (activeIndex > -1) {
            let newActiveIndex;
            if (goForward) {
              newActiveIndex = (activeIndex + 1) % focusableElements.length;
            } else if (activeIndex === 0) {
              // JavaScript doesn't do modular arithmetic with negative numbers correctly, so force it
              newActiveIndex = focusableElements.length - 1;
            } else {
              newActiveIndex = (activeIndex - 1) % focusableElements.length;
            }
            const newActiveId = focusableElements[newActiveIndex];
            setActiveDescendant(newActiveId);
            focusOnInput(document.getElementById(newActiveId));
          }
          break;
        }

        case 'Enter': {
          // if we're on a result, get the inner link element and click it
          const activeElement = document.getElementById(activeDescendant);
          if (activeElement.getElementsByTagName('a').length > 0) {
            activeElement.getElementsByTagName('a')[0].click();
          }
          break;
        }

        case 'Escape': {
          setActiveDescendant(searchInputId);
          focusOnInput(document.getElementById(searchInputId));
          setAutocompleteOpen(false);
          setPreQueryOpen(false);
          break;
        }

        default:
          return null;
      }
    }
    return null;
  };

  const handleOutsideClick = () => {
    if (preQueryOpen) {
      setPreQueryOpen(false);
    }
    if (autocompleteOpen) {
      setAutocompleteOpen(false);
    }
  };

  const updateAutocomplete = () => {
    // initialize Algolia workers
    const searchClient = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_SEARCH_KEY,
    );

    const userCountry = getUserLocation();

    // since autocomplete doesn't need to get facet counts, can just use the basic multiquery without the helper
    const autocomplete = () => {
      autocompleteSearch(
        searchClient,
        query.trimStart(),
        setAutocompleteResults,
        setAutocompleteOpen,
        indexName,
        geotargetingEnabled,
        userCountry,
      );
    };

    // Because the open / disabled state is managed by a parent
    // using the useAutocomplete() hook as opposed to in a global store,
    // we don't have to check if this input is focused.
    const queryCanAutocomplete = (query && query.trimStart().length >= MIN_CHARS_FOR_AUTOCOMPLETE);
    const isNewQuery = (query && query !== prevContext.query);

    if (autocompleteOpen && !queryCanAutocomplete) {
      setAutocompleteOpen(false);
    }
    if (!autocompleteDisabled && isNewQuery && queryCanAutocomplete) {
      autocomplete();
    }
    if (autocompleteDisabled && isNewQuery && queryCanAutocomplete) {
      setAutocompleteDisabled(false);
    }
  };

  // attach a unique reference key to all results for more control over keyboard navigability
  const getRefStrings = (items, identifier) => items.map((_, index) => `${id}-${identifier}-${index}`);

  const determineAndSetFocusableElements = () => {
    if (preQueryOpen && !autocompleteOpen) {
      let productRefIds = [];
      let popularSearchIds = [];

      if (preQuery[currentPage]?.length > 0) {
        productRefIds = getRefStrings(preQuery[currentPage], preQueryString);
      } else if (preQuery.default?.length > 0) {
        productRefIds = getRefStrings(preQuery.default, preQueryString);
      }

      if (preQuery[currentPage]?.popularSearchTerms?.length > 0) {
        popularSearchIds = getRefStrings(preQuery[currentPage].popularSearchTerms, popularSearchString);
      } else if (preQuery.default?.popularSearchTerms.length > 0) {
        popularSearchIds = getRefStrings(preQuery.default.popularSearchTerms, popularSearchString);
      }

      setFocusableElements([searchInputId, ...productRefIds, ...popularSearchIds]);
    } else if (autocompleteOpen) {
      const productRefIds = getRefStrings(autocompleteResults.products, resultString);
      setFocusableElements([searchInputId, ...productRefIds, viewAllId]);
    }
    setActiveDescendant(searchInputId);
  };

  useEffect(() => {
    determineAndSetFocusableElements();
  }, [preQuery, autocompleteResults, preQueryOpen, autocompleteOpen]);

  useEffect(() => {
    const {
      autocompleteResults: {
        products: prevProducts,
      },
    } = prevContext;

    const { products } = autocompleteResults;

    if (!isEqual(prevProducts, products)) {
      determineAndSetFocusableElements();
    }

    updateAutocomplete(prevContext);

    // Reassigning prevContext must be the last thing that happens in
    // this method so that other can use the values of prevContext for
    // comparison purposes.
    setPrevContext({ ...autoCompleteContext });
  }, [query]);

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={`search-box-with-autocomplete position-relative ${className}`}
        id={`${id}-search-with-autocomplete`}
        onKeyDown={handleKeyPress}
      >
        { /* eslint-enable jsx-a11y/no-noninteractive-tabindex */ }
        <ManagedAutoComplete
          {...props}
          activeOption={activeDescendant}
          setActiveOption={setActiveDescendant}
          searchInputId={searchInputId}
          viewAllId={viewAllId}
          searchRef={searchRef}
          searchBoxComponent={searchBoxComponent}
          searchBoxProps={
            {
              query,
              prevQuery,
              setQuery,
              searchRef,
              preQueryOpen,
              setPreQueryOpen,
              setAutocompleteOpen,
              autocompleteOpen,
              id,
              setActiveOption: setActiveDescendant,
              activeOption: activeDescendant,
              searchInputId,
              ...additionalSearchBoxProps,
            }
          }
        />
      </div>
    </OutsideClickHandler>
  );
}

BaseSearchBoxWithAutoComplete.propTypes = {
  indexName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  searchBoxComponent: PropTypes.func.isRequired,
  additionalSearchBoxProps: PropTypes.shape({}),
  className: PropTypes.string,
  location: PropTypes.shape({
    host: PropTypes.string,
    protocol: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

BaseSearchBoxWithAutoComplete.defaultProps = {
  additionalSearchBoxProps: {},
  className: '',
};

export default track({
  component: 'autocomplete',
})(BaseSearchBoxWithAutoComplete);
