import React from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import { getEnterpriseMarketingLinkData } from '@utils/edxLinks';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';

function BusinessLink({
  intl,
}) {
  const enterpriseMarketingLinkData = getEnterpriseMarketingLinkData({
    locale: intl.locale,
    utmMedium: process.env.GATSBY_ENTERPRISE_MARKETING_HEADER_UTM_MEDIUM,
    includeUrlWithQueryParams: true,
  });

  return (
    <ProspectusLink
      href={enterpriseMarketingLinkData.urlWithQueryParams}
      className="btn btn-tertiary menu-item"
      analytics={{
        eventName: 'edx.bi.link.site-nav.business.clicked',
      }}
    >
      <FormattedMessage
        id="prospectus.header.site_header.site_nav.business.business_link_text"
        defaultMessage="edX For Business"
        description="Link text for the 'edX For Business' link in the site nav"
      />
    </ProspectusLink>
  );
}

BusinessLink.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(BusinessLink);
