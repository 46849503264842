import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, FormattedMessage } from 'gatsby-plugin-react-intl';
import { intlShape } from '@prospectus/common/types/intlShape';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import NewBadge from '@prospectus/common/ui/NewBadge';
import SiteNavLink from './SiteNavLink';

const messages = defineMessages({
  'prospectus.header.site_header.site_nav.programs_heading': {
    id: 'prospectus.header.site_header.site_nav.programs_heading',
    defaultMessage: 'Programs & Degrees',
    description: 'Heading for the programs menu section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs_heading.description': {
    id: 'prospectus.header.site_header.site_nav.programs_heading.description',
    defaultMessage: 'Programs & Degrees menu',
    description: 'Screen reader description for the heading for the programs menu section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.microbachelors_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.microbachelors_link_text',
    defaultMessage: 'MicroBachelors® Programs',
    description: 'Link text for MicroBachelors link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.microbachelors_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.microbachelors_subtitle_text',
    defaultMessage: 'Undergraduate-level, for career advancement or a degree path',
    description: 'Text for MicroBachelors subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.microbootcamps_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.microbachelors_link_text',
    defaultMessage: 'MicroBootCamps™',
    description: 'Link text for microbootcamps link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.microbootcamps_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.microbachelors_subtitle_text',
    defaultMessage: 'Develop your technical skills through self-paced programs with access to live experts',
    description: 'Text for microbootcamps subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.bachelors_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.bachelors_link_text',
    defaultMessage: "Bachelor's Degrees",
    description: "Link text for Bachelor's link in the programs section of the site nav",
  },
  'prospectus.header.site_header.site_nav.programs.bachelors_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.bachelors_subtitle_text',
    defaultMessage: 'Begin or complete a degree; fully online',
    description: "Text for Bachelor's subtitle in the programs section of the site nav",
  },
  'prospectus.header.site_header.site_nav.programs.micromasters_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.micromasters_link_text',
    defaultMessage: 'MicroMasters® Programs',
    description: 'Link text for MicroMasters link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.micromasters_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.micromasters_subtitle_text',
    defaultMessage: 'Graduate-level, for career advancement or a degree path',
    description: 'Text for MicroMasters subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.masters_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.masters_link_text',
    defaultMessage: "Master's Degrees",
    description: "Link text for Master's link in the programs section of the site nav",
  },
  'prospectus.header.site_header.site_nav.programs.masters_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.masters_subtitle_text',
    defaultMessage: 'Online master’s degrees from top universities',
    description: "Text for Master's subtitle in the programs section of the site nav",
  },
  'prospectus.header.site_header.site_nav.programs.boot_camps_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.boot_camps_link_text',
    defaultMessage: 'Boot Camps',
    description: 'Link text for Boot Camps link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.boot_camps_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.boot_camps_subtitle_text',
    defaultMessage: 'Intensive, hands-on, project-based training',
    description: 'Text for Boot Camps subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.cert_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.cert_link_text',
    defaultMessage: 'Online Certificates',
    description: 'Link text for Online Certificates link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.cert_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.cert_subtitle_text',
    defaultMessage: 'From top employers and universities covering an extensive range of topics and skills',
    description: 'Text for Online Certificates subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.executive_education_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.executive_education_link_text',
    defaultMessage: 'Executive Education',
    description: 'Link text for Executive Education link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.executive_education_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.executive_education_subtitle_text',
    defaultMessage: 'Expert-led, fully supported courses that build career-critical skills',
    description: 'Text for Executive Education subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.xseries_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.xseries_link_text',
    defaultMessage: 'XSeries',
    description: 'Link text for Executive Education link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.xseries_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.xseries_subtitle_text',
    defaultMessage: 'Series of courses for a deep understanding of a topic',
    description: 'Text for XSeries subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.doctorate_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.doctorate_link_text',
    defaultMessage: 'Doctorate Degrees',
    description: 'Link text for Doctorate link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.doctorate_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.doctorate_subtitle_text',
    defaultMessage: 'Online and hybrid doctorate degrees from top universities',
    description: 'Text for Doctorate subtitle in the programs section of the site nav',
  },
});

function ProgramsSubmenu({
  intl,
  showBootCampNavLink,
}) {
  const initialProductLinks = [
    {
      key: 'boot-camps-link',
      href: 'boot-camps?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.boot_camps_link_text'])}
        </>
      ),
      hasNewBadge: true,
      eventName: 'edx.bi.link.site-nav.boot-camps.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.boot_camps_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'microbootcamps-link',
      href: 'boot-camps/microbootcamps?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.microbootcamps_link_text'])}
        </>
      ),
      hasNewBadge: true,
      eventName: 'edx.bi.link.site-nav.microbootcamps.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.microbootcamps_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'executive-education-link',
      href: 'executive-education?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.executive_education_link_text'])}
        </>
      ),
      hasNewBadge: true,
      eventName: 'edx.bi.link.site-nav.executive-education.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.executive_education_subtitle_text'])}
        </>
      ),
      showEs: true,
    },
    {
      key: 'masters-link',
      href: 'masters?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.masters_link_text'])}
        </>
      ),
      hasNewBadge: true,
      eventName: 'edx.bi.link.site-nav.masters.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.masters_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'certificates-link',
      href: 'certificates?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.cert_link_text'])}
        </>
      ),
      hasNewBadge: false,
      eventName: 'edx.bi.link.site-nav.certificates.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.cert_subtitle_text'])}
        </>
      ),
      showEs: true,
    },
    {
      key: 'micromasters-link',
      href: 'masters/micromasters?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.micromasters_link_text'])}
        </>
      ),
      hasNewBadge: false,
      eventName: 'edx.bi.link.site-nav.micromasters.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.micromasters_subtitle_text'])}
        </>
      ),
      showEs: true,
    },
    {
      key: 'bachelors-link',
      href: 'bachelors?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.bachelors_link_text'])}
        </>
      ),
      hasNewBadge: true,
      eventName: 'edx.bi.link.site-nav.bachelors.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.bachelors_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'microbachelors-link',
      href: 'bachelors/microbachelors?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.microbachelors_link_text'])}
        </>
      ),
      hasNewBadge: false,
      eventName: 'edx.bi.link.site-nav.microbachelors.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.microbachelors_subtitle_text'])}
        </>
      ),
      showEs: true,
    },
    {
      key: 'xseries-link',
      href: 'xseries?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.xseries_link_text'])}
        </>
      ),
      hasNewBadge: false,
      eventName: 'edx.bi.link.site-nav.xseries.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.xseries_subtitle_text'])}
        </>
      ),
      showEs: true,
    },
    {
      key: 'doctorate-link',
      href: 'doctorate?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.doctorate_link_text'])}
        </>
      ),
      hasNewBadge: true,
      eventName: 'edx.bi.link.site-nav.doctorate.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.doctorate_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
  ];

  const [productLinks, setProductLinks] = useState(initialProductLinks);

  useEffect(() => {
    let links = initialProductLinks;

    if (intl.locale === 'es') {
      links = links.filter(link => link.showEs === true);
    }
    if (!showBootCampNavLink) {
      links = links.filter((link) => link.key !== 'boot-camps-link');
    }

    setProductLinks(links);
  }, []);

  return (
    <SiteNavLink
      id="programs"
      className="visible-desktop"
      title={intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs_heading'])}
      url="search/?tab=program"
      buttonDescription={intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs_heading.description'])}
      eventName="edx.bi.link.site-nav.programs-degrees.clicked"
      htmlHeader={(
        <header className="tab-header">
          <div className="program-header-menu">
            <h2 id="menu-tab-view-programs">
              <FormattedMessage
                id="prospectus.header.site_header.site_nav.programs.subtitle_text"
                defaultMessage="Pathways for your advancement"
                description="Subtitle text for programs section of the site nav"
              />
            </h2>
            <ProspectusLink
              className="btn btn-outline-primary"
              href="products?linked_from=sitenav"
              analytics={{
                eventName: 'edx.bi.link.site-nav.compare-programs.clicked',
              }}
            >
              <FormattedMessage
                id="prospectus.header.site_header.site_nav.programs.program_comparison.button_text"
                defaultMessage="Compare Programs"
                description="Button text for the 'compare programs' button in the program section of the site nav"
              />
            </ProspectusLink>
          </div>
        </header>
      )}
      htmlContent={(
        <div className="tab-content">
          <ul className="program-list list-group list-group-flush">
            {productLinks && productLinks.map(({
              key,
              href,
              eventName,
              label,
              hasNewBadge,
              subtitle,
            }) => (
              <li className="list-group-item" key={key}>
                <div className="content-block">
                  <h3 className="content-heading">
                    <ProspectusLink
                      className="title-link"
                      href={href}
                      analytics={{
                        eventName,
                      }}
                    >
                      {label}
                      {hasNewBadge && <NewBadge className="position-absolute px-2 mx-2" />}
                    </ProspectusLink>
                  </h3>
                  <p className="text-muted program-description">
                    {subtitle}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    />
  );
}

ProgramsSubmenu.propTypes = {
  intl: intlShape.isRequired,
  showBootCampNavLink: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  showBootCampNavLink: state.showBootCampNavLink,
});

export default connect(mapStateToProps)(injectIntl(ProgramsSubmenu));
