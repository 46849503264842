import React, { useCallback } from 'react';

import Truncate from 'react-truncate';
import { useTracking } from 'react-tracking';
import { Badge, Card } from '@edx/paragon';

import { submitEvent } from '@utils/analytics';
import { InnerBaseCardProps } from './types';

import {
  addCloudflareImageParams, ImageOptions,
} from '../../ui/CloudflareImage/utils';

const CARD_HEADER_IMAGE_DARK = '/images/product_pages/default-card-header-dark.png';
const CARD_HEADER_IMAGE_LIGHT = '/images/product_pages/default-card-header-light.png';

const cloudflareImageParams:ImageOptions = { quality: 60, format: 'webp', width: 350 };

// gets the transformed url for cloudflare image
const getCloudflareTransformedURL = (
  imgSrc:string,
  options = cloudflareImageParams,
) => addCloudflareImageParams(imgSrc, options);

function InnerBaseCard({
  customHeaderImage,
  schoolLogo,
  title,
  titleTruncate = 3,
  subtitle,
  variant,
  productTypeCopy,
  footer,
  uuid,
  shouldfirePreQuerySegmentEvent = false,
  handleOnClick,
  isLoading = false,
}: InnerBaseCardProps) {
  //
  const DEFAULT_CARD_HEADER = variant === 'dark'
    ? CARD_HEADER_IMAGE_DARK
    : CARD_HEADER_IMAGE_LIGHT;
  const tracking = useTracking();
  // get the card header image source
  const getCardImgSrc = useCallback(
    () => (
      customHeaderImage
        ? getCloudflareTransformedURL(customHeaderImage) : DEFAULT_CARD_HEADER),
    [customHeaderImage, DEFAULT_CARD_HEADER],
  );
  // get logo image source
  const getLogoSrc = useCallback(
    () => (
      getCloudflareTransformedURL(schoolLogo, { ...cloudflareImageParams, quality: 75, width: 150 })),
    [schoolLogo],
  );

  const cardImgSrc = getCardImgSrc();
  const logoSrc = getLogoSrc();
  // For multiple school name line break
  const subTitleTruncateLine = (typeof subtitle === 'object' && subtitle?.length > 1) ? 2 : 1;

  // TODO: refactor this event handler to be passed into analytics prop on BaseCard instead.
  // The click event is not reliable because the page could navigate away before the call to segment completes.
  const handleSubscriptionEventHandler = () => {
    const componentProps = {
      program_uuid: uuid,
      title,
    };
    submitEvent(tracking, componentProps, 'edx.bi.subscription.pre-search-page.program.clicked');
  };

  const onCardClickHandler = shouldfirePreQuerySegmentEvent ? handleSubscriptionEventHandler : handleOnClick;

  return (
    <Card
      className={`base-card ${variant}`}
      variant={variant}
      onClick={onCardClickHandler}
      isLoading={isLoading}
    >
      <Card.ImageCap
      // @todo WS-3952 Paragon fallbackSrc dones't work, this should be removed laterly
        className="base-card-image-show"
        src={cardImgSrc}
        srcAlt={`header image for ${subtitle}`}
        fallbackSrc={DEFAULT_CARD_HEADER}
        logoSrc={logoSrc}
        logoAlt={`logo for ${subtitle}`}
        imageLoadingType="lazy"
      />
      <Card.Header
        className="mt-2"
        title={(
          <Truncate
            lines={titleTruncate}
            trimWhitespace
            ellipsis="…"
          >
            {title}
          </Truncate>
        )}
        subtitle={(
          <Truncate lines={subTitleTruncateLine} trimWhitespace>
            {subtitle}
          </Truncate>
        )}
      />
      <Card.Section className="d-flex flex-column justify-content-end">
        <div>
          <Badge>
            {productTypeCopy}
          </Badge>
        </div>
        <div className="mt-2">
          {footer}
        </div>
      </Card.Section>
    </Card>
  );
}

export default InnerBaseCard;
