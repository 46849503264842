import { defineMessages } from 'gatsby-plugin-react-intl';

const messages = defineMessages({
  'prospectus.header.site_header.site_nav.boot-camp_heading': {
    id: 'prospectus.header.site_header.site_nav.boot-camp_heading',
    defaultMessage: 'Boot Camps',
    description: 'Heading for the boot camp menu section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp_heading.description': {
    id: 'prospectus.header.site_header.site_nav.boot-camp_heading.description',
    defaultMessage: 'Boot Camps',
    description: 'Screen reader description for the heading for the boot camp menu section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.microbootcamps_link_text': {
    id: 'prospectus.header.site_header.site_nav.programs.microbachelors_link_text',
    defaultMessage: 'Artificial Intelligence',
    description: 'Link text for microbootcamps link in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.programs.microbootcamps_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.programs.microbachelors_subtitle_text',
    defaultMessage: 'Develop skills in AI and machine learning in an immersive, expert-led program',
    description: 'Text for microbootcamps subtitle in the programs section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.coding_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.coding_link_text',
    defaultMessage: 'Coding',
    description: 'Link text for Coding link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.coding_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.coding_subtitle_text',
    defaultMessage: 'Build a strong foundation in full-stack web development',
    description: 'Text for coding subtitle in the bootc-camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.cybersecurity_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.cybersecurity_link_text',
    defaultMessage: 'Cybersecurity',
    description: 'Link text for Cybersecurity link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.cybersecurity_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.cybersecurity_subtitle_text',
    defaultMessage: 'Learn IT, networking, and modern information security',
    description: 'Text for cybersecurity subtitle in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.analytics_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.analytics_link_text',
    defaultMessage: 'Data Analytics',
    description: 'Link text for Data analytics link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.analytics_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.analytics_subtitle_text',
    defaultMessage: 'Unlock the full potential of data using Python, SQL, and more',
    description: 'Text for Data analytics subtitle in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.all_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.all_link_text',
    defaultMessage: 'View all boot camps',
    description: 'Link text for all bootcamps link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.all_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.all_subtitle_text',
    defaultMessage: 'Gain tech skills to get the career that you want',
    description: 'Text for all boot camps subtitle in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.fintech_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.fintech_link_text',
    defaultMessage: 'Fintech',
    description: 'Link text for fintech bootcamps link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.fintech_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.fintech_subtitle_text',
    defaultMessage: 'Bring together data, technology, and finance as a fintech professional',
    description: 'Text for fintech boot camps subtitle in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.digital-marketing_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.marketing_link_text',
    defaultMessage: 'Digital Marketing',
    description: 'Link text for digital marketing bootcamps link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.digital-marketing_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.digital-marketing_subtitle_text',
    defaultMessage: 'Gain real-world skills in marketing strategy and digital advertising',
    description: 'Text for digital marketing boot camps subtitle in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.product-managment_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot.product-managment_link_text',
    defaultMessage: 'Product Management',
    description: 'Link text for digital marketing bootcamps link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.product-managment_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.product-managment_subtitle_text',
    defaultMessage: 'Guide a product throughout its lifecycle as a product manager',
    description: 'Text for digital marketing boot camps subtitle in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.project-managment_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.project-managment_link_text',
    defaultMessage: 'Tech Project Management',
    description: 'Link text for project manager bootcamps link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.project-managment_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.project-managment_subtitle_text',
    defaultMessage: 'Drive efficiency, innovation, and collaboration as a project manager',
    description: 'Text for project manager boot camps subtitle in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.ux-ui-user-experience_link_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.ux-ui-user-experience_link_text',
    defaultMessage: 'UX/UI',
    description: 'Link text for ux/ui user experience bootcamps link in the boot camp section of the site nav',
  },
  'prospectus.header.site_header.site_nav.boot-camp.ux-ui-user-experience_subtitle_text': {
    id: 'prospectus.header.site_header.site_nav.boot-camp.ux-ui-user-experience_subtitle_text',
    defaultMessage: 'Create seamless digital experiences as a UX/UI designer',
    description: 'Text for user experience boot camps subtitle in the boot camp section of the site nav',
  },
});

export default messages;
