import './styles.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@edx/paragon';
import { Minimize } from '@edx/paragon/icons';
import { useIntl } from 'gatsby-plugin-react-intl';

import { submitEvent } from '@utils/analytics';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import { messages } from '@prospectus/common/ui/xpert/experiments/ChatFloatingActionButton/Survey/constants';
import FloatingActionButton from '@prospectus/common/ui/xpert/shared/FloatingActionButton';
import FloatingActionPopup from '@prospectus/common/ui/xpert/shared/FloatingActionPopup';
import ProactiveMessage from '@prospectus/common/ui/xpert/shared/ProactiveMessage';
import useOptimizelyVariation from '@prospectus/common/ui/xpert/shared/hooks/useOptimizelyVariation';
import CloudflareImage from '@prospectus/common/ui/CloudflareImage';
import Survey from './Survey';
import { UNAVAILABLE, SURVEY } from './ChatbotType';

function ChatFloatingActionButton(props) {
  const [open, setOpen] = useState(false);

  const isMobile = useBreakpointMatch('medium');
  const intl = useIntl();
  const experimentVariation = useOptimizelyVariation('maasChatBotExperiment');

  if (!experimentVariation) {
    return null;
  }

  const handleClick = () => {
    setOpen(true);
    submitEvent(props.tracking, {
      category: '2u',
      page: props.pageName,
      label: 'chatbot',
    }, 'edx.bi.experiment.chatbot.painted.door.clicked.v2');
  };

  const handleMinimize = () => {
    setOpen(false);
  };

  const handleProactiveMessageClick = () => {
    setOpen(true);
    submitEvent(props.tracking, {
      category: '2u',
      page: props.pageName,
      label: 'chatbot',
    }, 'edx.bi.experiment.chatbot.proactive.message.clicked.v2');
  };

  const handleProactiveMessageClose = () => {
    submitEvent(props.tracking, {
      category: '2u',
      page: props.pageName,
      label: 'chatbot',
    }, 'edx.bi.experiment.chatbot.proactive.message.closed.v2');
  };

  const handleSubmitSurveyAnswer = (answer) => {
    submitEvent(props.tracking, {
      ...answer,
      category: '2u',
      page: props.pageName,
      label: 'chatbot',
    }, 'edx.bi.experiment.chatbot.survey.question.submitted');
  };

  return (
    <div>
      <ProactiveMessage
        style={{
          bottom: isMobile ? '10rem' : '5.5rem',
          right: isMobile ? '.9rem' : '1.5rem',
        }}
        isShown={!open}
        onClose={handleProactiveMessageClose}
        onClick={handleProactiveMessageClick}
        cookieName="edx-proactive-message-chatbot"
      >
        <span>
          {intl.formatMessage(
            messages[
              'prospectus.maas.painted.door.chatbot.proactive-message.greeting'
            ],
          )}
        </span>
        <span className="proactive-message-wave">👋</span>
        <span>
          {intl.formatMessage(
            messages[
              'prospectus.maas.painted.door.chatbot.proactive-message.message'
            ],
          )}
        </span>
      </ProactiveMessage>
      {!open && (
        <FloatingActionButton
          label={intl.formatMessage(
            messages['prospectus.maas.painted.door.chatbot.button.text'],
          )}
          onClick={handleClick}
          style={{
            ...(isMobile && { borderRadius: '50%' }),
            ...(!isMobile && { padding: '0.74rem 1.4rem' }),
            backgroundColor: '#454545',
            fontWeight: '800',
            family: 'system-ui',
            fontSize: '0.9rem',
            color: '#FFFFFF',
          }}
        >
          <div className="chatbot__icon">
            <div className="chatbot__icon--svg">
              <CloudflareImage
                url="/images/experiments/xpert/chat.svg"
                data-ot-ignore=""
                description={intl.formatMessage(
                  messages['prospectus.maas.painted.door.chatbot.button.text'],
                )}
              />
            </div>
            {!isMobile && (
              <div className="chatbot__text">
                {intl.formatMessage(
                  messages['prospectus.maas.painted.door.chatbot.button.text'],
                )}
              </div>
            )}
          </div>
        </FloatingActionButton>
      )}
      {open && (
        <FloatingActionPopup onMinimize={handleMinimize}>
          <div className="d-flex justify-content-center py-3 bg-gray-500 text-light-100 bg-gray-700">
            <h2 className="small text-light-100 font-weight-bold my-0 mx-auto p-0">
              {intl.formatMessage(
                messages['prospectus.maas.painted.door.chatbot.title'],
              )}
            </h2>
            <Button
              aria-label={intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.minimize'])}
              onClick={handleMinimize}
              className="bg-transparent border-0 m-0 p-0 position-absolute chatbot__minimize--btn"
            >
              <Icon className="justify-self-end" src={Minimize} />
            </Button>
          </div>
          {props.chatbotType === UNAVAILABLE && (
            <div className="chatbot__container--body">
              <div className="chatbot__message">
                <span>edX&nbsp; · &nbsp;Bot</span>
              </div>
              <div className="chatbot__container--body--bubble-container">
                <div>
                  <CloudflareImage
                    url="/images/experiments/xpert/edx.ico"
                    description="body title"
                    data-ot-ignore=""
                    className="chatbot__logo"
                  />
                </div>
                <div>
                  <div className="chatbot__bubble">
                    Hello! We&apos;re so glad you&apos;re here.
                  </div>
                  <div className="chatbot__bubble">
                    Our chatbot isn&apos;t available right now, but we are on
                    it. Thanks, and please come back soon.
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.chatbotType === SURVEY && (
            <Survey handleSubmitSurveyAnswer={handleSubmitSurveyAnswer} />
          )}
        </FloatingActionPopup>
      )}
    </div>
  );
}

ChatFloatingActionButton.propTypes = {
  tracking: PropTypes.shape().isRequired,
  pageName: PropTypes.string.isRequired,
  chatbotType: PropTypes.string,
};

ChatFloatingActionButton.defaultProps = {
  chatbotType: UNAVAILABLE,
};

export default ChatFloatingActionButton;
