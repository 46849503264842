import './styles.scss';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { WebviewContext } from '@utils/context';
import { addOptimizelyTracking } from '@utils/analytics';
import ContentfulLink from '@prospectus/common/ui/ContentfulLink';
import Wrapper from './CondensedWrapper';
import { condensedCardContainerType } from './types';

export const MAX_CARDS_TO_DISPLAY_MOBILE = 4;

function CondensedCardContainer({
  productList,
  productsTitle = '',
  isScrollable = false,
  dynamicProductList,
  shouldWrap,
  additionalClassNames,
  searchProps,
  cta,
}: condensedCardContainerType) {
  const isWebview = useContext(WebviewContext);

  return (
    <div className="condensed-card-container w-100">
      <h4 className="mb-4">{productsTitle}</h4>
      <div
        className={classNames(
          'd-flex',
          { 'flex-wrap': !isScrollable && shouldWrap && !dynamicProductList },
          { 'flex-nowrap scrollable': isScrollable && !dynamicProductList },
          {
            'flex-nowrap flex-lg-wrap scrollable': isScrollable && dynamicProductList,
          },
          additionalClassNames,
          'condensed-cards',
        )}
      >
        {productList && productList.map((item, index) => {
          // if the list is not expandable all cards will be visible
          const finalAnalytics = addOptimizelyTracking(item, searchProps?.analytics);
          const finalUrlParams = addOptimizelyTracking(item, searchProps?.urlParams);
          return (
            <Wrapper
              key={`condensed-card-${item.uuid || index}`}
              product={item}
              urlParams={finalUrlParams}
              isLoading={searchProps?.isLoading}
              analytics={{
                ...finalAnalytics,
                card_visibility: true,
              }}
              tabIndex={index}
              webview={isWebview}
              hasDeviceParam={searchProps?.hasDeviceParam}
            />
          );
        })}
      </div>
      {cta && cta.url && cta.label && (
        <div className={classNames('d-flex ctabutton mt-4 small', {
          // @ts-ignore
          'last-cta': cta?.isLast,
        })}
        >
          <ContentfulLink
            cta={cta}
            analytics={{
              eventName: 'edx.bi.user.card-list-module.cta.click',
            }}
          />
        </div>
      )}
    </div>
  );
}

CondensedCardContainer.defaultProps = {
  dynamicProductList: false,
  additionalClassNames: '',
  searchProps: null,
  cta: null,
  isScrollable: false,
  shouldWrap: false,
};

export default CondensedCardContainer;
