import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  defineMessages,
} from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import { isUserInUSA } from '@utils/location';
import NewBadge from '@prospectus/common/ui/NewBadge';
import MenuAccordion from './MenuAccordion';

const messages = defineMessages({
  'prospectus.header.site_header.site_nav.product_links.accordion_label': {
    id: 'prospectus.header.site_header.product_links.accordion_label',
    defaultMessage: 'Degrees & Programs',
    description: 'Label for a menu accordion containing product links.',
  },
  'prospectus.header.site_header.site_nav.partners.masters_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.masters_link_text',
    defaultMessage: "Master's Degrees",
    description: "Label for a link to the Master's program page",
  },
  'prospectus.header.site_header.site_nav.partners.bachelors_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.bachelors_link_text',
    defaultMessage: "Bachelor's Degrees",
    description: "Label for a link to the Bachelor's program page",
  },
  'prospectus.header.site_header.site_nav.partners.boot-camps_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.boot-camps_link_text',
    defaultMessage: 'Boot Camps',
    description: 'Label for a link to the Boot Camps page',
  },
  'prospectus.header.site_header.site_nav.partners.executive-education_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.executive-education_link_text',
    defaultMessage: 'Executive Education',
    description: 'Label for a link to the Boot Camps page',
  },
  'prospectus.header.site_header.site_nav.partners.certificates_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.certificates_link_text',
    defaultMessage: 'Online Certificates',
    description: 'Label for a link to the Online Certificates page',
  },
  'prospectus.header.site_header.site_nav.partners.micromasters-program_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.micromasters-program_link_text',
    defaultMessage: 'MicroMasters® Program',
    description: 'Label for a link to the MicroMasters® Program page',
  },
  'prospectus.header.site_header.site_nav.partners.microbachelors-program_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.microbachelors-program_link_text',
    defaultMessage: 'MicroBachelors® Program',
    description: 'Label for a link to the MicroBachelors® Program page',
  },
  'prospectus.header.site_header.site_nav.partners.doctorate_link_text': {
    id: 'prospectus.header.site_header.site_nav.partners.doctorate_link_text',
    defaultMessage: 'Doctorate Degrees',
    description: 'Label for a link to the Doctorates page',
  },
});

function MobileProductLinks({
  intl,
  collapsibleRef,
  onCollapsibleOpen,
}) {
  const initialProductLinks = [
    {
      href: '/boot-camps/microbootcamps?linked_from=sitenav',
      label: (
        <>
          MicroBootCamps™ {/* doesn't need to be localized */}
          <NewBadge className="ml-2" />
        </>
      ),
      analytics: {
        eventName: 'edx.bi.link.site-nav.microbootcamps.clicked',
      },
    },
    {
      href: '/boot-camps?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.boot-camps_link_text'])}
          <NewBadge className="ml-2" />
        </>
      ),
      analytics: {
        eventName: 'edx.bi.link.site-nav.boot-camps.clicked',
      },
    },
    {
      href: '/executive-education?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.executive-education_link_text'])}
          <NewBadge className="ml-2" />
        </>
      ),
      analytics: {
        eventName: 'edx.bi.link.site-nav.executive-education.clicked',
      },
    },
    {
      href: '/masters?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.masters_link_text'])}
          <NewBadge className="ml-2" />
        </>
      ),
      analytics: {
        eventName: 'edx.bi.link.site-nav.masters.clicked',
      },
    },
    {
      href: '/certificates?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.certificates_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.certificates.clicked',
      },
    },
    {
      href: '/micromasters?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.micromasters-program_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.micromasters.clicked',
      },
    },
    {
      href: '/bachelors?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.bachelors_link_text'])}
          <NewBadge className="ml-2" />
        </>
      ),
      analytics: {
        eventName: 'edx.bi.link.site-nav.bachelors.clicked',
      },
    },
    {
      href: '/bachelors/microbachelors?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.microbachelors-program_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.microbachelors.clicked',
      },
    },
    {
      href: '/doctorate?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.partners.doctorate_link_text'])}
          <NewBadge className="ml-2" />
        </>),
      analytics: {
        eventName: 'edx.bi.link.site-nav.doctorate.clicked',
      },
    },
  ];

  const [productLinks, setProductLinks] = useState(initialProductLinks);

  useEffect(() => {
    let links = initialProductLinks;

    if (!isUserInUSA()) {
      links = links.filter(link => !link.href.includes('boot-camps'));
    }

    setProductLinks(links);
  }, []);

  return (
    <MenuAccordion
      title={(
        <span>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.product_links.accordion_label'])}
          <NewBadge className="ml-2" />
        </span>
        )}
      id="product-links"
      links={productLinks}
      ref={collapsibleRef}
      onCollapsibleOpen={onCollapsibleOpen}
    />
  );
}

MobileProductLinks.propTypes = {
  intl: intlShape.isRequired,
  onCollapsibleOpen: PropTypes.func.isRequired,
  collapsibleRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]).isRequired,
};

export default injectIntl(MobileProductLinks);
