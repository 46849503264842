import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { formatRichText } from '@utils/formatting';

const formatContent = (activeBanner) => {
  const options = {
    renderNode: {
      // Rich text comes wrapped in a p by default and we want to tweak the p styles
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="m-0 py-2 text-center banner-space">
          {children}
        </p>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          href={node.data.uri}
          className="text-decoration-underline sitewide-banner-link"
          style={{ color: activeBanner.textColor }}
        >{children}
        </a>
      ),
    },
  };

  return formatRichText(activeBanner?.content?.raw, options);
};

export default formatContent;
