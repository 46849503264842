import './styles.scss';
import React from 'react';

import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import { FLYOUT_ID, GOAL, TOPIC } from '@prospectus/common/ui-strict/MegaNav/data/constants';
import { useMegaNav } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';

import MenuHero from '../MenuHero';
import MobileBackButton from '../MobileBackButton';
import NavigationContainer from '../NavigationContainer';
import NavigationCardView from '../NavigationCardView';
import TopicList from '../TopicList';
import AllTopicsView from '../AllTopicsView';
import NavigationCareerFlyout from '../NavigationCareerFlyout';

function NavigationFlyoutView() {
  const { activeCategory, categories } = useMegaNav();
  const categoryData = categories[activeCategory];
  const isMobile = useBreakpointMatch('medium');

  if (activeCategory === 'career') {
    return <NavigationCareerFlyout />;
  }

  if (!categoryData) {
    return null;
  }

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    <div
      id={FLYOUT_ID}
      className="mega-nav-flyout d-flex flex-column w-100 bg-white pb-6"
      tabIndex={0}
    >
      {isMobile && <MobileBackButton />}
      <MenuHero {...categoryData.hero} />
      <div className="flyout-content p-4.5 d-flex flex-column mb-2">
        {categoryData.type === TOPIC && categoryData.topicPills?.length > 0 && (
          <TopicList topics={categoryData.topicPills} />
        )}
        {categoryData.productSummaries?.length > 0 && (
          <NavigationContainer
            desktopCols={categoryData.productSummaries.length}
            cardList={categoryData.productSummaries}
          />
        )}
        {categoryData.navigationLinkLists?.length > 0 && (
          <NavigationCardView items={categoryData.navigationLinkLists} />
        )}
        {categoryData.type === GOAL && categoryData.topicPills?.length > 0 && (
          <TopicList topics={categoryData.topicPills} />
        )}
        {categoryData.type === TOPIC && categoryData.topicLinkLists && (
          <AllTopicsView topicLinkLists={categoryData.topicLinkLists} />
        )}
      </div>
    </div>
  );
}

export default NavigationFlyoutView;
