const { PRODUCT_MAP_2U } = require('../../utils/discoveryConstants');

const productTypes2U = Object.values(PRODUCT_MAP_2U).map(product => product.NAME);

const is2UProductType = (productType) => productTypes2U.includes(productType);

/**
 * @param {Object[]} productList - A list of products, e.g. courses or programs.
 * @returns {Object} An object containing two child objects, allowlist and blocklist,
 * with product UUIDs as keys.
 */
const createLocationRestrictionMap = productList => {
  const locationRestrictionMap = {
    allowlist: {},
    blocklist: {},
  };
  productList.forEach(product => {
    const { locationRestriction } = product;
    if (locationRestriction && (locationRestriction.countries.length > 0 || locationRestriction.states.length > 0)) {
      const { restrictionType, countries, states } = locationRestriction;
      locationRestrictionMap[restrictionType][product.uuid] = { countries, states };
    }
  });
  return locationRestrictionMap;
};

/**
 * @param  {...any} productLists - Any number of product lists.
 * @returns {Object[]} An array of location restriction maps for each product list given.
 */
const getLocationRestrictionMaps = (...productLists) => (
  // Create separate maps on the off chance that products from different lines have the same UUID
  productLists.map(productList => createLocationRestrictionMap(productList))
);

const getProductListByUUID = (uuids, courses, programs) => {
  const uuidToProductMap = new Map();

  courses.forEach(course => {
    uuidToProductMap.set(course.uuid, {
      ...course,
      cardType: 'course',
    });
  });

  programs.forEach(program => {
    uuidToProductMap.set(program.uuid, {
      ...program,
      cardType: 'program',
    });
  });

  return (uuids || []).reduce((accumulator, uuid, index) => {
    const product = uuidToProductMap.get(uuid);

    if (product) {
      accumulator.push({
        ...product,
        cardIndex: index,
      });
    } else if (!process.env.GATSBY_USE_LOCAL_DISCOVERY && !process.env.GATSBY_LIMIT_ONE_PAGE_PER_TYPE) {
      /* eslint-disable no-console */
      console.warn(`product not found with uuid: ${uuid}`);
      /* eslint-enable no-console */
    }

    return accumulator;
  }, []);
};

const getPartnerListByUUID = (uuids, schoolList) => uuids.reduce((accumulator, uuid) => {
  const schoolObj = schoolList.find(school => school.uuid === uuid);

  if (schoolObj && schoolObj.slug) {
    accumulator.push({
      alt: schoolObj.name,
      img: schoolObj.logoImageUrl,
      href: `school/${schoolObj.slug}`,
      isExternal: false,
    });
  }

  return accumulator;
}, []);

// Build a list of program cards that are active and whose type matches the given programType.
const getProgramListByType = (programType, programs) => programs
  .filter(({ type, hidden, status }) => type === programType && !hidden && status === 'active')
  .map((program, index) => ({ ...program, cardIndex: index }));

const getProgramListBy2UType = (programType, programList) => programList
  .filter(({ productLine }) => productLine === programType)
  .map((program, index) => ({ ...program, cardIndex: index }));

const pickProducts = (productUuids, allProducts) => {
  if (productUuids?.length > 0) {
    return productUuids
      .map(uuid => allProducts.find(({ uuid: productUuid }) => uuid === productUuid))
      .filter(Boolean);
  }
  return [];
};

module.exports = {
  getLocationRestrictionMaps,
  getPartnerListByUUID,
  getProductListByUUID,
  getProgramListByType,
  getProgramListBy2UType,
  pickProducts,
  is2UProductType,
};
