/**
* All content of this file comes from the package @edx/frontend-enterprise v.4.1.0
* The following code was bring to prospectus codebase to avoid the necessity of installing
* the whole new package (@edx/frontend-enterprise/utils) to use only one single function.
*
* The function currently used is getLearnerPortalLinks() in src/data/actions/user.jsx.
*/

export const ENTERPRISE_ADMIN = 'enterprise_admin';
export const ENTERPRISE_CATALOG_ADMIN = 'enterprise_catalog_admin';
export const ENTERPRISE_LEARNER = 'enterprise_learner';
export const ENTERPRISE_OPENEDX_OPERATOR = 'enterprise_openedx_operator';

const ENTERPRISE_PERMISSIONS = [
  ENTERPRISE_ADMIN,
  ENTERPRISE_CATALOG_ADMIN,
  ENTERPRISE_LEARNER,
  ENTERPRISE_OPENEDX_OPERATOR,
];

/**
 * Determines whether a role is an enterprise role.
 *
 * @param {string} role The name of a JWT role
 * @returns {boolean} True if the specified role is an enterprise role, otherwise false
 */
export const isEnterpriseRole = role => ENTERPRISE_PERMISSIONS.some(permission => role.includes(permission));

/**
 * Determines whether an authenticated user has a valid enterprise role, or the specified role.
 *
 * @param {object} user An authenticated user
 * @returns {boolean} true if the user has an enterprise role and/or the specified role and/or the specified role
 * for the specific enterprise, otherwise false
 */
export function isEnterpriseUser(user) {
  if (user !== null && user.roles) {
    const { roles } = user;
    return roles.filter(role => isEnterpriseRole(role)).length > 0;
  }
  return false;
}

/**
 * Fetches the metadata for the authenticated user's linked enterprise customers.
 *
 * @param {object} apiClient http client to use to make an API request (e.g., axios)
 * @returns {promise} A promise that, when resolved, provides an API response from the `enterprise-customer`
 *  API endpoint.
 */
const fetchEnterpriseCustomers = apiClient => apiClient.get(`${process.env.GATSBY_LMS_DOMAIN}/enterprise/api/v1/enterprise-customer/`);

function getCacheKey(userId) {
  return `learnerPortalLinks:${userId}`;
}

function cacheLinks(userId, links) {
  sessionStorage.setItem(
    getCacheKey(userId),
    JSON.stringify({
      // Set one hour expiration
      expiration: Date.now() + (1 * 60 * 60 * 1000),
      links,
    }),
  );
}

async function fetchLearnerPortalLinks(apiClient, userId) {
  const learnerPortalLinks = [];
  const learnerPortalHostname = process.env.ENTERPRISE_LEARNER_PORTAL_HOSTNAME;
  if (!learnerPortalHostname) {
    return learnerPortalLinks;
  }
  const response = await fetchEnterpriseCustomers(apiClient);
  const enterpriseCustomers = response.data.results;
  try {
    for (let i = 0; i < enterpriseCustomers.length; i += 1) {
      const enterpriseCustomer = enterpriseCustomers[i];
      const enterpriseCustomerSlug = enterpriseCustomer.slug;
      const enableLearnerPortal = enterpriseCustomer.enable_learner_portal;
      const brandingConfiguration = enterpriseCustomer.branding_configuration;
      if (enableLearnerPortal && enterpriseCustomerSlug) {
        learnerPortalLinks.push({
          // branding_configuration is not always returned as part
          // of the response so check if it exists before referencing fields
          branding_configuration: brandingConfiguration ? {
            logo: brandingConfiguration.logo || null,
            banner_border_color: brandingConfiguration.banner_border_color || null,
            banner_background_color: brandingConfiguration.banner_background_color || null,
          } : null,
          title: enterpriseCustomer.name,
          url: `${window.location.protocol}//${learnerPortalHostname}/${enterpriseCustomerSlug}`,
          uuid: enterpriseCustomer.uuid,
        });
      }
    }

    cacheLinks(userId, learnerPortalLinks);
  } catch (error) {
    // empty
  }

  return learnerPortalLinks;
}

function getCachedLearnerPortalLinks(userId) {
  const cacheKey = getCacheKey(userId);
  const cachedItem = sessionStorage.getItem(cacheKey);

  if (cachedItem) {
    const cachedLinks = JSON.parse(cachedItem);
    // Check cache expiration
    if (cachedLinks.expiration <= Date.now()) {
      sessionStorage.removeItem(cacheKey);
    } else {
      return cachedLinks.links;
    }
  }

  return null;
}

/**
 * Fetches the learner portal links available to an enterprise user.
 *
 * @param {object} apiClient http client to use to make an API request (e.g., axios)
 * @param {object} authenticatedUser An authenticated user object
 * @returns Array of objects with metadata about the learner portal URLs associated with the
 * enterprise customers for which the user is linked.
 */
export default async function getLearnerPortalLinks(apiClient, authenticatedUser) {
  let learnerPortalLinks = [];

  if (authenticatedUser !== null && isEnterpriseUser(authenticatedUser)) {
    const { userId } = authenticatedUser;
    const cachedLinks = getCachedLearnerPortalLinks(userId);

    if (cachedLinks != null) {
      learnerPortalLinks = learnerPortalLinks.concat(cachedLinks);
    } else {
      const links = await fetchLearnerPortalLinks(apiClient, userId);
      learnerPortalLinks = learnerPortalLinks.concat(links);
    }
  }

  return learnerPortalLinks;
}
