import './styles.scss';
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import LinkWithVisibilityTracking from '@prospectus/common/ui-strict/LinkWithVisibilityTracking';
import { useMegaNav } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import { formatRichText } from '@utils/formatting';
import isUserLoggedIn from '@utils/user';

import DismissButton from '../DismissButton';
import { MenuHeroPropType } from './types';

const formatContent = text => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
    },
  };

  return formatRichText(text, options);
};

function MenuHero({
  title,
  subtitle,
  cta,
  cta2,
}: MenuHeroPropType) {
  const { activeCategory } = useMegaNav();
  const isMobile = useBreakpointMatch('medium');

  return (
    <div className="menu-hero position-relative w-100 bg-primary-300 px-3 pt-4.5 pb-5 px-sm-4.5 pt-sm-4 pb-sm-4.5">
      <h2 className="text-white">{title?.raw ? formatContent(title.raw) : title}</h2>
      <p className="menu-hero-description text-white small">{subtitle}</p>
      <div className="menu-hero-cta-group d-flex flex-wrap">
        {cta && (
          <LinkWithVisibilityTracking
            className="btn btn-brand w-100 w-lg-auto"
            url={cta.url}
            eventName="edx.bi.mega-nav.primary-cta.clicked"
            additionalLinkProps={{
              category: activeCategory,
              loggedIn: isUserLoggedIn(),
              navigationLink: true,
              title,
            }}
          >
            {cta.label}
          </LinkWithVisibilityTracking>
        )}
        {cta2 && (
          <LinkWithVisibilityTracking
            className="btn btn-light w-100 w-lg-auto"
            url={cta2.url}
            eventName="edx.bi.mega-nav.secondary-cta.clicked"
            additionalLinkProps={{
              category: activeCategory,
              loggedIn: isUserLoggedIn(),
              navigationLink: true,
              title,
            }}
          >
            {cta2.label}
          </LinkWithVisibilityTracking>
        )}
      </div>
      {!isMobile && <DismissButton />}
    </div>
  );
}

MenuHero.defaultProps = {
  cta: null,
  cta2: null,
};

export default MenuHero;
