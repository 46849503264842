import React from 'react';
import PropTypes from 'prop-types';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';

function ContentfulLink({
  cta: {
    url,
    label,
  },
  className,
  analytics,
  linkProps,
  tracking,
  userLanguage,
  allowFragment,
}) {
  return (
    <ProspectusLink
      href={url}
      className={className}
      analytics={analytics}
      linkProps={linkProps}
      tracking={tracking}
      userLanguage={userLanguage}
      allowFragment={allowFragment}
    >{label}
    </ProspectusLink>
  );
}

ContentfulLink.propTypes = {
  cta: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  analytics: PropTypes.shape({
    eventName: PropTypes.string.isRequired,
    linkProps: PropTypes.shape(),
  }).isRequired,
  linkProps: PropTypes.shape(),
  tracking: PropTypes.shape(),
  userLanguage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  allowFragment: PropTypes.bool,
};

ContentfulLink.defaultProps = {
  allowFragment: false,
  className: '',
  linkProps: {},
  tracking: {},
  userLanguage: false,
};

export default ContentfulLink;
