import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@edx/paragon';

import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';

import './styles.scss';

function FloatingActionButton({
  label, className, onClick, style, children, ...rest
}) {
  const isMobile = useBreakpointMatch('medium');
  return (
    <Button
      data-testid="floating-action-button"
      type="button"
      variant="custom"
      aria-label={label}
      className={cx({
        'floating-action-btn__icon': !isMobile,
        'floating-action-btn__icon--mobile': isMobile,
      }, className)}
      style={style}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
}

FloatingActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  ...Button.propTypes,
};

FloatingActionButton.defaultProps = {
  className: '',
  style: {},
};

export default FloatingActionButton;
