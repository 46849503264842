import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import track, { useTracking } from 'react-tracking';
import classNames from 'classnames';
import { SearchField } from '@edx/paragon';
import { defineMessages, injectIntl } from 'gatsby-plugin-react-intl';
import { submitEvent } from '@utils/analytics';

import { intlShape } from '@prospectus/common/types/intlShape';
import { NavContext } from '@utils/context';

const messages = defineMessages({
  'prospectus.search.autoComplete.courses.title': {
    id: 'prospectus.search.autoComplete.courses.title',
    description: 'Heading for the Courses section of autoComplete suggestions.',
    defaultMessage: 'Courses',
  },
  'prospectus.search.autoComplete.programs.title': {
    id: 'prospectus.search.autoComplete.programs.title',
    description: 'Heading for the Programs section of autoComplete suggestions.',
    defaultMessage: 'Programs',
  },
  'prospectus.search.searchbar.placeholder': {
    id: 'prospectus.search.searchbar.placeholder',
    description: 'Placeholder text in a search input field',
    defaultMessage: 'What do you want to learn?',
  },
  'prospectus.search.autocomplete.cta': {
    id: 'prospectus.search.autocomplete.cta',
    description: 'Button text to take users to the full page of search results for their query',
    defaultMessage: 'View all results',
  },
  'prospectus.header.searchbar.button.text': {
    id: 'prospectus.header.searchbar.button.text',
    description: 'Label for the search submission button',
    defaultMessage: 'Search',
  },
  'prospectus.header.searchbar.button.submit-sr': {
    id: 'prospectus.header.searchbar.button.submit-sr',
    description: 'Screen reader text for the button which submits a search query.',
    defaultMessage: 'Submit your search query',
  },
  'prospectus.header.searchbar.close-button.screen-reader-text': {
    id: 'prospectus.header.searchbar.close-button.screen-reader-text',
    description: 'Screen reader label for close button',
    defaultMessage: 'Exit out of expanded search bar (will make navigation links available again)',
  },
  'prospectus.search.searchbar.button.clear-sr': {
    id: 'prospectus.search.searchbar.button.clear-sr',
    description: 'Screen reader text for the button which clears a search query.',
    defaultMessage: 'Clear your search query',
  },
});

function HeaderSearchBox({
  query,
  setQuery,
  searchRef,
  id,
  searchInputId,
  intl,
  placeholder,
  activeOption,
  onSubmit,
  onBlur,
  onFocus,
  onClear,
}) {
  const searchSubmitId = `${id}-search-submit`;
  const isSpanish = intl.locale === 'es';
  const context = useContext(NavContext);
  const searchAutocompleteId = `${id}-search-autocomplete`;
  const tracking = useTracking();

  const updateQuery = (value) => {
    setQuery(value);
  };

  const clearQuery = () => {
    setQuery('');
    onClear();
  };
  const handleOnFocus = () => {
    submitEvent(tracking, {
      category: 'search',
    }, 'edx.bi.user.search.box.clicked');
  };

  const { searchIsActive } = context;

  return (
    <div
      className="d-flex"
      ref={searchRef}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <SearchField.Advanced
        onSubmit={onSubmit}
        onChange={updateQuery}
        onFocus={handleOnFocus}
        onClear={clearQuery}
        submitButtonLocation={searchIsActive ? 'external' : 'internal'}
        screenReaderText={{
          clearButton: intl.formatMessage(messages['prospectus.search.searchbar.button.clear-sr']),
          label: intl.formatMessage(messages['prospectus.header.searchbar.button.text']),
          submitButton: intl.formatMessage(messages['prospectus.header.searchbar.button.submit-sr']),
        }}
        value={query}
      >
        <div
          className={`pgn__searchfield_wrapper search-box-wrapper position-relative d-flex align-items-center justify-content-between ${searchIsActive ? 'show-as-focused' : ''}`}
          id={searchInputId}
        >
          <SearchField.Input
            aria-label="Search"
            autoComplete="off"
            className={classNames(
              `search-input pl-3 small ${isSpanish ? 'spanish' : 'default'}`,
              { 'has-query ': !!query },
            )}
            placeholder={placeholder}
            aria-controls={`${searchAutocompleteId}`}
            aria-activedescendant={activeOption}
          />
          {!searchIsActive && (
            <SearchField.SubmitButton
              submitButtonLocation="internal"
              disabled
              id={searchSubmitId}
            />
          )}
        </div>
        {searchIsActive && (
          <SearchField.SubmitButton
            submitButtonLocation="external"
            id={searchSubmitId}
            buttonText={intl.formatMessage(messages['prospectus.header.searchbar.button.text'])}
          />
        )}
      </SearchField.Advanced>
    </div>
  );
}

HeaderSearchBox.propTypes = {
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  searchInputId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  searchRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]).isRequired,
  placeholder: PropTypes.string,
  activeOption: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

HeaderSearchBox.defaultProps = {
  placeholder: '',
  activeOption: '',
  onClear: () => {},
};

export default track({
  component: 'search-page-search-box',
})(injectIntl(HeaderSearchBox));
