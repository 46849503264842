import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Collapsible } from '@edx/paragon';

const CollapsibleTrigger = React.forwardRef(({
  tag, children, openOnly, closeOnly, ...props
}, ref) => {
  const {
    isOpen, open, close, toggle,
  } = useContext(Collapsible.Context);

  const handleToggle = (e) => {
    if (openOnly) {
      open(e);
    } else if (closeOnly) {
      close(e);
    } else {
      toggle(e);
    }
  };

  const handleClick = useCallback((e) => {
    if (props.onClick) {
      props.onClick(e);
    }
    handleToggle(e);
  });

  const handleKeyDown = useCallback((e) => {
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
    if (e.key === 'Enter') {
      handleToggle(e);
    }
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      open(e);
    }
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      close(e);
    }
  });

  return React.createElement(
    tag,
    {
      ...props,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      role: 'button',
      tabIndex: 0,
      'aria-expanded': isOpen,
      ref,
    },
    children,
  );
});

CollapsibleTrigger.propTypes = {
  /** Specifies contents of the component. */
  children: PropTypes.node,
  /** Specifies base element. */
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  /** Specifies whether toggling `Collapsible's` state will always trigger only open action. */
  openOnly: PropTypes.bool,
  /** Specifies whether toggling `Collapsible's` state will always trigger only close action. */
  closeOnly: PropTypes.bool,
  /** Callback fired when element gets clicked. */
  onClick: PropTypes.func,
  /** Callback fired when a key is pressed. */
  onKeyDown: PropTypes.func,
};

CollapsibleTrigger.defaultProps = {
  children: undefined,
  tag: 'div',
  openOnly: false,
  closeOnly: false,
  onClick: undefined,
  onKeyDown: undefined,
};

export default CollapsibleTrigger;
