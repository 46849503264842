import './styles/index.scss';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import { injectIntl, defineMessages, FormattedMessage } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import getLinkProps from '@utils/reactScroll';
import { SiteNavContext } from '@utils/context';
import { allCurrencyShape } from '@prospectus/common/types/allCurrencyShape';
import SiteHeader from './components/SiteHeader';

const messages = defineMessages({
  'prospectus.header.main_label': {
    id: 'prospectus.header.main_label',
    defaultMessage: 'Main',
    description: 'Screenreader text identifying the main section of the header.',
  },
});

function Header(
  {
    intl, allCurrency, location, showMegaNav,
  },
) {
  const linkProps = getLinkProps(() => {}, 0);

  const mainContentId = 'main-content';

  const handleSkiplinkClick = () => {
    document.getElementById(mainContentId).focus({ preventScroll: true });
  };

  const isMobile = useBreakpointMatch('medium');

  return (
    <SiteNavContext.Consumer>
      {({ mobileNavIsOpen, mobileSearchbarIsOpen }) => (
        <header
          className={classNames('region region-identity edx-header', { scrollable: !mobileNavIsOpen && !mobileSearchbarIsOpen })}
          aria-label={intl.formatMessage(messages['prospectus.header.main_label'])}
        >
          <Link
            {...linkProps}
            to={mainContentId}
            href={`#${mainContentId}`}
            className="show-on-focus"
            onClick={handleSkiplinkClick}
          >
            <FormattedMessage
              id="prospectus.layout.skiplink.mainContent"
              defaultMessage="Skip to main content"
              description="Label for skip link to allow keyboard navigation users to skip to the page's main content."
            />
          </Link>
          <SiteHeader
            location={location}
            allCurrency={allCurrency}
            showMegaNav={showMegaNav}
            isMobile={isMobile}
            // hasBootCamps={hasBootCamps}
          />
        </header>
      )}
    </SiteNavContext.Consumer>
  );
}

Header.propTypes = {
  intl: intlShape.isRequired,
  allCurrency: allCurrencyShape,
  location: PropTypes.shape({}),
  showMegaNav: PropTypes.bool,
};

Header.defaultProps = {
  allCurrency: {},
  location: {},
  showMegaNav: true,
};

export default injectIntl(Header);
