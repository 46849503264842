import { graphql, useStaticQuery } from 'gatsby';

export const useCareerFlyoutData = () => {
  const { contentfulNavigationFlyout } = useStaticQuery(
    graphql`
      query CareerFlyoutQuery {
        contentfulNavigationFlyout(
          categoryId: { eq: "career" }
        ) {
          menuLabel
          categoryId
          hero {
            ...HeroFragment
          }
          navigationLinkLists {
            heading
            description
            links {
              ...LinkFragment
            }
            cta {
              ...LinkFragment
            }
            ctaVariant
          }
        }
      }
    `,
  );

  return contentfulNavigationFlyout;
};
