const GOAL = 'goal' as const;
const TOPIC = 'topic' as const;
const FLYOUT_ID = 'nav-flyout';

// ACTIONS
const OPEN_MEGA_NAV = 'OPEN_MEGA_NAV';
const CLOSE_MEGA_NAV = 'CLOSE_MEGA_NAV';
const TOGGLE_MEGA_NAV = 'TOGGLE_MEGA_NAV';
const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
const CLEAR_ACTIVE_CATEGORY = 'CLEAR_ACTIVE_CATEGORY';
const SET_CATEGORIES = 'SET_CATEGORIES';
const SET_MENU_ITEMS = 'SET_MENU_ITEMS';

export {
  CLEAR_ACTIVE_CATEGORY,
  CLOSE_MEGA_NAV,
  FLYOUT_ID,
  GOAL,
  OPEN_MEGA_NAV,
  SET_ACTIVE_CATEGORY,
  SET_CATEGORIES,
  SET_MENU_ITEMS,
  TOGGLE_MEGA_NAV,
  TOPIC,
};
