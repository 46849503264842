import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
} from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import MenuAccordion from './MenuAccordion';
import messages from '../SiteNav/constant/messages';

function MobileBootCampLinks({
  intl,
  collapsibleRef,
  onCollapsibleOpen,
}) {
  const initialProductLinks = [
    {
      href: '/boot-camps/coding?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.coding_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.masters.clicked',
      },
    },
    {
      href: 'boot-camps/ai?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.microbootcamps_link_text'])}
        </>
      ),
      analytics: {
        eventName: 'edx.bi.link.site-nav.microbootcamps.clicked',
      },
      newBadge: true,
    },
    {
      href: '/boot-camps/cybersecurity?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.cybersecurity_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.bachelors.clicked',
      },
    },
    {
      href: '/boot-camps/data-analytics?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.analytics_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.boot-camps.clicked',
      },
    },
    {
      href: '/boot-camps/fintech?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.fintech_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.fintech.clicked',
      },
    },
    {
      href: '/boot-camps/digital-marketing?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.digital-marketing_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.digital-marketing.clicked',
      },
    },
    {
      href: '/boot-camps/product-management?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.product-managment_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.product-managment.clicked',
      },
    },
    {
      href: '/boot-camps/tech-project-management?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.project-managment_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.project-managment.clicked',
      },
    },
    {
      href: '/boot-camps/ux-ui-user-experience?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.ux-ui-user-experience_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.ux-ui-user-experience.clicked',
      },
    },
    {
      href: '/boot-camps?linked_from=sitenav',
      label: intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.all_link_text']),
      analytics: {
        eventName: 'edx.bi.link.site-nav.boot-camps.clicked',
      },
    },
  ];

  const [bootCampLinks, setBootCampLinks] = useState(initialProductLinks);

  useEffect(() => {
    const links = initialProductLinks;

    setBootCampLinks(links);
  }, []);

  return (
    <MenuAccordion
      title={intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp_heading'])}
      id="boot-camp-links"
      links={bootCampLinks}
      ref={collapsibleRef}
      onCollapsibleOpen={onCollapsibleOpen}
    />
  );
}

MobileBootCampLinks.propTypes = {
  intl: intlShape.isRequired,
  onCollapsibleOpen: PropTypes.func.isRequired,
  collapsibleRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]).isRequired,
};

export default injectIntl(MobileBootCampLinks);
