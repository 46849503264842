import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withPrefix } from 'gatsby';
import memoizeOne from 'memoize-one';
import { defineMessages, injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import { EdxUserInfo } from '@utils/cookies';
import { NavContext } from '@utils/context';
import {
  dispatchGetUserCurrencyAndSetCookie,
  getUserEnterprisePortalLinks,
  getUserLanguage,
  getUserLoggedIn,
} from '@data/actions/user';
import { allCurrencyShape } from '@prospectus/common/types/allCurrencyShape';
import LearnMenuButton from '@prospectus/common/ui-strict/MegaNav/components/LearnMenuButton';
import SiteNav from '../SiteNav';
import UserMenu from '../UserMenu';
import SearchBar from '../SearchBar';
import enterprisePortalLinkShape from '../../enterprisePortalLinkShape';
import BusinessLink from './components/EdxForBusinessDesktopLink';

function BaseSiteHeader({
  requestUserEnterprisePortalLinks,
  requestUserLanguage,
  requestUserLoggedIn,
  requestSetUserCurrency,
  allCurrency,
  intl,
  userEnterprisePortalLinks,
  userLoggedIn,
  location,
  showMegaNav,
}) {
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [userIsClient, setUserIsClient] = useState(false);

  useEffect(() => {
    if (allCurrency.edges) {
      const { edges: currencyEdges } = allCurrency;
      const currencyNodes = currencyEdges.map(
        ({ node: currencyNode }) => currencyNode,
      );
      requestSetUserCurrency(currencyNodes);
    }

    requestUserEnterprisePortalLinks();
    requestUserLanguage();
    requestUserLoggedIn();
  }, []);

  const userDetails = userLoggedIn ? EdxUserInfo() : false;

  const navContext = (searchIsActiveState) => ({
    searchIsActive: searchIsActiveState,
    setSearchIsActive: (isActive) => setSearchIsActive(isActive),
  });

  const memoizedNavContext = memoizeOne(navContext);

  const messages = defineMessages({
    'prospectus.header.site_header.logo.link_title': {
      id: 'prospectus.header.site_header.logo.link_title',
      defaultMessage: 'Home page',
      description:
        'Link description for the home page logo (for accessibility)',
    },
    'prospectus.header.site_header.logo.img_alt': {
      id: 'prospectus.header.site_header.logo.img_alt',
      defaultMessage: 'edX Online',
      description: 'Image alt-text for the home page logo (for accessibility)',
    },
    'prospectus.header.searchbar.placeholder.desktop': {
      id: 'prospectus.header.searchbar.placeholder.desktop',
      defaultMessage: 'What do you want to learn?',
      description: 'The placeholder text for the nav search bar desktop state',
    },
    'prospectus.header.searchbar.placeholder.tablet': {
      id: 'prospectus.header.searchbar.placeholder.tablet',
      defaultMessage: 'Search online courses',
      description: 'The placeholder text for the nav search bar on tablet',
    },
  });

  let headerImage = (
    <ProspectusLink
      href="/"
      title={intl.formatMessage(
        messages['prospectus.header.site_header.logo.link_title'],
      )}
      itemProp="url"
      analytics={{
        eventName: 'edx.bi.user.site.header.click',
      }}
      key="header-logo"
    >
      <img
        className="site-logo image-style-none"
        itemProp="logo"
        typeof="foaf:Image"
        src={withPrefix('/images/logos/edx-logo-elm.svg')}
        alt={intl.formatMessage(
          messages['prospectus.header.site_header.logo.img_alt'],
        )}
      />
    </ProspectusLink>
  );

  if (userEnterprisePortalLinks.length > 0) {
    // Only need the first returned value for the logo
    const userEnterpriseLogoLink = userEnterprisePortalLinks[0];
    headerImage = (
      <div className="enterprise-logo-heading">
        <ProspectusLink
          href={userEnterpriseLogoLink.url}
          itemProp="url"
          analytics={{
            eventName: 'edx.bi.user.site.header.click',
          }}
        >
          <img
            itemProp="logo"
            typeof="foaf:Image"
            src={userEnterpriseLogoLink.branding_configuration.logo}
            alt={userEnterpriseLogoLink.title}
          />
        </ProspectusLink>
      </div>
    );
  }

  const handleIsClient = (isClient) => {
    setUserIsClient(isClient);
  };

  return (
    <NavContext.Provider value={memoizedNavContext(searchIsActive)}>
      <div className="header-search site-header-content align-items-center">
        <div id="branding" className="branding-elements-desktop">
          <div id="logo" className="edx-logo">
            <div className="edx-logo-heading">{headerImage}</div>
          </div>
        </div>
        <SiteNav
          userDetails={userDetails}
          userLoggedIn={userLoggedIn}
          hideNavLinks={searchIsActive}
          showMegaNav={showMegaNav}
          location={location}
        />
        <div id="branding" className="branding-elements-mobile">
          <div id="logo" className="edx-logo">
            <div className="edx-logo-heading">{headerImage}</div>
          </div>
        </div>
        {/* WS-4311: This learn menu button was added for testing
         * It will be updated to look more like the design once we go live with MegaNav
         * @TODO: Update the Learn button to match spec
         */}
        <div className="d-flex">
          {showMegaNav && (
            <LearnMenuButton />
          )}
          <div className="desktop-search-bar justify-content-center align-items-center">
            <SearchBar
              placeholder={intl.formatMessage(
                messages['prospectus.header.searchbar.placeholder.desktop'],
              )}
              idSuffix="desktop"
              location={location}
            />
          </div>
          <div className="tablet-search-bar">
            <SearchBar
              placeholder={intl.formatMessage(
                messages['prospectus.header.searchbar.placeholder.tablet'],
              )}
              idSuffix="tablet"
              location={location}
            />
          </div>
        </div>
        <div className="flex-grow-desktop" />
        {userIsClient && (
          <div
            className={classNames(
              'business-link visible-desktop',
              { 'user-logged-in': userLoggedIn },
              { 'is-spanish': intl.locale === 'es' },
            )}
          >
            <BusinessLink />
          </div>
        )}
        <UserMenu
          className={classNames('user-menu-parallelogram-wrapper', {
            'd-lg-flex': showMegaNav,
          })}
          userDetails={userDetails}
          userLoggedIn={userLoggedIn}
          userEnterprisePortalLinks={userEnterprisePortalLinks}
          alignDropdownRight={false}
          onIsClient={handleIsClient}
          showMegaNav={showMegaNav}
        />
      </div>
    </NavContext.Provider>
  );
}

BaseSiteHeader.propTypes = {
  requestUserLanguage: PropTypes.func,
  requestUserLoggedIn: PropTypes.func,
  userEnterprisePortalLinks: PropTypes.arrayOf(
    PropTypes.shape(enterprisePortalLinkShape),
  ),
  userLoggedIn: PropTypes.bool,
  requestSetUserCurrency: PropTypes.func,
  requestUserEnterprisePortalLinks: PropTypes.func,
  allCurrency: allCurrencyShape,
  intl: intlShape.isRequired,
  location: PropTypes.shape({}),
  showMegaNav: PropTypes.bool,
};

BaseSiteHeader.defaultProps = {
  requestUserLanguage: () => {},
  requestUserLoggedIn: () => {},
  requestSetUserCurrency: () => {},
  requestUserEnterprisePortalLinks: () => {},
  userEnterprisePortalLinks: [],
  allCurrency: {},
  userLoggedIn: false,
  location: {},
  showMegaNav: true,
};

const mapStateToProps = (store) => ({
  userLoggedIn: store.userLoggedIn,
  userEnterprisePortalLinks:
    store.userEnterprisePortalLinks
    && store.userEnterprisePortalLinks.userEnterprisePortalLinks,
});

const mapDispatchToProps = (dispatch) => ({
  requestUserLanguage() {
    dispatch(getUserLanguage());
  },
  requestUserLoggedIn() {
    dispatch(getUserLoggedIn());
  },
  requestSetUserCurrency(allCurrency) {
    dispatch(dispatchGetUserCurrencyAndSetCookie(allCurrency));
  },
  requestUserEnterprisePortalLinks() {
    dispatch(getUserEnterprisePortalLinks());
  },
});

const SiteHeader = connect(mapStateToProps, mapDispatchToProps)(BaseSiteHeader);

export default injectIntl(SiteHeader);
