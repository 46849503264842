// @file
// If this were put into a global store,
// all of the autocomplete enabled search inputs
// would update and display as the global store is updated.
// This allows autocomplete state to easily be managed by
// a central parent, while also not being global.
import { useState, useDeferredValue } from 'react';
import { defaultAutocompleteResultsObject } from '@utils/search';

function useAutocomplete(defaultQuery = '') {
  const [query, setQuery] = useState(defaultQuery);
  const deferredQuery = useDeferredValue(query);
  const [preQueryOpen, setPreQueryOpen] = useState(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [results, setResults] = useState(defaultAutocompleteResultsObject);
  const resetResults = (defaults = defaultAutocompleteResultsObject) => {
    setResults(defaults);
  };

  // Return value keys are named to match the keys
  // needed by the components/Search/AutoComplete.jsx component
  // in order to properly display the query and autocomplete results.
  // This does not provide all values required by that
  // component, but should be the central place to manage state
  // for autocomplete if it has to be managed outside of the
  // component itself.
  return {
    query: deferredQuery,
    setQuery,
    preQueryOpen,
    setPreQueryOpen,
    autocompleteOpen,
    setAutocompleteOpen,
    autocompleteDisabled: isDisabled,
    setAutocompleteDisabled: setIsDisabled,
    autocompleteResults: results,
    setAutocompleteResults: setResults,
    resetResults,
  };
}

export default useAutocomplete;
