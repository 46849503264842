import Cookies from 'js-cookie';
import { whereAlpha2 } from 'iso-3166-1';
import getLearnerPortalLinks from '@utils/learnerPortalLinks';
import apiClient, { fetchAuthenticatedUser } from '@data/apiClient';

import {
  getLanguagePreference,
  getGeoLocaleAlpha2, setCurrencyInfoCookie, removeCurrencyInfoCookie,
} from '@utils/cookies';
import { getLmsLink } from '@utils/edxLinks';
import { getBrowserLanguage } from '@utils/i18n';
import {
  SET_USER_LANGUAGE,
  SET_USER_LOGGED_IN,
  USER_ENROLLMENTS_ERROR,
  USER_ENROLLMENTS_SUCCESS,
  USER_ENTERPRISE_PORTAL_LINKS_ERROR,
  USER_ENTERPRISE_PORTAL_LINKS_SUCCESS,
  USER_ENTITLEMENTS_ERROR,
  USER_ENTITLEMENTS_SUCCESS,
  SET_USER_CURRENCY, USER_ENTITLEMENTS_PENDING, USER_ENROLLMENTS_PENDING,
} from '@data/constants/actionTypes/user';

export const setUserLoggedIn = value => ({
  type: SET_USER_LOGGED_IN,
  value,
});

export const userEnrollmentsError = error => ({
  type: USER_ENROLLMENTS_ERROR,
  error,
});

export const userEnrollmentsSuccess = value => ({
  type: USER_ENROLLMENTS_SUCCESS,
  value,
});

export const userEnrollmentsPending = value => ({
  type: USER_ENROLLMENTS_PENDING,
  value,
});

export const userEntitlementsError = error => ({
  type: USER_ENTITLEMENTS_ERROR,
  error,
});

export const userEntitlementsSuccess = value => ({
  type: USER_ENTITLEMENTS_SUCCESS,
  value,
});

export const userEntitlementsPending = value => ({
  type: USER_ENTITLEMENTS_PENDING,
  value,
});

export const userEnterprisePortalLinksError = error => ({
  type: USER_ENTERPRISE_PORTAL_LINKS_ERROR,
  error,
});

export const userEnterprisePortalLinksSuccess = value => ({
  type: USER_ENTERPRISE_PORTAL_LINKS_SUCCESS,
  value,
});

export const setUserLanguage = value => ({
  type: SET_USER_LANGUAGE,
  value,
});

export const setUserCurrency = value => ({
  type: SET_USER_CURRENCY,
  value,
});

export const getUserLanguage = () => (dispatch) => {
  const validLanguages = JSON.parse(process.env.GATSBY_LANGUAGES);
  validLanguages.push('en');

  // If language preference set use that
  const langPreference = getLanguagePreference();

  if (langPreference && validLanguages.includes(langPreference)) {
    return dispatch(setUserLanguage(langPreference));
  }

  const browserLanguage = getBrowserLanguage();
  if (browserLanguage && validLanguages.includes(browserLanguage)) {
    dispatch(setUserLanguage(browserLanguage));
  }

  return dispatch(setUserLanguage(false));
};

export const getUserLoggedIn = () => (dispatch) => {
  const userLoggedIn = !!Cookies.get('edxloggedin')
    || (process.env.GATSBY_ENVIRONMENT === 'development' && process.env.GATSBY_USER_FORCE_LOGGED_IN === 'true');

  return dispatch(setUserLoggedIn(userLoggedIn));
};

export const getUserCurrencyAndSetCookie = (currencyNodes) => {
  const localeAlpha2 = getGeoLocaleAlpha2();
  let currencyInfo = {};
  if (localeAlpha2) {
    const fullLocaleObject = whereAlpha2(localeAlpha2);
    if (fullLocaleObject) {
      const { alpha3: localeAlpha3 } = fullLocaleObject;
      const currencyNode = currencyNodes.find(({ ISO3 }) => ISO3 === localeAlpha3);
      if (currencyNode) {
        // need to wrap this line in () for destructuring to work due to JS weirdness
        ({ currencyInfo } = currencyNode);
        if (currencyInfo) {
          setCurrencyInfoCookie(currencyInfo, localeAlpha3);
        }
      }
    }
  }

  if (Object.keys(currencyInfo).length === 0) {
    removeCurrencyInfoCookie();
  }
  return currencyInfo;
};

export const dispatchGetUserCurrencyAndSetCookie = currencyNodes => (dispatch) => {
  const currencyInfo = getUserCurrencyAndSetCookie(currencyNodes);
  dispatch(setUserCurrency(currencyInfo));
};

export const getUserEnrollments = (refresh = false) => (dispatch, getState) => {
  // Avoid making the same api call multiple times.
  const state = getState();
  const status = state.userEnrollments && state.userEnrollments.status
    ? state.userEnrollments.status
    : false;
  if (!refresh && (status && !['idle', 'error'].includes(status))) {
    return;
  }
  // Before making the api call, ensure that the state holds the
  // pending status for this information.
  dispatch(userEnrollmentsPending());
  apiClient.get(
    getLmsLink(process.env.GATSBY_USER_ENROLLMENT_API),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  ).then((response) => {
    if (response.ok || response.status === 200) {
      return dispatch(userEnrollmentsSuccess(response));
    }
    const error = new Error(response.statusText);
    error.response = response;

    return dispatch(userEnrollmentsError(error));
  }).catch(error => dispatch(userEnrollmentsError(error)));
};

export const getUserEntitlements = (refresh = false) => (dispatch, getState) => {
  // Avoid making the same api call multiple times.
  const state = getState();
  const status = state.userEntitlements && state.userEntitlements.status
    ? state.userEntitlements.status
    : false;
  if (!refresh && (status && !['idle', 'error'].includes(status))) {
    return;
  }
  // Before making the api call, ensure that the state holds the
  // pending status for this information.
  dispatch(userEntitlementsPending());
  apiClient.get(
    getLmsLink(process.env.GATSBY_USER_ENTITLEMENT_API),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  ).then((response) => {
    if (response.ok || response.status === 200) {
      return dispatch(userEntitlementsSuccess(response));
    }
    const error = new Error(response.statusText);
    error.response = response;

    return dispatch(userEntitlementsError(error));
  }).catch(error => dispatch(userEntitlementsError(error)));
};

export const getUserEnterprisePortalLinks = () => (dispatch) => {
  const userLoggedIn = !!Cookies.get('edxloggedin');
  if (userLoggedIn) {
    const authenticatedUser = fetchAuthenticatedUser();
    authenticatedUser.then((user) => {
      if (user) {
        return getLearnerPortalLinks(
          apiClient,
          user,
          process.env.ENTERPRISE_LEARNER_PORTAL_HOSTNAME,
          process.env.GATSBY_LMS_DOMAIN,
        ).then(
          learnerPortalLinks => dispatch(userEnterprisePortalLinksSuccess(learnerPortalLinks)),
        ).catch(
          error => dispatch(userEnterprisePortalLinksError(error)),
        );
      }
      const error = new Error('No authenticated user found despite being logged in.');
      return dispatch(userEnterprisePortalLinksError(error));
    }).catch(error => dispatch(userEnterprisePortalLinksError(error)));
  }
};
