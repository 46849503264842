// @file
// Defines a hook for dealing with and reacting
// to changes in the URL's search parameters.  These
// helpers are taken from react-router-dom and are recreated
// because @reach/router does not provide a way to work
// with search params.
// By using @reach/router instead of react-router-dom, we are
// in better alignment with Gatsby and can more easily navigate
// programatically (and with less overhead as we don't also have
// to include react-router-dom).
import React, { useRef } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { navigate } from 'gatsby-link';
import { IntlContext } from 'gatsby-plugin-react-intl';
import { WebviewContext } from '@utils/context';

import {
  ALL_AVAILABLE_FACETS,
  isValidParam,
  getIntlSearchPath,
  hasExtraneousParams,
  SEARCH_PATH,
} from '@utils/search';

const createSearchParams = (initial = '') => new URLSearchParams(
  typeof initial === 'string' || Array.isArray(initial) || initial instanceof URLSearchParams
    ? initial
    : Object.keys(initial).reduce((memo, key) => {
      const value = initial[key];
      return memo.concat(
        Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]],
      );
    }, []),
);

const cleanupSearchParams = (params, maintain = []) => {
  if (!hasExtraneousParams(params.toString(), maintain)) {
    return params;
  }

  Array.from(params.keys()).forEach(key => {
    if (!isValidParam(key, maintain)) {
      params.delete(key);
    }
  });

  return params;
};

const useSearchParams = (defaultInit = '') => {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  const location = useLocation();
  const { locale } = React.useContext(IntlContext);
  const webview = React.useContext(WebviewContext) || false;

  const searchParams = React.useMemo(() => {
    const localSearchParams = createSearchParams(location.search);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of defaultSearchParamsRef.current.keys()) {
      if (!localSearchParams.has(key)) {
        defaultSearchParamsRef.current.getAll(key).forEach((value) => {
          localSearchParams.append(key, value);
        });
      }
    }

    return localSearchParams;
  }, [location.search]);

  const setSearchParams = React.useCallback((nextInit, navigateOptions) => {
    const params = createSearchParams(nextInit);
    // Remove empty keys
    Array.from(params.keys()).forEach(key => {
      if (!params.get(key)) {
        params.delete(key);
      }
    });
    const paramsWithPrefix = Array.from(params).length ? `?${params}` : '';

    const currentSearchPath = location.pathname + location.search;
    const newSearchPath = `/${getIntlSearchPath(SEARCH_PATH, locale, webview)}${paramsWithPrefix}`;

    if (currentSearchPath !== newSearchPath) {
      navigate(newSearchPath, { ...navigateOptions, replace: true });
    }
  }, [navigate, location.search]);

  const addSearchParam = React.useCallback((key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  }, [location.search]);

  const updateSearchParams = React.useCallback((newParams) => {
    Object.keys(newParams).forEach((key) => {
      const value = newParams[key];
      // Special handling for repeated key refinements.
      // `program_type=MicroMasters&program_type=XSeries`
      if (Array.isArray(value)) {
        const values = value;
        searchParams.delete(key);
        values.forEach(item => {
          searchParams.append(key, item);
        });
      } else {
        searchParams.set(key, value);
      }
    });
    setSearchParams(searchParams);
  }, [location.search]);

  const removeSearchParam = React.useCallback((key, value = '') => {
    // Some params can have multiple values, in which case
    // we need to handle those params differently.
    const allValues = searchParams.getAll(key);
    if (allValues.length === 1) {
      searchParams.delete(key);

      // Pagination url parameter `&page` is useless without product type parameter (`&tab`).
      if (key === 'tab' && searchParams.has('page')) {
        searchParams.delete('page');
      }
      setSearchParams(searchParams);
      return;
    }
    // Refinements have repeated keys
    if (allValues.length > 1) {
      const refinementValues = allValues.filter(refinementValue => refinementValue !== value);
      searchParams.set(key, refinementValues);
      setSearchParams(searchParams);
    }
  }, [location.search]);

  const clearRefinements = React.useCallback((maintain = []) => {
    // When only tab parameter is set, prevent ?q=undefined
    const params = ['q'].concat(maintain).reduce((paramsToMaintain, param) => {
      const maintainedParams = paramsToMaintain;
      if (searchParams.get(param)) {
        maintainedParams[param] = searchParams.get(param);
      }

      return maintainedParams;
    }, {});

    setSearchParams(params);
  }, [location.search]);

  const clearSearch = React.useCallback(() => {
    setSearchParams({});
  }, [location.search]);

  const getQuery = React.useCallback(() => searchParams.get('q') || '', [location.search]);

  const getRefinements = React.useCallback(() => {
    const newRefinements = {};
    ALL_AVAILABLE_FACETS.forEach(facetName => {
      const facetParams = searchParams.getAll(facetName);
      if (facetParams.length) {
        newRefinements[facetName] = facetParams;
      }
    });
    return newRefinements;
  }, [location.search]);

  const rewriteBrowserUrlWithoutExtraneousParams = (maintain = []) => {
    if (!window || !window.history) {
      return;
    }
    if (!hasExtraneousParams(searchParams.toString(), maintain)) {
      return;
    }
    const params = cleanupSearchParams(searchParams, maintain);
    const paramsWithPrefix = Array.from(params).length ? `?${params}` : '';
    window.history.replaceState(location.state, null, `/${getIntlSearchPath(SEARCH_PATH, locale, webview)}${paramsWithPrefix}`);
  };

  return {
    searchParams,
    setSearchParams,
    addSearchParam,
    updateSearchParams,
    removeSearchParam,
    clearRefinements,
    clearSearch,
    getQuery,
    getRefinements,
    rewriteBrowserUrlWithoutExtraneousParams,
    cleanupSearchParams,
  };
};

export default useSearchParams;
