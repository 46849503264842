const getLinkProps = (handleActive, offset) => (
  {
    activeClass: 'active',
    className: 'link',
    spy: true,
    smooth: true,
    offset,
    duration: 750,
    onSetActive: handleActive,
  }
);

export default getLinkProps;
