module.exports = {
  title: 'edX Online',
  company: 'edX',
  description: 'Gain new skills, advance your career, or learn something just for fun. edX is the trusted platform for education and learning offering 2900+ courses from the world\'s best institutions including Harvard, MIT, Microsoft, and more. Learn computer or data science, business, engineering, finance, history, language and more. Take advantage of flexible learning on your schedule. Start today.',
  url: '',
  image: '',
  siteName: 'edX',
  locale: 'en-US',
  twitter: '@edXOnline',
  fbAppID: '',
  apple: {
    id: '945480667',
    url: 'https://itunes.apple.com/us/app/edx/id945480667?mt=8',
  },
};
