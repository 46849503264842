import classNames from 'classnames';
import React, { useMemo } from 'react';
import useMousePosition from './hooks/useMousePosition';

type SafeAreaType = {
  menuItem: HTMLElement;
  menu: HTMLElement;
};

/**
  Creates a triangle starting from the user's cursor and ending at the right side of the menu, which allows the user
  to pass over other menu items without triggering them to a certain extent.

  Adapted from the demo in https://www.smashingmagazine.com/2023/08/better-context-menus-safe-triangles/
*/
function SafeArea({ menuItem, menu }: SafeAreaType) {
  const { x: mouseX, y: mouseY } = useMousePosition();

  const {
    width: menuItemWidth,
    x: menuItemX,
  } = menuItem.getBoundingClientRect();

  const {
    height: menuHeight,
    y: menuY,
  } = menu.getBoundingClientRect();

  const menuItemRightPosition = menuItemX + menuItemWidth + 4;

  const svgWidth = useMemo(() => {
    // if the mouse is not in the menu item area,
    // we don't want the svg to have any width
    // because otherwise it messes with the actual container
    // and introduces flickering
    if (mouseX > menuItemWidth) {
      return 0;
    }

    return menuItemRightPosition - mouseX;
  }, [mouseX]);

  const svgHeight = menuHeight;

  return (
    <svg
      className={classNames('position-fixed', { 'zindex-1': mouseX < menuItemRightPosition })}
      style={{
        width: svgWidth,
        height: menuHeight,
        pointerEvents: 'none',
        top: menuY,
        left: mouseX,
        cursor: 'pointer',
      }}
    >
      {/* Unsafe Area - only defined for debug visualisation */}
      {/* <path
        pointerEvents="none"
        width="100%"
        height="100%"
        fill="rgba(187,39,38,0.05)"
        d={`M 0,0 L ${svgWidth},0 L ${svgWidth},${svgHeight} L 0,${svgHeight} z`}
      /> */}
      {/* Safe Area */}
      <path
        pointerEvents="auto"
        stroke="red"
        strokeWidth="0.4"
        // comment out stroke opacity and increase last value in fill to get visualization for debugging
        strokeOpacity={0}
        fill="rgba(114,140,89,0)"
        d={`M 0, ${mouseY - menuY}
            L ${svgWidth},${svgHeight}
            L ${svgWidth},0 z`}
      />
    </svg>
  );
}

export default SafeArea;
