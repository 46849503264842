import { defineMessages } from 'gatsby-plugin-react-intl';

export const messages = defineMessages({
  'prospectus.maas.painted.door.chatbot.title': {
    id: 'prospectus.maas.painted.door.chatbot.title',
    description: 'Heading for the chatbot.',
    defaultMessage: 'edX',
  },
  'prospectus.maas.painted.door.chatbot.survey.buttons.prev': {
    id: 'prospectus.maas.painted.door.chatbot.survey.buttons.prev',
    description: 'Previous button text.',
    defaultMessage: 'Prev',
  },
  'prospectus.maas.painted.door.chatbot.survey.buttons.next': {
    id: 'prospectus.maas.painted.door.chatbot.survey.buttons.next',
    description: 'Next button text.',
    defaultMessage: 'Next',
  },
  'prospectus.maas.painted.door.chatbot.survey.buttons.submit': {
    id: 'prospectus.maas.painted.door.chatbot.survey.buttons.submit',
    description: 'Submit button text.',
    defaultMessage: 'Submit',
  },
  'prospectus.maas.painted.door.chatbot.survey.intro': {
    id: 'prospectus.maas.painted.door.chatbot.survey.intro',
    description: 'Chatbot introduction message.',
    defaultMessage: 'Hi there! We\'re so glad you\'re here. Our live chat isn\'t available yet but we\'d appreciate it if you could take a minute to answer 3 questions and give us your feedback so we can make edX better for everyone',
  },
  'prospectus.maas.painted.door.chatbot.survey.privacy-policy': {
    id: 'prospectus.maas.painted.door.chatbot.survey.privacy-policy',
    description: 'Privacy policy for the chatbot survey.',
    defaultMessage: 'These responses will only be used internally for product improvement purposes. edX will use your information in accordance with edX\'s privacy policy. For more information regarding edX\'s privacy policy',
  },
  'prospectus.maas.painted.door.chatbot.survey.privacy-policy.link.text': {
    id: 'prospectus.maas.painted.door.chatbot.survey.privacy-policy.link.text',
    description: 'Text for the link to the Privacy policy for the chatbot survey.',
    defaultMessage: 'click here',
  },
  'prospectus.maas.painted.door.chatbot.survey.privacy-policy.link': {
    id: 'prospectus.maas.painted.door.chatbot.survey.privacy-policy.link',
    description: 'Link to the Privacy policy for the chatbot survey.',
    defaultMessage: '/edx-privacy-policy',
  },
  'prospectus.maas.painted.door.chatbot.survey.thank-you': {
    id: 'prospectus.maas.painted.door.chatbot.survey.thank-you',
    description: 'Survey completion thank you message.',
    defaultMessage: 'Thank you for completing our survey!',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one',
    description: 'First question of the survey.',
    defaultMessage: 'What brings you to our chat?',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.subtitle': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.subtitle',
    description: 'Subtitle for the first question of the survey.',
    defaultMessage: 'Select all that apply:',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.two': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.two',
    description: 'Second question of the survey.',
    defaultMessage: 'How could we make this chat experience better for you?',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.two.subtitle': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.two.subtitle',
    description: 'Subtitle for the second question of the survey.',
    defaultMessage: 'Select all that apply:',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.three': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.three',
    description: 'Third question of the survey.',
    defaultMessage: 'May we contact you by email with follow up questions?',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.three.specialInfo': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.three.specialInfo',
    description: 'Special information for the third question of the survey.',
    defaultMessage: ' (If you respond “yes” to this question, your name and email will be collected on the next page. Otherwise, press "no" to complete the survey)',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.four': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.four',
    description: 'Fourth question of the survey.',
    defaultMessage: 'Please provide the following:',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.options.one': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.options.one',
    description: 'First answer option to the first survey question.',
    defaultMessage: 'Just curious',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.options.two': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.options.two',
    description: 'Second answer option to the first survey question.',
    defaultMessage: 'I can\'t find what I\'m looking for',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.options.three': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.options.three',
    description: 'Third answer option to the first survey question.',
    defaultMessage: 'I need help being matched to the best learning option for me',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.options.four': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.options.four',
    description: 'Fourth answer option to the first survey question.',
    defaultMessage: 'I want to learn specific details about a particular learning option',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.options.five': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.options.five',
    description: 'Fifth answer option to the first survey question.',
    defaultMessage: 'I have a technical issue.',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.options.six': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.options.six',
    description: 'Sixth answer option to the first survey question.',
    defaultMessage: 'I\'m curious about the benefits of online learning.',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.two.options.one': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.two.options.one',
    description: 'First answer option to the second survey question.',
    defaultMessage: 'Offer quick access to FAQ',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.two.options.two': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.two.options.two',
    description: 'Second answer option to the second survey question.',
    defaultMessage: 'Provide information about specific products',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.two.options.three': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.two.options.three',
    description: 'Third answer option to the second survey question.',
    defaultMessage: 'Help me solve problems',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.two.options.four': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.two.options.four',
    description: 'Fourth answer option to the second survey question.',
    defaultMessage: 'I\'d like the chat to friendly/personalized to me',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.three.buttons.yes': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.three.buttons.yes',
    description: 'First answer option to the third survey question.',
    defaultMessage: 'Yes',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.three.buttons.no': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.three.buttons.no',
    description: 'Second answer option to the third survey question.',
    defaultMessage: 'No',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.one.input.label': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.one.input.label',
    description: 'Label for the custom input box in question one.',
    defaultMessage: 'Something else / please specify:',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.two.input.label': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.two.input.label',
    description: 'Label for the custom input field in question two.',
    defaultMessage: 'Something else / please specify:',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.four.input.name': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.four.input.name',
    description: 'Label for name input field.',
    defaultMessage: 'Name:',
  },
  'prospectus.maas.painted.door.chatbot.survey.questions.four.input.email': {
    id: 'prospectus.maas.painted.door.chatbot.survey.questions.four.input.email',
    description: 'Label for email input field.',
    defaultMessage: 'Email:',
  },
  'prospectus.maas.painted.door.chatbot.proactive-message.greeting': {
    id: 'prospectus.maas.painted.door.chatbot.proactive-message.greeting',
    description: 'Proactive message greeting text',
    defaultMessage: 'Hi,',
  },
  'prospectus.maas.painted.door.chatbot.proactive-message.message': {
    id: 'prospectus.maas.painted.door.chatbot.proactive-message.message',
    description: 'Proactive message',
    defaultMessage: 'Can I answer any questions for you?',
  },
  'prospectus.maas.painted.door.chatbot.survey.buttons.minimize': {
    id: 'prospectus.maas.painted.door.chatbot.survey.buttons.minimize',
    description: 'Minimize chatbot button.',
    defaultMessage: 'Minimize',
  },
  'prospectus.maas.painted.door.chatbot.survey.buttons.close': {
    id: 'prospectus.maas.painted.door.chatbot.survey.buttons.close',
    description: 'Close button text.',
    defaultMessage: 'Close',
  },
  'prospectus.maas.painted.door.chatbot.button.text': {
    id: 'prospectus.maas.painted.door.chatbot.button.text',
    description: 'Text for the chat button',
    defaultMessage: 'Chat',
  },
});

export const getQuestions = (intl) => [
  {
    title: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one']),
    subTitle: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.subtitle']),
    options: [
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.options.one']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.options.two']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.options.three']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.options.four']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.options.five']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.options.six']) },
    ],
    inputList: [
      {
        type: 'text',
        key: 'text',
        label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.one.input.label']),
      },
    ],
    buttonList: [
      {
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.prev']),
        nextPage: 0,
      },
      {
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.next']),
        nextPage: 2,
        shouldMoveForward: true,
      },
    ],
  },
  {
    title: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.two']),
    subTitle: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.two.subtitle']),
    options: [
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.two.options.one']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.two.options.two']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.two.options.three']) },
      { label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.two.options.four']) },
    ],
    inputList: [
      {
        type: 'text',
        key: 'text',
        label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.two.input.label']),
      },
    ],
    buttonList: [
      {
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.prev']),
        nextPage: 1,
      },
      {
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.next']),
        nextPage: 3,
        shouldMoveForward: true,
      },
    ],
  },
  {
    title: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.three']),
    specialInformation: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.three.specialInfo']),
    key: 'contactByEmail',
    buttonList: [
      {
        iskeyValueType: true,
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.three.buttons.yes']),
        value: true,
        nextPage: 4,
      },
      {
        iskeyValueType: true,
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.three.buttons.no']),
        value: false,
        nextPage: 5,
        shouldSendData: true,
      },
    ],
  },
  {
    title: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.four']),
    inputList: [
      {
        type: 'text',
        key: 'name',
        label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.four.input.name']),
      },
      {
        type: 'email',
        key: 'email',
        label: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.questions.four.input.email']),
      },
    ],
    buttonList: [
      {
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.prev']),
        nextPage: 3,
      },
      {
        name: intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.submit']),
        nextPage: 5,
        shouldSendData: true,
        shouldMoveForward: true,
      },
    ],
  },
];
