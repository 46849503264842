import { useLocation } from '@reach/router';
import { PRODUCT_MAP_2U, EXTERNAL_PRODUCT_SOURCE_SLUGS } from '@utils/discoveryConstants';
import {
  SEARCH, addSearchDeviceParam, addQueryParams, createSearchResultUrl,
} from '@utils/search';
import { getConditionalUrlParams } from '@utils/analytics';
import { shouldRedirect } from '@utils/externalRedirect';
import { CourseOrProgramType, ProductCardProps } from '@prospectus/common/ui/ProductCard/types';
import { BaseCardAnalytics, CardAnalyticsType } from '@prospectus/common/ui-strict/BaseCard/types';
import { createCodeFriendlyProduct, ProductType } from '@prospectus/common/types/productTypes';

const isEdxProgram = ({ cardType, is2UDegreeProgram }: { cardType: CourseOrProgramType, is2UDegreeProgram: boolean}) => cardType === 'program' && !is2UDegreeProgram;
const is2UCourse = (courseType) => !!Object.values(PRODUCT_MAP_2U).find(
  productLine => productLine.DISCOVERY_KEY === courseType,
);

const format2UAnalytics = (analytics: CardAnalyticsType, {
  productType,
  title,
}) => {
  const linkProps: CardAnalyticsType = {
    ...analytics,
    product_category: '2u',
    label: title,
    product_line: createCodeFriendlyProduct(productType),
  };

  if (analytics?.page_type === SEARCH) {
    linkProps.product_category = `2U Search: ${productType}`;
    linkProps.results_level = analytics?.results_level;
  }

  if (analytics?.position) {
    linkProps.position = analytics?.position;
  }

  return {
    eventName: 'edx.bi.2u-product-card.clicked',
    linkProps,
  };
};

const formatEdxProgramAnalytics = (analytics: CardAnalyticsType, {
  title,
  uuid,
}) => {
  const linkProps = {
    ...analytics,
    product_category: 'program',
    label: `${title} [${uuid}]`,
  };
  return {
    eventName: 'edx.bi.user.discovery.card.click',
    linkProps,
  };
};

const formatEdxCourseAnalytics = (analytics: CardAnalyticsType, {
  uuid,
}) => {
  const linkProps = {
    ...analytics,
    product_category: 'course',
    uuid,
  };

  return {
    eventName: 'edx.bi.user.discovery.card.click',
    linkProps,
  };
};

const getAnalytics = ({
  productType,
  title,
  uuid,
  analytics,
  cardType,
  courseType,
  is2UDegreeProgram,
}: ProductCardProps): BaseCardAnalytics => {
  if (is2UCourse(courseType) || is2UDegreeProgram) {
    return format2UAnalytics(analytics, {
      productType,
      title,
    });
  }

  if (isEdxProgram({ cardType, is2UDegreeProgram })) {
    return formatEdxProgramAnalytics(analytics, {
      title,
      uuid,
    });
  }

  return formatEdxCourseAnalytics(analytics, {
    uuid,
  });
};

const formatUrl = ({
  path,
  hasDeviceParam = false,
  urlParams,
  is2UDegreeProgram,
}: ProductCardProps) => {
  let finalUrl: string = path;

  // if the card is a first or second level search result
  if (urlParams?.index) {
    finalUrl = createSearchResultUrl(urlParams?.index, path, urlParams?.queryID, urlParams?.position);
  }

  if (hasDeviceParam && urlParams?.webview) {
    finalUrl = addSearchDeviceParam(finalUrl, urlParams?.webview);
  }

  if (urlParams?.eaid) {
    finalUrl = addQueryParams(finalUrl, { eaid: urlParams?.eaid });
  }

  const remainingUrlParams = getConditionalUrlParams(urlParams, is2UDegreeProgram);

  finalUrl = addQueryParams(finalUrl, remainingUrlParams);

  return finalUrl;
};

const formatExternalUrl = ({
  is2UDegreeProgram,
  externalUrl,
  urlParams,
}: ProductCardProps) => {
  const remainingUrlParams = getConditionalUrlParams(urlParams, is2UDegreeProgram);
  if (is2UDegreeProgram) {
    // for degree programs, this value is different for external urls versus internal
    remainingUrlParams.version = 'edu';
  }
  return addQueryParams(externalUrl, remainingUrlParams);
};

const addLobExperimentUrl = (
  search: string,
  path: string,
) => {
  let finalUrl = path;
  const currentParams = new URLSearchParams(search);
  const vanguardsClick = currentParams.get('vanguards_click');
  if (vanguardsClick) {
    finalUrl = addQueryParams(path, { vanguards_click: vanguardsClick });
  }
  return finalUrl;
};

const getFinalUrl = (props: ProductCardProps) => {
  const { search } = useLocation();
  const {
    productSource, externalUrl, lobExperimentVariation, uuid,
  } = props;
  if (EXTERNAL_PRODUCT_SOURCE_SLUGS.includes(productSource) && externalUrl) {
    return formatExternalUrl(props);
  }
  if (externalUrl && shouldRedirect(uuid)) {
    return formatExternalUrl(props);
  }
  let finalUrl = formatUrl(props);
  if (lobExperimentVariation) {
    finalUrl = addLobExperimentUrl(search, finalUrl);
  }
  return finalUrl;
};

const getVariant = (productType: ProductType) => (
  ['Boot Camp', 'Executive Education', 'Course'].includes(productType) ? 'light' : 'dark'
);

export {
  getAnalytics,
  isEdxProgram,
  formatUrl,
  formatExternalUrl,
  getVariant,
  addLobExperimentUrl,
  getFinalUrl,
};
