import { useEffect, useState, useRef } from 'react';

const useAboveFold = () => {
  const [isAboveFold, setIsAboveFold] = useState(null);
  const linkRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, thisObserver) => {
      if (entries[0].intersectionRatio <= 0) {
        setIsAboveFold(false);
      } else {
        setIsAboveFold(true);
      }
      thisObserver.unobserve(entries[0].target); // stop observing so that the status doesn't change as the user scrolls
    });
    observer.observe(linkRef.current);
  }, []);
  return [isAboveFold, linkRef];
};

export default useAboveFold;
