import './styles.scss';
import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';

import { BasicLinkPropType } from '@prospectus/common/types/commonPropTypes';
import ExpandableList from '@prospectus/common/ui-strict/ExpandableList';
import { TOPIC } from '@prospectus/common/ui-strict/MegaNav/data/constants';
import { useMegaNav } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import LinkWithVisibilityTracking from '@prospectus/common/ui-strict/LinkWithVisibilityTracking';

import isUserLoggedIn from '@utils/user';

const messages = defineMessages({
  'prospectus.mega-nav.topic-list.related': {
    id: 'prospectus.resources.topic-list.related',
    description: 'Label for related course topics',
    defaultMessage: 'Related topics',
  },
  'prospectus.mega-nav.topic-list.trending': {
    id: 'prospectus.resources.topic-list.trending',
    description: 'Label for trending course topics',
    defaultMessage: 'Trending topics',
  },
  'prospectus.mega-nav.topic-list.show-more-related': {
    id: 'prospectus.resources.topic-list.show-more-related',
    description: 'Button to show more related course topics',
    defaultMessage: 'Show more related topics',
  },
  'prospectus.mega-nav.topic-list.show-more-trending': {
    id: 'prospectus.resources.topic-list.show-more-trending',
    description: 'Button to show more trending course topics',
    defaultMessage: 'Show more trending topics',
  },
  'prospectus.mega-nav.topic-list.show-less-related': {
    id: 'prospectus.resources.topic-list.show-less-related',
    description: 'Button to show less related course topics',
    defaultMessage: 'Show less related topics',
  },
  'prospectus.mega-nav.topic-list.show-less-trending': {
    id: 'prospectus.resources.topic-list.show-less-trending',
    description: 'Button to show less trending course topics',
    defaultMessage: 'Show less trending topics',
  },
});

type TopicListPropType = {
  topics: BasicLinkPropType[];
};

function TopicList({ topics }: TopicListPropType) {
  const intl = useIntl();
  const { activeCategory, categories } = useMegaNav();
  const isTopicCategory = categories?.[activeCategory]?.type === TOPIC;
  const stringType = isTopicCategory ? 'related' : 'trending';

  return (
    <div className={classNames(`${stringType}-topics`, { 'topic-list-top pb-4': isTopicCategory })}>
      <h4 className="mb-3">
        {intl.formatMessage(messages[`prospectus.mega-nav.topic-list.${stringType}`])}
      </h4>
      <ExpandableList
        className="topic-list"
        // extra height to account for 8px gap
        extraHeight={8}
        moreText={intl.formatMessage(messages[`prospectus.mega-nav.topic-list.show-more-${stringType}`])}
        lessText={intl.formatMessage(messages[`prospectus.mega-nav.topic-list.show-less-${stringType}`])}
      >
        {topics.map(({ url, label }, index) => (
          <LinkWithVisibilityTracking
            key={`mega-nav-topic-${uuidv4()}`}
            className={classNames(
              'paragon__chip paragon__chip-light m-0',
              { 'last-chip': index === topics.length - 1 },
            )}
            url={url}
            eventName="edx.bi.mega-nav.topic.clicked"
            additionalLinkProps={{
              category: activeCategory,
              loggedIn: isUserLoggedIn(),
              title: label,
              navigationLink: true,
              flyoutTitle: label,
            }}
          >
            {label}
          </LinkWithVisibilityTracking>
        ))}
      </ExpandableList>
    </div>
  );
}

export default TopicList;
