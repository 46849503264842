import { useMemo } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

export const GEOTARGETING_KEY = 'geotargeting';

function useGeotargeting() {
  const { search } = useLocation();

  return useMemo(() => {
    const currentParams = new URLSearchParams(search);
    const geotargetingEnabled = currentParams.get(GEOTARGETING_KEY) !== 'false';
    return { geotargetingEnabled };
  }, [search]);
}

export default useGeotargeting;
