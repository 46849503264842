import { BaseCardProps, CardAnalyticsType } from '@prospectus/common/ui-strict/BaseCard/types';
import { CardUrlParamsType } from '@prospectus/common/ui/ProductCard/types';
import { ProductType } from '@prospectus/common/types/productTypes';

export type CourseOrProgramType = 'course' | 'program' | 'program_course';

export interface OwnerType {
    key?: string;
    logoImageUrl?: string;
    name?: string;
  }

export interface CondensedCardType extends BaseCardProps{
    analytics?: CardAnalyticsType;
    courseType?: string;
    courseLength?: number;
    cardType?: CourseOrProgramType;
    externalUrl?: string;
    is2UDegreeProgram?: boolean;
    productSource?: string;
    path: string;
    productType: ProductType;
    hasDeviceParam?: boolean;
    tabIndex?: string;
    owners?: OwnerType[];
    lobExperimentVariation?: string;
    schoolName: string;
    urlParams?: CardUrlParamsType;
}

export const CondensedCardDefaultProps = {
  factoid: '',
  tabIndex: undefined,
  hasDeviceParam: false,
  position: undefined,
  quickFacts: [],
  isLoading: false,
  urlParams: undefined,
  eaid: undefined,
  source: undefined,
  lobExperimentVariation: '',
  headerImage: undefined,
  productSource: undefined,
  shouldfirePreQuerySegmentEvent: false,
};
