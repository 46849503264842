import { useStaticQuery, graphql } from 'gatsby';

import { GOAL, TOPIC } from '@prospectus/common/ui-strict/MegaNav/data/constants';
import { CategoriesType } from '../data/types';
import { sortMegaNavCategories } from '../utils/sortMegaNavCategories';

const useMegaNavQuery = (languageCode = 'en') => {
  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulNavigationMenu {
        edges {
          node {
            language
            lobFlyouts {
              menuLabel
              categoryId
              hero {
                ...HeroFragment
              }
              productSummaries {
                title
                text {
                  raw
                }
              }
              navigationLinkLists {
                heading
                products {
                  ... on Course {
                    id
                    courseUuid: uuid
                    title
                    courseType
                    prospectusPath
                    owners {
                      key
                      name
                      logoImageUrl
                    }
                  }
                  ... on Program {
                    id
                    programUuid: uuid
                    title
                    type
                    marketingPath
                    authoringOrganizations {
                      key
                      name
                      logoImageUrl
                    }
                  }
                }
              }
              topicPills {
                ...LinkFragment
              }
            }
            topicFlyouts {
              menuLabel
              categoryId
              hero {
                ...HeroFragment
              }
              topicPills {
                ...LinkFragment
              }
              navigationLinkLists {
                heading
                products {
                  ... on Course {
                    id
                    courseUuid: uuid
                    title
                    courseType
                    prospectusPath
                    owners {
                      key
                      name
                      logoImageUrl
                    }
                  }
                  ... on Program {
                    id
                    programUuid: uuid
                    title
                    type
                    marketingPath
                    authoringOrganizations {
                      key
                      name
                      logoImageUrl
                    }
                    is2UDegreeProgram
                  }
                }
                cta {
                  ...LinkFragment
                }
              }
              topicLinkLists {
                ... on ContentfulCarouselModule {
                  heading
                  headingLink
                  links {
                    ...LinkFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const categories = {} as CategoriesType;
  const menuItems = [];

  // add cardType (course or program) to card
  const navigationMenu = contentfulData.allContentfulNavigationMenu.edges.find(
    (edge) => edge.node.language === languageCode,
  )?.node;

  // for the condensed card analytics
  const processProductLists = productLists => {
    if (!productLists) { return productLists; }
    return productLists.map((productList) => {
      const newProductList = productList;
      newProductList.products = productList.products.map((product) => {
        if (product.courseUuid) {
          return { ...product, cardType: 'course' };
        }
        return { ...product, cardType: 'program' };
      });
      return newProductList;
    });
  };

  const lobMenuItems = [];
  navigationMenu?.lobFlyouts?.forEach((item) => {
    const processedItem = item;
    processedItem.navigationLinkLists = processProductLists(item.navigationLinkLists);
    categories[processedItem.categoryId] = { ...processedItem, type: 'goal' };
    lobMenuItems.push({ category: processedItem.categoryId, label: processedItem.menuLabel });
  });

  menuItems.push({
    section: GOAL,
    label: languageCode === 'en' ? 'By goal' : 'Por gol', // @todo add conditional with translation for Spanish
    items: lobMenuItems,
  });

  const topicMenuItems = [];
  navigationMenu?.topicFlyouts?.forEach((item) => {
    const processedItem = item;
    processedItem.navigationLinkLists = processProductLists(item.navigationLinkLists);
    categories[processedItem.categoryId] = { ...processedItem, type: TOPIC };
    topicMenuItems.push({ category: processedItem.categoryId, label: processedItem.menuLabel });
  });
  menuItems.push({
    section: TOPIC,
    label: languageCode === 'en' ? 'By topic' : 'Por tema', // @todo add conditional with translation for Spanish
    items: topicMenuItems,
  });

  const sortedCategories = sortMegaNavCategories(categories);

  return { categories: sortedCategories, menuItems };
};

export default useMegaNavQuery;
