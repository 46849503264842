import React from 'react';

import { createCodeFriendlyProduct } from '@prospectus/common/types/productTypes';
import { CardAnalyticsType } from '@prospectus/common/ui-strict/BaseCard/types';
import useProductType from '@prospectus/common/hooks/useProductType';
import CondensedCard from '..';
import CardWrapperProps, { CardUrlParamsType } from './types';

export default function Wrapper({
  product,
  urlParams,
  isLoading,
  hasDeviceParam,
  webview,
  analytics,
}: CardWrapperProps) {
  const productType = useProductType(product?.courseType, product?.type);

  // courseUuid & programUuid might be coming from useMegaNavQuery
  const uuid = product?.uuid || product?.courseUuid || product?.programUuid;

  // Because the models for 2U products and Legacy edX products look different,
  // the variables below follow a pattern where the first value represents
  // the legacy edx field that should be passed to the card and the following
  // value represents the field for the 2U products
  const path = product?.prospectusPath || product?.marketingPath;
  const headerImage = product?.cardImageUrl || product?.image?.src;

  // School name and school logo follow the same pattern with an additional
  // optional field in the database that can override the name or logo that's
  // associated with a certain instituion. For example the MIT Sloan business
  // school may want to override the default MIT logo with their own.
  const schoolName = product?.organizationShortCodeOverride
    || product?.owners?.[0]?.name
    || product?.authoringOrganizations?.[0]?.name
    || product?.partner;
  const schoolLogo = product?.organizationLogoOverrideUrl
    || product?.logoFilename
    || product?.authoringOrganizations?.[0]?.logoImageUrl
    || product?.owners?.[0]?.logoImageUrl;

  const finalUrlParams: CardUrlParamsType = {
    ...urlParams,
    webview,
    campaign: product?.title,
    queryID: product?.queryID,
    source: product.productSource?.slug || 'edx',
    product_category: createCodeFriendlyProduct(productType),
    position: product?.position,
  };
  // Remove undefied fields
  Object.keys(finalUrlParams).forEach(
    (key) => finalUrlParams[key] === undefined && delete finalUrlParams[key],
  );

  const productAnalytics: CardAnalyticsType = {
    ...analytics,
    label:
      product?.activeCourseRun?.key || `${product?.title} [${uuid}]`,
    queryID: product?.queryID,
    // using slug vs productSource because of differences in course-discovery versus algolia
    source: product.productSource?.slug || product.productSource || 'edx',
    position: product?.position,
    product_category: createCodeFriendlyProduct(productType),
  };

  // Remove undefined fields
  Object.keys(productAnalytics).forEach(
    (key) => productAnalytics[key] === undefined && delete productAnalytics[key],
  );

  if (!productType) {
    return null;
  }

  return (
    <CondensedCard
      owners={product.owners}
      isLoading={isLoading}
      uuid={uuid}
      cardType={product.cardType}
      courseType={product.courseType}
      title={product.title}
      productSource={product.productSource?.slug}
      productType={productType}
      path={path}
      schoolLogo={schoolLogo}
      schoolName={schoolName}
      headerImage={headerImage}
      hasDeviceParam={hasDeviceParam}
      is2UDegreeProgram={product.is2UDegreeProgram}
      urlParams={finalUrlParams}
      analytics={productAnalytics}
          // Footer
      externalUrl={product.additionalMetadata?.externalUrl || product.degree?.additionalMetadata?.externalUrl}
      courseLength={product.courses?.length}
      quickFacts={product.degree?.quickFacts}
      url=""
      variant="light"
      productTypeCopy=""
      footer={undefined}
    />
  );
}
