import Cookies from 'js-cookie';

export const EdxUserInfo = () => {
  const cookieName = `${process.env.GATSBY_COOKIE_ENV}-edx-user-info`;
  const rawCookie = Cookies.get(cookieName);
  if (rawCookie) {
    try {
      // Fix any encoded commas/quotes that have been double escaped before parsing.
      return JSON.parse(rawCookie.replace(/\\054/g, ',').replace(/\\"/g, '"'));
    } catch (e) {
      return {};
    }
  }

  // Return default user data if in dev and forcing logged in state
  if (process.env.GATSBY_ENVIRONMENT === 'development' && process.env.GATSBY_USER_FORCE_LOGGED_IN === 'true') {
    return {
      username: 'Clem N Tyne',
      email: 'clem.tyne@test.com',
      header_urls: {
        account_settings: 'url/to/account_settings',
        learner_profile: 'url/to/learner_profile',
        logout: 'url/to/logout',
        resume_block: 'url/to/resume',
      },
      user_image_urls: {
        full: 'https://source.unsplash.com/500x500/?dog,portrait',
        large: 'https://source.unsplash.com/120x120/?dog,portrait',
        medium: 'https://source.unsplash.com/50x50/?dog,portrait',
        small: 'https://source.unsplash.com/30x30/?dog,portrait',
      },
    };
  }

  return {};
};

export const getLanguagePreference = () => {
  const cookieName = `${process.env.GATSBY_COOKIE_ENV}-edx-language-preference`;
  const langPreference = Cookies.get(cookieName);

  if (langPreference) {
    return langPreference.slice(0, 2);
  }

  return false;
};

export const setLanguagePreferenceCookie = (languageCode, callback = () => {}) => {
  const cookieName = `${process.env.GATSBY_COOKIE_ENV}-edx-language-preference`;
  Cookies.set(cookieName, languageCode, {
    domain: 'edx.org',
    expires: 1,
    path: '/',
  });

  callback();
};

export const getGeoLocaleAlpha2 = () => Cookies.get(`${process.env.GATSBY_COOKIE_ENV}-edx-cf-loc`);

export const setCurrencyInfoCookie = (currencyInfo, countryCode) => {
  const cookieName = 'edx-price-l10n';
  const domainInfo = process.env.GATSBY_COOKIE_ENV !== 'localhost'
    ? { domain: 'edx.org', expires: 1, path: '/' }
    : {};
  const cookieValue = JSON.stringify({
    ...currencyInfo,
    countryCode,
  });
  Cookies.set(cookieName, cookieValue, domainInfo);
};

export const removeCurrencyInfoCookie = () => {
  if (Cookies && Cookies.get('edx-price-l10n')) {
    Cookies.remove('edx-price-l10n');
  }
};
