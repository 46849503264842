import './styles.scss';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import escape from 'validator/lib/escape';
import unescape from 'validator/lib/unescape';

import { Form, Input, Button } from '@edx/paragon';
import { useIntl } from 'gatsby-plugin-react-intl';
import { getQuestions, messages } from './constants';

function Survey(props) {
  const surveyRef = useRef();
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const [surveyPageNumber, setSurveyPageNumber] = useState(0);
  const intl = useIntl();
  const questions = getQuestions(intl);

  const handleSurveyOptionSelection = ({ questionIndex, event }) => {
    const getCheckedOption = () => (
      Array.from(new Set([
        ...(surveyAnswers[questionIndex]?.selectedOptions || []),
        event.target.value,
      ]))
    );

    const getUnCheckedOption = () => (
      (surveyAnswers[questionIndex]?.selectedOptions || []).filter(option => option !== event.target.value)
    );

    const optionAnswer = {
      selectedOptions: event.target.checked ? getCheckedOption(questionIndex) : getUnCheckedOption(questionIndex),
    };

    const questionAnswer = {
      ...surveyAnswers[questionIndex],
      ...optionAnswer,
      question: questions[questionIndex].title,
    };

    const updatedSurveyAnswer = {
      ...surveyAnswers,
      [questionIndex]: questionAnswer,
    };

    setSurveyAnswers(updatedSurveyAnswer);
  };

  const handleSurveyTextEntered = ({ questionIndex, key, value }) => {
    const textValue = (value || '').trim().length === 0 ? null : escape(value);

    const updatedSurveyAnswer = {
      ...surveyAnswers,
      [questionIndex]: {
        ...surveyAnswers[questionIndex],
        question: questions[questionIndex].title,
        [key]: textValue,
      },
    };

    setSurveyAnswers(updatedSurveyAnswer);
  };

  const sendSubmission = (answer) => {
    const submission = {
      selectedOptions1: answer[0]?.selectedOptions,
      text1: answer[0]?.text,
      selectedOptions2: answer[1]?.selectedOptions,
      text2: answer[1]?.text,
      contactByEmail: answer[2]?.contactByEmail,
      name: answer[3]?.name,
      email: answer[3]?.email,
      buttonName: answer?.buttonName,
    };
    props.handleSubmitSurveyAnswer(submission);
  };

  const handleSubmitSurveyAnswer = ({
    buttonName,
  }) => {
    const allAnswers = {
      ...surveyAnswers,
      buttonName,
    };

    sendSubmission(allAnswers);
  };

  const handleSubmitSurveyKeyValueAnswer = ({
    questionIndex,
    key,
    value,
    buttonName,
    shouldSendData,
  }) => {
    const updatedSurveyAnswer = {
      ...surveyAnswers,
      [questionIndex]: {
        question: questions[questionIndex].title,
        [key]: value,
      },
    };

    setSurveyAnswers(updatedSurveyAnswer);

    if (shouldSendData) {
      const allAnswers = {
        ...updatedSurveyAnswer,
        buttonName,
      };

      sendSubmission(allAnswers);
    }
  };

  const handleButtonClick = ({ button, questionIndex }) => {
    const question = questions[questionIndex];

    if (button.iskeyValueType) {
      handleSubmitSurveyKeyValueAnswer({
        questionIndex,
        key: question.key,
        value: button.value,
        buttonName: button.name,
        shouldSendData: button.shouldSendData,
      });
    } else if (button.shouldSendData) {
      handleSubmitSurveyAnswer({
        nextSurveyPageNumber: button.nextPage,
        buttonName: button.name,
      });
    }

    surveyRef.current.scrollTo?.(0, 0);
    setSurveyPageNumber(button.nextPage);
  };

  const isOptionOrTextProvided = (questionIndex) => (
    surveyAnswers[questionIndex]?.selectedOptions?.length > 0 || surveyAnswers[questionIndex]?.text
  );

  const isNameAndEmailProvided = (questionIndex) => (
    surveyAnswers[questionIndex]?.name
    && surveyAnswers[questionIndex]?.email
    && isEmail(surveyAnswers[questionIndex]?.email)
  );

  const questionIndex = surveyPageNumber - 1;
  const question = questions[questionIndex];

  return (
    <div ref={surveyRef} className="survey">
      <div className="survey__container">
        <Form onSubmit={event => { event.preventDefault(); }}>
          {surveyPageNumber === 0 && (
            <section>
              <fieldset>
                <legend className="survey__container--legend">
                  <div>
                    <div>
                      {intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.intro'])}
                    </div>
                    <div className="survey__privacy-policy">
                      {intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.privacy-policy'])}
                      {', '}
                      <a
                        href={intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.privacy-policy.link'])}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="survey__privacy-policy--link"
                      >
                        {intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.privacy-policy.link.text'])}
                      </a>.
                    </div>
                  </div>
                </legend>
              </fieldset>
            </section>
          )}
          {surveyPageNumber > 0 && questionIndex < questions.length && (
            <section className="survey__question">
              <fieldset>
                <legend className="survey__container--legend">
                  <div>
                    {surveyPageNumber}. {question.title}{question.subTitle}
                    <span className="survey__question--special-information">{question.specialInformation}</span>
                  </div>
                </legend>
                {question.options?.length > 0 && (
                <ul className="survey__question--checkbox-list-container">
                  {question.options?.map((option) => (
                    <li
                      key={option.label}
                      value={option.label}
                      className="list-group-item border-0"
                    >
                      <label
                        htmlFor={option.label}
                      >
                        <div className="survey__question--checkbox-container">
                          <div>
                            <Input
                              id={option.label}
                              name={option.label}
                              type="checkbox"
                              value={option.label}
                              checked={Boolean(surveyAnswers[questionIndex]?.selectedOptions?.find(
                                selectedOption => selectedOption === option.label,
                              ))}
                              className="mr-2 mt-1"
                              onChange={(event) => handleSurveyOptionSelection({
                                questionIndex,
                                event,
                              })}
                            />
                          </div>
                          <div className="mr-2 refinement-option-label">
                            {option.label}
                          </div>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
                )}
              </fieldset>
              {question.inputList?.length > 0 && (
                <div className="survey__question--input-list-container">
                  {question.inputList?.map((input) => (
                    <label
                      key={input.key}
                      htmlFor={input.key}
                      className="survey__question--input-label"
                    >
                      <div>
                        {input.label}
                      </div>
                      <Input
                        data-testid="custom-input"
                        id={input.key}
                        name={input.key}
                        type={input.type}
                        className="mr-2 mt-1"
                        maxLength="100"
                        value={unescape(surveyAnswers[questionIndex]?.[input.key] || '')}
                        onKeyDown={event => { if (event.key === 'Enter') { event.preventDefault(); } }}
                        onChange={(event) => handleSurveyTextEntered({
                          questionIndex,
                          key: input.key,
                          value: event.target.value,
                        })}
                      />
                    </label>
                  ))}
                </div>
              )}
            </section>
          )}
          {questionIndex < questions.length && (
          <section className="survey__question--button-list-container">
            {surveyPageNumber === 0 && (
              <Button
                variant="custom"
                aria-label={intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.next'])}
                type="submit"
                className="survey__question--button"
                onClick={() => setSurveyPageNumber(1)}
              >
                {intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.next'])}
              </Button>
            )}
            {surveyPageNumber > 0 && question.buttonList?.map((button, buttonIndex) => (
              <Button
                key={button.name}
                variant="custom"
                aria-label={button.name}
                type="submit"
                className="survey__question--button"
                style={{ marginRight: buttonIndex < question.buttonList.length - 1 ? '1rem' : '' }}
                disabled={!button.iskeyValueType && button.shouldMoveForward
                && !(isOptionOrTextProvided(questionIndex) || isNameAndEmailProvided(questionIndex))}
                onClick={() => handleButtonClick({
                  questionIndex,
                  button,
                })}
              >
                {button.name}
              </Button>
            ))}
          </section>
          )}
        </Form>
        {surveyPageNumber > questions.length && (
        <div>
          {intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.thank-you'])}
        </div>
        )}
      </div>
    </div>
  );
}

Survey.propTypes = {
  handleSubmitSurveyAnswer: PropTypes.func.isRequired,
};

export default Survey;
