import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FocusTrapReact from 'focus-trap-react';

import { useArrowKeyNavigation } from '@edx/paragon';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';

import './styles.scss';

function FloatingActionPopup({
  style,
  className,
  children,
  isNormalSize,
  isMounted,
}) {
  const isMobile = useBreakpointMatch('medium');
  const parentRef = useArrowKeyNavigation({
    selectors: 'a:not(:disabled),button:not(:disabled),input:not(:disabled)',
    ignoredKeys: ['ArrowRight', 'ArrowLeft', 'Enter'],
  });

  const handleMouseOver = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleMouseOut = () => {
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    parentRef.current?.addEventListener('mouseover', handleMouseOver);
    parentRef.current?.addEventListener('mouseout', handleMouseOut);

    return () => {
      handleMouseOut();
      parentRef.current?.removeEventListener('mouseover', handleMouseOver);
      parentRef.current?.removeEventListener('mouseout', handleMouseOut);
    };
  }, [parentRef.current]);

  return (
    <FocusTrapReact focusTrapOptions={{
      clickOutsideDeactivates: true,
    }}
    >
      <div
        ref={parentRef}
        tabIndex={-1}
        role="dialog"
        aria-modal
        data-testid="floating-action-popup"
        className={cx(
          {
            'floating-popup': !isMobile,
            'floating-popup--mobile': isMobile,
            'floating-popup--expand-size': isMounted && !isNormalSize && !isMobile,
            'floating-popup--return-to-normal-size': isMounted && isNormalSize && !isMobile,
          },
          className,
        )}
        style={style}
      >
        {children}
      </div>
    </FocusTrapReact>
  );
}

FloatingActionPopup.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape(),
  isMounted: PropTypes.bool,
  className: PropTypes.string,
  isNormalSize: PropTypes.bool,
};

FloatingActionPopup.defaultProps = {
  style: {},
  className: '',
  isNormalSize: true,
  isMounted: false,
};

export default FloatingActionPopup;
