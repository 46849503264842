import React, {
  useContext, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import track, { useTracking } from 'react-tracking';
import { useLocation } from '@gatsbyjs/reach-router';
import { injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import { submitEvent } from '@utils/analytics';
import { AutoCompleteContext, NavContext } from '@utils/context';
import useAutocomplete from '@prospectus/common/hooks/useAutocomplete';
import useAlgoliaProductIndex from '@prospectus/common/hooks/useAlgoliaProductIndex';
import AutoComplete from '@prospectus/search-page/components/AutoComplete';
import SearchBox from './SearchBox';

function SearchBar({
  intl,
  forceSearchIsActive,
  placeholder,
  idSuffix,
  location,
  tracking,
}) {
  const searchInputRef = React.createRef();

  // need to use useRef here instead of createRef in order to make the usePopper hook in AutoComplete work correctly
  const autocompleteRef = useRef();

  const context = useContext(NavContext);
  const autocompleteState = useAutocomplete();
  const { preQueryOpen, setPreQueryOpen, autocompleteOpen } = autocompleteState;
  const defaultLocation = useLocation();
  const { getTrackingData } = useTracking();
  const trackData = getTrackingData();
  const indexName = useAlgoliaProductIndex(intl);

  const handleOnFocus = () => {
    if (forceSearchIsActive) {
      return;
    }
    setPreQueryOpen(true);
    context.setSearchIsActive(true);
  };

  const handleOnBlur = (e) => {
    if (forceSearchIsActive) {
      return;
    }
    const isFocusInChild = e && e.currentTarget.contains(e.relatedTarget);
    const isFocusInAutocomplete = e && autocompleteRef.current.contains(e.relatedTarget);

    // do not trigger if switching focus to a child or autocomplete
    if (e && !isFocusInChild && !isFocusInAutocomplete) {
      context.setSearchIsActive(false);
    }
  };

  const autoCompleteContextValues = useMemo(() => (
    { ...autocompleteState, autocompleteOpen, preQueryOpen }
  ), [autocompleteState, autocompleteOpen, preQueryOpen]);

  const sendAnalytics = (state) => {
    const componentProps = {
      category: 'search',
      component: 'search-bar',
      label: state.query || '',
      page: trackData.page,
      url: location.pathname || defaultLocation.href,
    };

    submitEvent(tracking, componentProps, 'edx.bi.user.search.header-search-bar.submitted');
  };

  return (
    <AutoCompleteContext.Provider
      value={autoCompleteContextValues}
    >
      <AutoComplete
        indexName={indexName}
        id={`nav-search-${idSuffix}`}
        searchBoxComponent={SearchBox}
        additionalSearchBoxProps={{
          placeholder,
          searchInputRef,
        }}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        autocompleteRef={autocompleteRef}
        className="nav-search-box font-weight-normal"
        sendAnalytics={sendAnalytics}
      />
    </AutoCompleteContext.Provider>
  );
}

SearchBar.propTypes = {
  intl: intlShape.isRequired,
  tracking: PropTypes.shape({
    trackEvent: PropTypes.func.isRequired,
    getTrackingData: PropTypes.func.isRequired,
  }).isRequired,
  forceSearchIsActive: PropTypes.bool, // shows visible submit button
  placeholder: PropTypes.string.isRequired,
  idSuffix: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

SearchBar.defaultProps = {
  location: {},
  forceSearchIsActive: false,
};

export default track({
  components: 'navbar-search-box',
})(injectIntl(SearchBar));
