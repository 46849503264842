// Code Owner: #project-xpert-platform
import React from 'react';
import isValidAudience from '@prospectus/common/ui/xpert/shared/utils';

let Chatbot;

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  Chatbot = require('@2uinc/frontend-component-xpert-chatbot').Chatbot;
}

function XpertChatbot() {
  if (Chatbot && isValidAudience()) {
    return <Chatbot xpertKey="edx" />;
  }
  return null;
}

export default XpertChatbot;
