import React from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  defineMessages,
} from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import MenuAccordion from './MenuAccordion';

function MobileAccountLinks({
  intl,
  profileLink,
  settingsLink,
  logoutLink,
  collapsibleRef,
  onCollapsibleOpen,
  collapsibleTriggerRef,
}) {
  const messages = defineMessages({
    'prospectus.header.site_header.logged_in_user_menu.account_accordion_label': {
      id: 'prospectus.header.site_header.logged_in_user_menu.account_accordion_label',
      defaultMessage: 'Account',
      description: 'Link text for the Account accordion label in the menu for logged-in users',
    },
    'prospectus.header.site_header.logged_in_user_menu.career_link_text': {
      id: 'prospectus.header.site_header.logged_in_user_menu.career_link_text',
      defaultMessage: 'Career',
      description: 'Link text for the Career link in the menu for logged-in users',
    },
    'prospectus.header.site_header.logged_in_user_menu.profile_link_text': {
      id: 'prospectus.header.site_header.logged_in_user_menu.profile_link_text',
      defaultMessage: 'Profile',
      description: 'Link text for the Profile link in the menu for logged-in users',
    },
    'prospectus.header.site_header.logged_in_user_menu.settings_link_text': {
      id: 'prospectus.header.site_header.logged_in_user_menu.settings_link_text',
      defaultMessage: 'Settings',
      description: 'Link text for the Settings link in the menu for logged-in users',
    },
    'prospectus.header.site_header.logged_in_user_menu.sign_out_link_text': {
      id: 'prospectus.header.site_header.logged_in_user_menu.sign_out_link_text',
      defaultMessage: 'Sign Out',
      description: 'Link text for the Sign-out link in the menu for logged-in users',
    },
  });
  return (
    <MenuAccordion
      className="last-focus"
      title={intl.formatMessage(messages['prospectus.header.site_header.logged_in_user_menu.account_accordion_label'])}
      id="account-links"
      links={[
        {
          href: 'https://careers.edx.org/',
          label: intl.formatMessage(messages['prospectus.header.site_header.logged_in_user_menu.career_link_text']),
          analytics: {
            eventName: 'edx.bi.link.site-nav.logged_in_user_menu.career_link.clicked',
          },
          inProspectus: false,
          newBadge: true,
        },
        {
          href: profileLink,
          label: intl.formatMessage(messages['prospectus.header.site_header.logged_in_user_menu.profile_link_text']),
          analytics: {
            eventName: 'edx.bi.link.site-nav.logged_in_user_menu.profile_link.clicked',
          },
          inProspectus: false,
          newBadge: false,
        },
        {
          href: settingsLink,
          label: intl.formatMessage(messages['prospectus.header.site_header.logged_in_user_menu.settings_link_text']),
          analytics: {
            eventName: 'edx.bi.link.site-nav.logged_in_user_menu.settings_link.clicked',
          },
          inProspectus: false,
          newBadge: false,
        },
        {
          href: logoutLink,
          label: intl.formatMessage(messages['prospectus.header.site_header.logged_in_user_menu.sign_out_link_text']),
          analytics: {
            eventName: 'edx.bi.link.site-nav.logged_in_user_menu.sign_out_link.clicked',
          },
          inProspectus: false,
          newBadge: false,
        },
      ]}
      ref={collapsibleRef}
      onCollapsibleOpen={onCollapsibleOpen}
      collapsibleTriggerRef={collapsibleTriggerRef}
    />
  );
}

MobileAccountLinks.propTypes = {
  intl: intlShape.isRequired,
  profileLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
  settingsLink: PropTypes.string.isRequired,
  onCollapsibleOpen: PropTypes.func.isRequired,
  collapsibleRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]).isRequired,
  collapsibleTriggerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]).isRequired,
};

export default injectIntl(MobileAccountLinks);
