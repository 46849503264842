import PropTypes from 'prop-types';

export const currencyInfoShape = PropTypes.shape({
  code: PropTypes.string,
  rate: PropTypes.number,
  symbol: PropTypes.string,
});

export const allCurrencyShape = PropTypes.shape({
  edges: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      ISO3: PropTypes.string.isRequired,
      currencyInfo: currencyInfoShape.isRequired,
    }),
  })),
});
