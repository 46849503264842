import { useEffect, useState } from 'react';

export default function useOptimizelyIsInitialized() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isInitialized) {
      return;
    }

    if (!isInitialized && window.optimizely && window.optimizely.initialized) {
      setIsInitialized(true);
      setIsLoading(false);
      return;
    }

    window.optimizely = window.optimizely || [];
    window.optimizely.push({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'initialized',
      },
      handler() {
        setIsInitialized(true);
        setIsLoading(false);
      },
    });
  }, [isInitialized]);

  useEffect(() => {
    // If Optimizely is never initialized, stop loading after 3 seconds.
    setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 3000);
  }, []);

  return { isInitialized, isLoading };
}
