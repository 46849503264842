import { IntlShape } from 'gatsby-plugin-react-intl';
import { CourseOrProgramType } from '../ui/ProductCard/types';

type ImageType = { src: string };
type OwnerType = { key: string; name: string; logoImageUrl?: string };
type CourseTitleType = { title: string };
type FactsType = { heading: string; blurb: string };

type QuickFactType = {
  icon: string;
  text: string;
};

type ProductMetaType = {
  title: string;
  description: string;
  keywords: string[];
};

type ProgramLocationRestrictionType = {
  restrictionType: string;
  countries: string[];
  states: string[];
};

type MastersCostType = {
  amount: string;
  description: string;
};

type DeadlineType = {
  date: string;
  name: string;
  semester: string;
  time: string;
};

type RankingType = {
  rank: string;
  description: string;
  source: string;
};

type CourseRunType = {
  title: string;
  key: string;
  type: string;
  minEffort?: number;
  maxEffort?: number;
  weeksToComplete?: number;
};

type AdditionalMetadataType = {
  externalCourseMarketingType?: string;
  externalIdentifier?: string;
  externalUrl?: string;
  organicUrl?: string;
  leadCaptureFormUrl?: string;
  organizationHexColor?: string;
  facts?: FactsType[];
  certificateInfo?: FactsType;
  startDate?: Date;
  registrationDeadline?: Date;
  productMeta?: ProductMetaType;
  productStatus?: string;
};

type DegreeType = {
  additionalMetadata: AdditionalMetadataType;
  applyUrl?: string;
  overallRanking?: string;
  quickFacts?: QuickFactType[];
  rankings?: RankingType[];
  deadlines?: DeadlineType[];
  deadlinesFinePrint?: string;
  costs?: MastersCostType[];
  costsFinePrint?: string;
  prerequisiteCoursework?: string;
  applicationRequirements?: string;
  hubspotLeadCaptureFormId?: string;
  internal2uCaptureFormId?: string;
  locationRestriction?: ProgramLocationRestrictionType;
  micromastersLongTitle?: string;
  micromastersLongDescription?: string;
  micromastersPath?: string;
  micromastersOrgNameOverride?: string;
  organizationLogoOverrideUrl?: string;
  organizationShortCodeOverride?: string;
  bannerBorderColor?: string;
  campusImage?: string;
  titleBackgroundImage?: string;
};

type ProductSourceType = {
    name: string;
    slug: string;
    description?: string;
}

export interface ProductDataType {
  uuid: string;
  courseUuid?: string;
  programUuid?: string;
  title: string;
  logoFilename?: string;
  cardImageUrl?: string;
  image?: ImageType;
  productType?: string;
  authoringOrganizations?: OwnerType[];
  courses?: CourseTitleType[];
  type?: string;
  marketingPath?: string;
  courseCount?: number;
  levelType?: string;
  prospectusPath?: string;
  owners?: OwnerType[];
  partner?: string;
  activeCourseRun?: CourseRunType;
  marketingURL?: string;
  intl?: IntlShape;
  additionalTrackingParams?: any;
  resultsLevel?: 'first-level-results' | 'second-level-results' | '';
  productSource?: ProductSourceType | any;
  degree?: DegreeType;
  additionalMetadata?: AdditionalMetadataType;
  organizationShortCodeOverride?: string;
  organizationLogoOverrideUrl?: string;
  hasDeviceParam?: boolean;
  cardType?: CourseOrProgramType;
  courseType?: string;
  queryID?: string;
  position?: number;
  webView?: boolean;
  inProspectus?: boolean;
  is2UDegreeProgram?: boolean;
  subscriptionEligible?: boolean;
}

export type ProductType =
  | "Master's"
  | "Bachelor's"
  | 'Professional Certificate'
  | 'XSeries'
  | 'Certificate'
  | 'License'
  | 'Doctorate'
  | 'MicroMasters'
  | 'MicroBachelors'
  | 'Boot Camp'
  | 'Executive Education'
  | 'Course';

export const createCodeFriendlyProduct = (type?: ProductType) => type?.replace(/\s+/g, '-').replace(/'/g, '').toLowerCase();
