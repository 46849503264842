import './styles.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { Container } from '@edx/paragon';
import { Close } from '@edx/paragon/icons';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import useRuntimeRenderable from '@prospectus/common/hooks/useRuntimeRenderable';
import { removePunctuationAndSpaces } from '@utils/formatting';
import useActiveBanner from './hooks/useActiveBanner';
import formatContent from './helpers/formatContent';

function SitewideBanner({ banners, intl }) {
  const runtimeRenderable = useRuntimeRenderable();
  const activeBanner = useActiveBanner(banners, intl);
  const cookieName = activeBanner ? `edx-sitewide-banner-${removePunctuationAndSpaces(activeBanner.campaignId)}` : undefined;
  const [showBanner, setShowBanner] = useState(true);

  const setHasViewedBannerCookie = () => {
    const domainInfo = process.env.GATSBY_COOKIE_ENV !== 'localhost'
      ? { domain: 'edx.org', expires: 365 }
      : {};

    // this cookie will be handled by our cloudflare service worker to determine visiblity.
    Cookies.set(cookieName, true, domainInfo);
  };

  const hideBanner = () => {
    setShowBanner(false);
    setHasViewedBannerCookie();
  };

  useEffect(() => {
    if (Cookies.get(cookieName)) {
      hideBanner();
    }
  }, []);

  const bgColor = activeBanner ? activeBanner.backgroundColor : 'transparent';
  const textColor = activeBanner ? activeBanner.textColor : 'initial';

  // the id and data-cookie-name attributes are required for the
  // possibility of parsing the element in cloudflare workers
  return (
    runtimeRenderable ? ( // avoid rendering during build time so that there won't be hydration mismatch errors
      <div
        id="sitewide-banner"
        role="alert"
        data-cookie-name={cookieName}
        className={classNames(
          'sitewide-banner position-relative',
          { 'banner-display': showBanner && activeBanner },
        )}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        <Container size="lg" className="d-flex justify-content-center sitewide-banner-content">
          {activeBanner && formatContent(activeBanner)}
          <button
            className="btn btn-link btn-close d-flex justify-content-center align-items-center p-2 mr-2 position-absolute"
            style={{ color: textColor }}
            onClick={hideBanner}
            type="button"
          >
            <Close focusable={false} role="img" aria-hidden />
            <div className="sr-only">
              <FormattedMessage
                id="prospectus.sitewide-banner.button.close"
                description="Label for button to close the site banner."
                defaultMessage="Close site banner."
              />
            </div>
          </button>
        </Container>
      </div>
    ) : null
  );
}

export const SitewideBannerProps = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  languageCode: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  content: PropTypes.shape({
    raw: PropTypes.string,
  }).isRequired,
});

SitewideBanner.propTypes = {
  banners: PropTypes.arrayOf(SitewideBannerProps),
  intl: intlShape.isRequired,
};

SitewideBanner.defaultProps = {
  banners: [],
};

export default track({
  component: 'sitewide-banner',
})(injectIntl(SitewideBanner));
