import './styles.scss';
import React, { useEffect } from 'react';
import { Input } from '@edx/paragon';
import { useLocation } from '@gatsbyjs/reach-router';
import { FormattedMessage, defineMessages, injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import { setLanguagePreferenceCookie } from '@utils/cookies';
import { redirectToTranslatedPage, withI18nPrefix } from '@utils/i18n';
import { getEnterpriseMarketingLinkData } from '@utils/edxLinks';
import AppDownloadLinks from '@prospectus/common/ui/AppDownloadLinks';
import useCCPACookie from '@prospectus/common/ui/CCPADialog/hooks/useCCPACookie';
import EdX2ULogo from './EdX2ULogo';
import {
  footerMessages, getFooterLinks, formatUrl, renderLinkList,
} from './utils';

const CCPA_QUERY_PARAM = /\bopendns=/;
const SOCIAL_WORK_MASTERS_QUERY_PARAM = /^\/masters\/online-msw-programs(\/[\w-]+)*$/;

function SiteFooter({ intl }) {
  const location = useLocation();
  const { setOptOutEnabled } = useCCPACookie();
  const renderCustomFooter = SOCIAL_WORK_MASTERS_QUERY_PARAM.test(location?.pathname);

  const filterLinks = (list) => {
    if (intl.locale === 'es') {
      return list.filter(({ hideForES = false }) => !hideForES);
    }

    return list;
  };

  const applyLanguageSelection = (event) => {
    event.preventDefault();
    const languageCode = event.target.elements['site-footer-language-select'].value;
    setLanguagePreferenceCookie(
      languageCode,
      redirectToTranslatedPage(window.location, languageCode),
    );
  };

  const toggleConsentModal = () => {
    setOptOutEnabled(true);
    window.OneTrust?.ToggleInfoDisplay();
  };

  const messages = defineMessages(footerMessages);

  const supportedLanguages = [
    {
      label: 'English',
      value: 'en',
    }, {
      label: 'español',
      value: 'es',
    },
  ];

  const enterpriseMarketingLinkData = getEnterpriseMarketingLinkData({
    locale: intl.locale,
    utmMedium: process.env.GATSBY_ENTERPRISE_MARKETING_FOOTER_UTM_MEDIUM,
  });
  enterpriseMarketingLinkData.title = intl.formatMessage(messages['prospectus.footer.edxLinks.business']);

  const {
    socialLinks,
    edXLinks,
    legalLinks,
    connectLinks,
    learnCourses,
    bootCampCourses,
    becomeGuides,
    bachelorsCourses,
    masterCourses,
    onlineMSWPrograms,
    onlineMSWTypes,
    onlineMSWTheories,
    onlineMSWLocations,

  } = getFooterLinks({
    messages, intl, enterpriseMarketingLinkData, toggleConsentModal,
  });

  useEffect(() => {
    if (CCPA_QUERY_PARAM.test(location.search)) { // if ?opendns=truthy is found
      if (window?.optanonLoaded) {
        toggleConsentModal();
      } else {
        window.addEventListener('OptanonLoaded', toggleConsentModal);
      }
    }

    return () => {
      window.removeEventListener('OptanonLoaded', toggleConsentModal);
    };
  }, []);

  const seoLinks = () => (
    <div className="master-links mt-4 mb-4">
      {
        renderCustomFooter ? (
          <div>
            <div className="social-work-masters-links d-flex justify-content-between">
              <div className="mb-4 mr-5 flex-grow-1">
                {renderLinkList({
                  title: 'Online MSW Programs',
                  linkList: filterLinks(onlineMSWPrograms),
                  isSeoLinks: true,
                })}
                <a
                  href={formatUrl({
                    url: withI18nPrefix('/masters/social-work#browse-accredited-online-master-of-social-work-degrees', intl.locale),
                  })}
                  target="_blank"
                  rel="noreferrer"
                  className="footer-seo-link text-info-500"
                >
                  see more MSW Programs
                </a>
              </div>
              <div className="mb-4 mr-5 flex-grow-1">
                {renderLinkList({
                  title: 'Online MSW Types',
                  linkList: filterLinks(onlineMSWTypes),
                  isSeoLinks: true,
                })}
                <a
                  href={formatUrl({
                    url: withI18nPrefix('/masters/social-work/types-of-social-work', intl.locale),
                  })}
                  target="_blank"
                  rel="noreferrer"
                  className="footer-seo-link text-info-500"
                >
                  see more MSW Types
                </a>
              </div>
              <div className="mb-4 mr-5 flex-grow-1">
                {renderLinkList({
                  title: 'Online MSW Theories',
                  linkList: filterLinks(onlineMSWTheories),
                  isSeoLinks: true,
                })}
                <a
                  href={formatUrl({
                    url: withI18nPrefix('/masters/social-work/social-work-theories', intl.locale),
                  })}
                  target="_blank"
                  rel="noreferrer"
                  className="footer-seo-link text-info-500"
                >
                  see more MSW Theories
                </a>
              </div>
              <div className="mb-4 mr-5 flex-grow-1">
                {renderLinkList({
                  title: 'Online MSW Locations',
                  linkList: filterLinks(onlineMSWLocations),
                  isSeoLinks: true,
                })}
                <a
                  href={formatUrl({
                    url: withI18nPrefix('/masters/social-work/locations', intl.locale),
                  })}
                  target="_blank"
                  rel="noreferrer"
                  className="footer-seo-link text-info-500"
                >
                  see more MSW Locations
                </a>
              </div>
            </div>
            <hr />
          </div>
        ) : ''
      }
      <div className="seo-links d-flex justify-content-between">
        <div className="mb-4 mr-5 flex-grow-1">
          {renderLinkList({
            title: 'BROWSE COURSES',
            linkList: filterLinks(learnCourses),
            isSeoLinks: true,
          })}
          <a
            href={formatUrl({
              url: withI18nPrefix('/learn', intl.locale),
            })}
            target="_blank"
            rel="noreferrer"
            className="footer-seo-link text-info-500"
          >
            see more Courses
          </a>
        </div>

        <div className="mb-4 mr-5 flex-grow-1">
          {renderLinkList({
            title: 'START A NEW CAREER',
            linkList: filterLinks(bootCampCourses),
            isSeoLinks: true,
          })}
          <a
            href={formatUrl({
              url: withI18nPrefix('/boot-camps', intl.locale),
            })}
            target="_blank"
            rel="noreferrer"
            className="footer-seo-link text-info-500"
          >
            see more Boot Camps
          </a>

          {renderLinkList({
            title: 'STEP-BY-STEP GUIDES',
            linkList: filterLinks(becomeGuides),
            isSeoLinks: true,
            topMargin: true,
          })}
          <a
            href={formatUrl({
              url: withI18nPrefix('/become', intl.locale),
            })}
            target="_blank"
            rel="noreferrer"
            className="footer-seo-link text-info-500"
          >
            see more Guides
          </a>
        </div>

        <div className="mb-4 mr-5 flex-grow-1">
          {renderLinkList({
            title: 'COMPLETE YOUR BACHELOR\'S ONLINE',
            linkList: filterLinks(bachelorsCourses),
            isSeoLinks: true,
          })}
          <a
            href={formatUrl({
              url: withI18nPrefix('/bachelors', intl.locale),
            })}
            target="_blank"
            rel="noreferrer"
            className="footer-seo-link text-info-500"
          >
            see more Undergraduate Degrees
          </a>
        </div>

        <div className="mb-4 mr-5 flex-grow-1">
          {renderLinkList({
            title: 'EARN YOUR ONLINE GRADUATE DEGREE',
            linkList: filterLinks(masterCourses),
            isSeoLinks: true,
          })}
          <a
            href={formatUrl({
              url: withI18nPrefix('/masters', intl.locale),
            })}
            target="_blank"
            rel="noreferrer"
            className="footer-seo-link text-info-500"
          >
            see more Graduate Degrees
          </a>
        </div>
      </div>
      <hr className="hr" />
    </div>
  );

  return (
    <div className="site-footer-wrapper footer border-top">
      <footer
        role="contentinfo"
        aria-label={intl.formatMessage(messages['prospectus.footer.ariaLabel'])}
        className="container mw-lg d-flex justify-content-between pb-3"
      >
        <div className="d-flex flex-column w-100">
          <div className="d-flex justify-content-between mb-4 flex-wrap">
            <a
              href={process.env.GATSBY_SITE_DOMAIN}
              aria-label={intl.formatMessage(messages['prospectus.footer.logo.ariaLabel'])}
            >
              <EdX2ULogo />
            </a>
            <div className="social-links">
              <ul className="medium-column d-flex flex-row justify-content-between list-unstyled p-0 mb-4">
                {socialLinks.map(link => (
                  <li key={link.url} className="mx-2">
                    <a
                      href={link.url}
                      title={link.title}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div className="social-media-share-button d-flex justify-content-center align-items-center rounded-circle">
                        {link.icon}
                        <span className="sr-only">{link.screenReaderText}</span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {intl.locale !== 'es' && seoLinks()}
          <div className="d-flex justify-content-between flex-wrap">
            <div className="mb-4 mr-5">
              {renderLinkList({
                title: 'edX',
                linkList: filterLinks(edXLinks),
              })}
            </div>
            <div className="mb-4 mr-5">{renderLinkList({
              title: intl.formatMessage(messages['prospectus.footer.connectLinks.heading']),
              linkList: filterLinks(connectLinks),
            })}
            </div>
            <div className="mb-4 flex-grow-1">
              {renderLinkList(
                {
                  title: intl.formatMessage(messages['prospectus.footer.legalLinks.heading']),
                  linkList: filterLinks(legalLinks),
                },
              )}
            </div>
            <div className="i18n d-flex mb-4">
              <form
                onSubmit={applyLanguageSelection}
                className="large-column"
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label
                  className="section-title mt-0"
                  htmlFor="site-footer-language-select"
                >
                  {intl.formatMessage(messages['prospectus.footer.languageForm.select.label'])}
                </label>
                <div className="d-flex align-items-start mt-3">
                  <Input
                    id="site-footer-language-select"
                    type="select"
                    defaultValue={intl.locale}
                    options={supportedLanguages}
                    className="mr-2"
                  />
                  <button id="site-footer-language-submit" className="btn btn-outline-primary" type="submit">
                    {intl.formatMessage(messages['prospectus.footer.languageForm.submit.label'])}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap-reverse body-small">
            <p>
              {`© ${new Date().getFullYear()} edX LLC. All rights reserved.`}
              <br />
              <FormattedMessage
                id="prospectus.footer.trademarks"
                defaultMessage="{icpMessage}"
                description="A description of the trademarks that belong to edX."
                values={{
                  icpMessage: (<span lang="zh-CN">
                    | 深圳市恒宇博科技有限公司 <a href="https://beian.miit.gov.cn" className="inline-link">粤ICP备17044299号-2</a>
                  </span>), // eslint-disable-line react/jsx-closing-tag-location
                }}
              />
            </p>
            <AppDownloadLinks className="large-column d-flex flex-row justify-content-between p-0 mb-5" />
          </div>
        </div>
      </footer>
    </div>
  );
}

SiteFooter.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SiteFooter);
