import React from 'react';

import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import { CLEAR_ACTIVE_CATEGORY } from '@prospectus/common/ui-strict/MegaNav/data/constants';
import { useMegaNavDispatch } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import isUserLoggedIn from '@utils/user';

type MenuLinkType = {
  eventName: string;
  href: string;
  label: string;
};

function MenuLink({ eventName, href, label }: MenuLinkType) {
  const dispatch = useMegaNavDispatch();

  return (
    <div onMouseEnter={() => { dispatch({ type: CLEAR_ACTIVE_CATEGORY }); }}>
      <ProspectusLink
        className="nav-menu__hyperlink small d-inline-block w-100 px-4 py-2 text-black"
        href={href}
        analytics={{
          eventName,
          linkProps: { loggedIn: isUserLoggedIn(), navigationLink: true },
        }}
      >
        {label}
      </ProspectusLink>
    </div>
  );
}

export default MenuLink;
