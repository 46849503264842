import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

const LOCATION_OVERRIDE_COOKIE = 'location-override';
const USER_LOCATION_COOKIE = 'prod-edx-cf-loc';
const USER_LOCATION_LATITUDE = 'stage-edx-cf-iplatitude';
const USER_LOCATION_LONGITUDE = 'stage-edx-cf-iplongitude';
const LOCATION_USA = 'US';
const METERS_IN_ONE_MILE = 1609;
const PRECISION_RADIUS_MILES = 50;

const getUserLocation = () => Cookies.get(LOCATION_OVERRIDE_COOKIE) || Cookies.get(USER_LOCATION_COOKIE);
const getUserCoordinates = () => {
  const latitude = Cookies.get(USER_LOCATION_LATITUDE);
  if (latitude) {
    const longitude = Cookies.get(USER_LOCATION_LONGITUDE);
    return latitude.concat(',', longitude);
  }
  return null;
};
const getPrecisionRadius = () => METERS_IN_ONE_MILE * PRECISION_RADIUS_MILES;
const isUserInUSA = () => getUserLocation() === LOCATION_USA;

const filterAlgoliaProductsByCountryRestriction = (productList) => {
  const userCountry = getUserLocation();

  if (!userCountry) {
    // If location cookie is not set, we can't filter by country
    return productList;
  }
  return productList?.filter(product => (
    // Algolia can't filter on null/nonexistent values, so "empty" lists have a single string value of "null" here
    // First check that the user's country is not in the blocklist
    !product.blockedIn.includes(userCountry)
    // Then check that the allowlist is either empty or includes the user's country
    && (product.allowedIn.includes('null') || product.allowedIn.includes(userCountry))
  ));
};

const filterCardListModuleByCountryRestriction = (
  cardList,
  courseLocationRestrictionMap,
  programLocationRestrictionMap,
) => {
  const userCountry = getUserLocation();
  if (!userCountry) {
    // If location cookie is not set, we can't filter by country
    return cardList;
  }

  if (isEmpty(courseLocationRestrictionMap?.allowlist)
    && isEmpty(courseLocationRestrictionMap?.blocklist)
    && isEmpty(programLocationRestrictionMap?.allowlist)
    && isEmpty(programLocationRestrictionMap?.blocklist)
  ) {
    // If there are no products under allowlist or blocklist, just return
    return cardList;
  }

  return cardList.filter(product => {
    const { uuid } = product;

    let allowlist = {};
    let blocklist = {};

    if (product.cardType === 'course') {
      allowlist = courseLocationRestrictionMap.allowlist;
      blocklist = courseLocationRestrictionMap.blocklist;
    } else {
      allowlist = programLocationRestrictionMap.allowlist;
      blocklist = programLocationRestrictionMap.blocklist;
    }

    if (allowlist[uuid]) {
      return allowlist[uuid].countries.includes(userCountry);
    }
    if (blocklist[uuid]) {
      return !blocklist[uuid].countries.includes(userCountry);
    }
    // If a product does not have an allowlist or blocklist, always display it
    return true;
  });
};

const filter2UProductsByCountryRestriction = (
  allProducts,
  courseLocationRestrictionMap,
  programLocationRestrictionMap,
) => {
  const filteredProducts = {};
  Object.keys(allProducts).forEach(productType => {
    Object.keys(allProducts[productType].subcategories).forEach(subcategory => {
      const filteredCardList = filterCardListModuleByCountryRestriction(
        allProducts[productType].subcategories[subcategory].cardList,
        courseLocationRestrictionMap,
        programLocationRestrictionMap,
      );

      if (!filteredProducts[productType]) {
        filteredProducts[productType] = cloneDeep(allProducts[productType]);
      }

      if (filteredCardList.length === 0) {
        delete filteredProducts[productType].subcategories[subcategory];
      } else {
        filteredProducts[productType].subcategories[subcategory] = {
          ...allProducts[productType].subcategories[subcategory],
          cardList: filteredCardList,
        };
      }
    });
  });
  return filteredProducts;
};

export {
  isUserInUSA,
  filterAlgoliaProductsByCountryRestriction,
  filterCardListModuleByCountryRestriction,
  getUserLocation,
  getUserCoordinates,
  filter2UProductsByCountryRestriction,
  getPrecisionRadius,
};
