import React, {
  createContext,
  useContext,
  useReducer,
} from 'react';

import {
  CLEAR_ACTIVE_CATEGORY,
  CLOSE_MEGA_NAV,
  OPEN_MEGA_NAV,
  SET_ACTIVE_CATEGORY,
  SET_CATEGORIES,
  SET_MENU_ITEMS,
  TOGGLE_MEGA_NAV,
} from './constants';
import { MegaNavProviderPropType } from './types';

const MegaNavContext = createContext(null);
const MegaNavDispatchContext = createContext(null);

const megaNavState = {
  isOpen: false,
  activeCategory: null,
  categories: {},
  menuItems: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case OPEN_MEGA_NAV: {
      return { ...state, isOpen: true };
    }

    case CLOSE_MEGA_NAV: {
      return { ...state, isOpen: false, activeCategory: null };
    }

    case TOGGLE_MEGA_NAV: {
      return { ...state, isOpen: !state.isOpen };
    }

    case SET_ACTIVE_CATEGORY: {
      return { ...state, activeCategory: action.payload };
    }

    case CLEAR_ACTIVE_CATEGORY: {
      return { ...state, activeCategory: null };
    }

    case SET_CATEGORIES: {
      return { ...state, categories: action.payload };
    }

    case SET_MENU_ITEMS: {
      return { ...state, menuItems: action.payload };
    }

    default: {
      return state;
    }
  }
};

function MegaNavProvider({ children, initialState, overrideDispatch }: MegaNavProviderPropType) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MegaNavContext.Provider value={state}>
      <MegaNavDispatchContext.Provider value={overrideDispatch || dispatch}>
        {children}
      </MegaNavDispatchContext.Provider>
    </MegaNavContext.Provider>
  );
}

MegaNavProvider.defaultProps = {
  initialState: megaNavState,
  overrideDispatch: undefined, // only use for tests
};

function useMegaNav() {
  return useContext(MegaNavContext);
}

function useMegaNavDispatch() {
  return useContext(MegaNavDispatchContext);
}

export { MegaNavProvider, useMegaNav, useMegaNavDispatch };
