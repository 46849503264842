import './styles.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@edx/paragon';
import {
  FormattedMessage,
} from 'gatsby-plugin-react-intl';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';

function MobileUserActionLinks({
  userLoggedIn,
  dashboardLinkClick,
  dashboardLink,
  loginLink,
  registerLink,
}) {
  if (userLoggedIn) {
    return (
      <div className="p-3 border-bottom bg-white">
        <li className="menu-link visible-mobile user-cta d-flex" key="dashboard">
          <a
            href={dashboardLink}
            className="btn btn-primary w-100"
            onClick={dashboardLinkClick}
          >
            <FormattedMessage
              id="prospectus.header.site_nav.user_menu.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard link text in the user portion of the menu - when the user is logged in"
            />
          </a>
        </li>
      </div>
    );
  }

  return (
    <div className="p-3 border-bottom bg-white">
      <li className="menu-link visible-mobile inline-primary-ctas d-flex justify-content-between" key="login">
        <Stack gap={2} direction="horizontal" className="w-100">
          <a
            href={loginLink}
            className="btn btn-outline-primary border-gray-700 bg-transparent w-50 account-access-button"
          >
            <FormattedMessage
              id="prospectus.header.site_nav.user_menu.sign_in"
              defaultMessage="Sign In"
              description="Sign In link text in the user portion of the menu - when the user is logged out"
            />
          </a>
          <ProspectusLink
            href={registerLink}
            className="btn btn-brand w-50 account-access-button"
            analytics={{
              eventName: 'edx.bi.user.register.clicked',
            }}
          >
            <FormattedMessage
              id="prospectus.header.site_nav.user_menu.register.for.free"
              defaultMessage="Register for free"
              description="Text for a link that will lead the user to our registration flow"
            />
          </ProspectusLink>
        </Stack>
      </li>
    </div>

  );
}

MobileUserActionLinks.propTypes = {
  userLoggedIn: PropTypes.bool.isRequired,
  dashboardLinkClick: PropTypes.func.isRequired,
  loginLink: PropTypes.string.isRequired,
  registerLink: PropTypes.string.isRequired,
  dashboardLink: PropTypes.string.isRequired,
};

export default MobileUserActionLinks;
