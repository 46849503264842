import React from 'react';
import {
  Reddit, Facebook, BsTwitter, BsLinkedin,
} from '@edx/paragon/icons';
import { Icon } from '@edx/paragon';
import { withI18nPrefix } from '@utils/i18n';

export const footerMessages = {
  'prospectus.footer.socialLinks.srText.facebook': {
    id: 'prospectus.footer.socialLinks.srText.facebook',
    defaultMessage: 'Like edX on Facebook',
    description: 'This is screenreader text for the edX Facebook social media link in the footer.',
  },
  'prospectus.footer.socialLinks.srText.twitter': {
    id: 'prospectus.footer.socialLinks.srText.twitter',
    defaultMessage: 'Follow edX on Twitter',
    description: 'This is screenreader text for the edX Twitter social media link in the footer.',
  },
  'prospectus.footer.socialLinks.srText.youtube': {
    id: 'prospectus.footer.socialLinks.srText.youtube',
    defaultMessage: 'Subscribe to the edX YouTube channel',
    description: 'This is screenreader text for the edX YouTube social media link in the footer.',
  },
  'prospectus.footer.socialLinks.srText.linkedin': {
    id: 'prospectus.footer.socialLinks.srText.linkedin',
    defaultMessage: 'Follow edX on LinkedIn',
    description: 'This is screenreader text for the edX LinkedIn social media link in the footer.',
  },
  'prospectus.footer.socialLinks.srText.reddit': {
    id: 'prospectus.footer.socialLinks.srText.reddit',
    defaultMessage: 'Subscribe to the edX subreddit',
    description: 'This is screenreader text for the edX reddit social media link in the footer.',
  },
  'prospectus.footer.languageForm.select.label': {
    id: 'prospectus.footer.languageForm.select.label',
    defaultMessage: 'Choose Language',
    description: 'The label for the laguage select part of the language selection form.',
  },
  'prospectus.footer.languageForm.submit.label': {
    id: 'prospectus.footer.languageForm.submit.label',
    defaultMessage: 'Apply',
    description: 'The label for button to submit the language selection form.',
  },
  'prospectus.footer.edxLinks.about': {
    id: 'prospectus.footer.edxLinks.about',
    defaultMessage: 'About',
    description: 'The label for the link to the about edX page.',
  },
  'prospectus.footer.edxLinks.bootcamps': {
    id: 'prospectus.footer.edxLinks.bootcamps',
    defaultMessage: 'Boot Camps',
    description: 'The label for the link to the Boot Camps edX page.',
  },
  'prospectus.footer.edxLinks.business': {
    id: 'prospectus.footer.edxLinks.business',
    defaultMessage: 'edX For Business',
    description: 'The label for the link to the edX for business page.',
  },
  'prospectus.footer.edxLinks.advisory': {
    id: 'prospectus.footer.edxLinks.advisory',
    defaultMessage: '2U Advisory Council',
    description: 'The label for the link to the 2U Advisory Councils page.',
  },
  'prospectus.footer.edxLinks.affiliates': {
    id: 'prospectus.footer.edxLinks.affiliates',
    defaultMessage: 'Affiliates',
    description: 'The label for the link to the edX affiliates page.',
  },
  'prospectus.footer.edxLinks.openEdx': {
    id: 'prospectus.footer.edxLinks.openEdx',
    defaultMessage: 'Open edX',
    description: 'The label for the link to the open edX site.',
  },
  'prospectus.footer.edxLinks.careers': {
    id: 'prospectus.footer.edxLinks.careers',
    defaultMessage: 'Careers',
    description: 'The label for the link to the edX Careers page.',
  },
  'prospectus.footer.edxLinks.news': {
    id: 'prospectus.footer.edxLinks.news',
    defaultMessage: 'News',
    description: 'The label for the link to the edX news page.',
  },
  'prospectus.footer.legalLinks.heading': {
    id: 'prospectus.footer.legalLinks.heading',
    defaultMessage: 'Legal',
    description: 'Heading for the legal links section of the footer.',
  },
  'prospectus.footer.legalLinks.termsOfService': {
    id: 'prospectus.footer.legalLinks.termsOfService',
    defaultMessage: 'Terms of Service & Honor Code',
    description: 'The label for the link to the edX terms of service page.',
  },
  'prospectus.footer.legalLinks.privacyPolicy': {
    id: 'prospectus.footer.legalLinks.privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'The label for the link to the edX privacy policy page.',
  },
  'prospectus.footer.legalLinks.cookiePolicy': {
    id: 'prospectus.footer.legalLinks.cookiePolicy',
    defaultMessage: 'Cookie Policy',
    description: 'The label for the link to the edX cookie policy page.',
  },
  'prospectus.footer.legalLinks.a11yPolicy': {
    id: 'prospectus.footer.legalLinks.a11yPolicy',
    defaultMessage: 'Accessibility Policy',
    description: 'The label for the link to the edX accessibility policy page.',
  },
  'prospectus.footer.legalLinks.modernSlaveryStatement': {
    id: 'prospectus.footer.legalLinks.modernSlaveryStatement',
    defaultMessage: 'Modern Slavery Statement',
    description: 'The label for the link to the edX modern slavery statement page.',
  },
  'prospectus.footer.legalLinks.trademarkPolicy': {
    id: 'prospectus.footer.legalLinks.trademarkPolicy',
    defaultMessage: 'Trademark Policy',
    description: 'The label for the link to the edX trademark policy page.',
  },
  'prospectus.footer.legalLinks.sitemap': {
    id: 'prospectus.footer.legalLinks.sitemap',
    defaultMessage: 'Sitemap',
    description: 'The label for the link to the edX sitemap page.',
  },
  'prospectus.footer.legalLinks.doNotSellData': {
    id: 'prospectus.footer.legalLinks.doNotSellData',
    defaultMessage: 'Your Privacy Choices',
    description: 'The label for the link button that leads to the cookie settings menu.',
  },
  'prospectus.footer.connectLinks.heading': {
    id: 'prospectus.footer.connectLinks.heading',
    defaultMessage: 'Connect',
    description: 'Heading for the connect links section of the footer.',
  },
  'prospectus.footer.connectLinks.ideaHub': {
    id: 'prospectus.footer.connectLinks.ideaHub',
    defaultMessage: 'Idea Hub',
    description: 'The label for the link to the edX idea hub.',
  },
  'prospectus.footer.connectLinks.contact': {
    id: 'prospectus.footer.connectLinks.contact',
    defaultMessage: 'Contact Us',
    description: 'The label for the link to the contact edX page.',
  },
  'prospectus.footer.connectLinks.help': {
    id: 'prospectus.footer.connectLinks.help',
    defaultMessage: 'Help Center',
    description: 'The label for the link to the edX help center.',
  },
  'prospectus.footer.connectLinks.security': {
    id: 'prospectus.footer.connectLinks.security',
    defaultMessage: 'Security',
    description: 'The label for the link to the security disclosures page.',
  },
  'prospectus.footer.connectLinks.mediaKit': {
    id: 'prospectus.footer.connectLinks.mediaKit',
    defaultMessage: 'Media Kit',
    description: 'The label for the link to the edX media kit page.',
  },
  'prospectus.footer.mobileApp.apple': {
    id: 'prospectus.footer.mobileApp.apple',
    defaultMessage: 'Download the edX mobile app from the Apple App Store',
    description: 'The label for the link to download the apple version of the edX app.',
  },
  'prospectus.footer.mobileApp.google': {
    id: 'prospectus.footer.mobileApp.google',
    defaultMessage: 'Download the edX mobile app from Google Play',
    description: 'The label for the link to download the google version of the edX app.',
  },
  'prospectus.footer.logo.altText': {
    id: 'prospectus.footer.logo.altText',
    defaultMessage: 'edX Logo',
    description: 'alt text for the footer logo.',
  },
  'prospectus.footer.logo.ariaLabel': {
    id: 'prospectus.footer.logo.ariaLabel',
    defaultMessage: 'edX Home',
    description: 'aria-label for the footer logo.',
  },
  'prospectus.footer.ariaLabel': {
    id: 'prospectus.footer.ariaLabel',
    defaultMessage: 'Page Footer',
    description: 'aria-label for the footer component',
  },
  'prospectus.footer.learnCourses.excel': {
    id: 'prospectus.footer.learnCourses.excel',
    defaultMessage: 'Learn Excel',
    description: 'The label for the link to the edX excel courses',
  },
  'prospectus.footer.learnCourses.computerProgramming': {
    id: 'prospectus.footer.learnCourses.computerProgramming',
    defaultMessage: 'Learn Computer Programming',
    description: 'The label for the link to the edX computer programming courses',
  },
  'prospectus.footer.learnCourses.python': {
    id: 'prospectus.footer.learnCourses.python',
    defaultMessage: 'Learn Python',
    description: 'The label for the link to the edX python courses',
  },
  'prospectus.footer.learnCourses.projectManagement': {
    id: 'prospectus.footer.learnCourses.projectManagement',
    defaultMessage: 'Learn Project Management',
    description: 'The label for the link to the edX project management courses',
  },
  'prospectus.footer.learnCourses.blockchain': {
    id: 'prospectus.footer.learnCourses.blockchain',
    defaultMessage: 'Learn Blockchain',
    description: 'The label for the link to the edX blockchain courses',
  },
  'prospectus.footer.learnCourses.softwareEngineering': {
    id: 'prospectus.footer.learnCourses.softwareEngineering',
    defaultMessage: 'Learn Software Engineering',
    description: 'The label for the link to the edX software engineering courses',
  },
  'prospectus.footer.learnCourses.businessAdministration': {
    id: 'prospectus.footer.learnCourses.businessAdministration',
    defaultMessage: 'Learn Business Administration',
    description: 'The label for the link to the edX business administratino courses',
  },
  'prospectus.footer.learnCourses.economics': {
    id: 'prospectus.footer.learnCourses.economics',
    defaultMessage: 'Learn Economics',
    description: 'The label for the link to the edX economics courses',
  },
  'prospectus.footer.learnCourses.architecture': {
    id: 'prospectus.footer.learnCourses.architecture',
    defaultMessage: 'Learn Architecture',
    description: 'The label for the link to the edX architecture courses',
  },
  'prospectus.footer.learnCourses.spanish': {
    id: 'prospectus.footer.learnCourses.spanish',
    defaultMessage: 'Learn Spanish',
    description: 'The label for the link to the edX Spanish courses',
  },
  'prospectus.footer.learnCourses.courses': {
    id: 'prospectus.footer.learnCourses.courses',
    defaultMessage: 'see more Courses',
    description: 'The label for the link to the edX learn more courses',
  },
  'prospectus.footer.bootcampCourses.dataAnalytics': {
    id: 'prospectus.footer.bootcampCourses.dataAnalytics',
    defaultMessage: 'Data Analytics Boot Camp',
    description: 'The label for the edx bootcamp course link of Data Analytics',
  },
  'prospectus.footer.bootcampCourses.coding': {
    id: 'prospectus.footer.bootcampCourses.coding',
    defaultMessage: 'Coding Boot Camp',
    description: 'The label for the edx bootcamp course link of Coding',
  },
  'prospectus.footer.bootcampCourses.uxui': {
    id: 'prospectus.footer.bootcampCourses.uxui',
    defaultMessage: 'UX/UI Boot Camp',
    description: 'The label for the edx bootcamp course link of UX/UI',
  },
  'prospectus.footer.bootcampCourses.cybersecurity': {
    id: 'prospectus.footer.bootcampCourses.cybersecurity',
    defaultMessage: 'Cybersecurity Boot Camp',
    description: 'The label for the edx bootcamp course link of Cybersecurity',
  },
  'prospectus.footer.bootcampCourses.digitalMarketing': {
    id: 'prospectus.footer.bootcampCourses.digitalMarketing',
    defaultMessage: 'Digital Marketing Boot Camp',
    description: 'The label for the edx bootcamp course link of Digital Marketing',
  },
  'prospectus.footer.bootcampCourses.fintech': {
    id: 'prospectus.footer.bootcampCourses.fintech',
    defaultMessage: 'FinTech Boot Camp',
    description: 'The label for the edx bootcamp course link of FinTech',
  },
  'prospectus.footer.bootcampCourses.productManagement': {
    id: 'prospectus.footer.bootcampCourses.productManagement',
    defaultMessage: 'Product Management Boot Camp',
    description: 'The label for the edx bootcamp course link of Product Management',
  },
  'prospectus.footer.bootcampCourses.technologyProjectManagement': {
    id: 'prospectus.footer.bootcampCourses.technologyProjectManagement',
    defaultMessage: 'Technology Project Management Boot Camp',
    description: 'The label for the edx bootcamp course link of Technology Project Management',
  },
  'prospectus.footer.bootcampCourses.moreCourses': {
    id: 'prospectus.footer.bootcampCourses.moreCourses',
    defaultMessage: 'see more Boot Camps',
    description: 'The label for the edx bootcamp more courses link',
  },
  'prospectus.footer.becomeGuides.cybersecurityAnalyst': {
    id: 'prospectus.footer.becomeGuides.cybersecurityAnalyst',
    defaultMessage: 'Become a Cybersecurity Analyst',
    description: 'The label for the edx guide link on how to Become a Cybersecurity Analyst',
  },
  'prospectus.footer.becomeGuides.dataScientist': {
    id: 'prospectus.footer.becomeGuides.dataScientist',
    defaultMessage: 'Become a Data Scientist',
    description: 'The label for the edx guide link on how to Become a Data Scientist',
  },
  'prospectus.footer.becomeGuides.socialMediaManager': {
    id: 'prospectus.footer.becomeGuides.socialMediaManager',
    defaultMessage: 'Become a Social Media Manager',
    description: 'The label for the edx guide link on how to Become a Social Media Manager',
  },
  'prospectus.footer.becomeGuides.softwareDeveloper': {
    id: 'prospectus.footer.becomeGuides.softwareDeveloper',
    defaultMessage: 'Become a Software Developer',
    description: 'The label for the edx guide link on how to Become a Software Developer',
  },
  'prospectus.footer.becomeGuides.softwareEngineer': {
    id: 'prospectus.footer.becomeGuides.softwareEngineer',
    defaultMessage: 'Become a Software Engineer',
    description: 'The label for the edx guide link on how to Become a Software Engineer',
  },
  'prospectus.footer.becomeGuides.moreGuides': {
    id: 'prospectus.footer.becomeGuides.moreGuides',
    defaultMessage: 'see more Guides',
    description: 'The label for the edx more become guides link',
  },
  'prospectus.footer.bachelorsCourses.computerScience': {
    id: 'prospectus.footer.bachelorsCourses.computerScience',
    defaultMessage: 'Bachelor\'s in Computer Science / Data Science',
    description: 'The label for the edx bachelor course link of Computer Science / Data Science',
  },
  'prospectus.footer.bachelorsCourses.health': {
    id: 'prospectus.footer.bachelorsCourses.health',
    defaultMessage: 'Bachelor\'s in Health and Nursing',
    description: 'The label for the edx bachelor course link of Health and Nursing',
  },
  'prospectus.footer.bachelorsCourses.business': {
    id: 'prospectus.footer.bachelorsCourses.business',
    defaultMessage: 'Bachelor\'s in Business',
    description: 'The label for the edx bachelor course link of Business',
  },
  'prospectus.footer.bachelorsCourses.moreCourses': {
    id: 'prospectus.footer.bachelorsCourses.moreCourses',
    defaultMessage: 'see more Undergraduate Degrees',
    description: 'The label for the edx undergraduate more courses link',
  },
  'prospectus.footer.graduate.businessAdministration': {
    id: 'prospectus.footer.graduate.businessAdministration',
    defaultMessage: 'Master\'s in Business Administration',
    description: 'The label for the edx master degree course link of Business Administration',
  },
  'prospectus.footer.graduate.speechPathology': {
    id: 'prospectus.footer.graduate.speechPathology',
    defaultMessage: 'Master\'s in Speech Pathology',
    description: 'The label for the edx master degree course link of Speech Pathology',
  },
  'prospectus.footer.graduate.dataScience': {
    id: 'prospectus.footer.graduate.dataScience',
    defaultMessage: 'Master\'s in Data Science',
    description: 'The label for the edx master degree course link of Data Science',
  },
  'prospectus.footer.graduate.publicHealth': {
    id: 'prospectus.footer.graduate.publicHealth',
    defaultMessage: 'Master\'s in Public Health',
    description: 'The label for the edx master degree course link of Public Health',
  },
  'prospectus.footer.graduate.nursing': {
    id: 'prospectus.footer.graduate.nursing',
    defaultMessage: 'Master\'s in Nursing',
    description: 'The label for the edx master degree course link of Nursing',
  },
  'prospectus.footer.graduate.socialWork': {
    id: 'prospectus.footer.graduate.socialWork',
    defaultMessage: 'Master\'s in Social Work',
    description: 'The label for the edx master degree course link of Social Work',
  },
  'prospectus.footer.graduate.counseling': {
    id: 'prospectus.footer.graduate.counseling',
    defaultMessage: 'Master\'s in Counseling/Psychology',
    description: 'The label for the edx master degree course link of Counseling/Psychology',
  },
  'prospectus.footer.graduate.engineering': {
    id: 'prospectus.footer.graduate.engineering',
    defaultMessage: 'Master\'s in Engineering',
    description: 'The label for the edx master degree course link of Engineering',
  },
  'prospectus.footer.graduate.healthcare': {
    id: 'prospectus.footer.graduate.healthcare',
    defaultMessage: 'Master\'s Degree in Healthcare',
    description: 'The label for the edx master degree course link of Healthcare',
  },
  'prospectus.footer.graduate.education': {
    id: 'prospectus.footer.graduate.education',
    defaultMessage: 'Master\'s Degree in Education',
    description: 'The label for the edx master degree course link of Education',
  },
  'prospectus.footer.graduate.moreCourses': {
    id: 'prospectus.footer.graduate.moreCourses',
    defaultMessage: 'see more Graduate Degrees',
    description: 'The label for the edx graduate more courses link',
  },

  'prospectus.footer.onlineMSWPrograms.accreditedOnlineMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.accreditedOnlineMSWPrograms',
    defaultMessage: 'Accredited Online MSW Programs',
    description: 'The label for the edx online MSW program link of Accredited Online MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.fastTrackMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.fastTrackMSWPrograms',
    defaultMessage: 'Fast Track MSW Programs',
    description: 'The label for the edx online MSW program link of Fast Track MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.affordableMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.affordableMSWPrograms',
    defaultMessage: 'Affordable MSW Programs',
    description: 'The label for the edx online MSW program link of Affordable MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.advancedStandingMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.advancedStandingMSWPrograms',
    defaultMessage: 'Advanced Standing MSW Programs',
    description: 'The label for the edx online MSW program link of Advanced Standing MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.oneYearMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.oneYearMSWPrograms',
    defaultMessage: 'One Year MSW Programs',
    description: 'The label for the edx online MSW program link of One Year MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.acceleratedMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.acceleratedMSWPrograms',
    defaultMessage: 'Accelerated MSW Programs',
    description: 'The label for the edx online MSW program link of Accelerated MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.onlineMSWProgramsWithNoGRE': {
    id: 'prospectus.footer.onlineMSWPrograms.onlineMSWProgramsWithNoGRE',
    defaultMessage: 'Online MSW Programs with No GRE',
    description: 'The label for the edx online MSW program link of Online MSW Programs with No GRE',
  },
  'prospectus.footer.onlineMSWPrograms.partTimeMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.partTimeMSWPrograms',
    defaultMessage: 'Part Time MSW Programs',
    description: 'The label for the edx online MSW program link of Part Time MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.hbcuOnlineMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.hbcuOnlineMSWPrograms',
    defaultMessage: 'HBCU Online MSW Programs',
    description: 'The label for the edx online MSW program link of HBCU Online MSW Programs',
  },
  'prospectus.footer.onlineMSWPrograms.moreMSWPrograms': {
    id: 'prospectus.footer.onlineMSWPrograms.moreMSWPrograms',
    defaultMessage: 'see more MSW Programs',
    description: 'The label for the edx online MSW program link of see more MSW Programs',
  },

  'prospectus.footer.onlineMSWTypes.clinicalSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.clinicalSocialWork',
    defaultMessage: 'Clinical Social Work',
    description: 'The label for the edx online MSW program link of Clinical Social Work',
  },
  'prospectus.footer.onlineMSWTypes.medicalSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.medicalSocialWork',
    defaultMessage: 'Medical Social Work',
    description: 'The label for the edx online MSW program link of Medical Social Work',
  },
  'prospectus.footer.onlineMSWTypes.macroSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.macroSocialWork',
    defaultMessage: 'Macro Social Work',
    description: 'The label for the edx online MSW program link of Macro Social Work',
  },
  'prospectus.footer.onlineMSWTypes.mentalHealthSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.mentalHealthSocialWork',
    defaultMessage: 'Mental Health Social Work',
    description: 'The label for the edx online MSW program link of Mental Health Social Work',
  },
  'prospectus.footer.onlineMSWTypes.hospiceSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.hospiceSocialWork',
    defaultMessage: 'Hospice Social Work',
    description: 'The label for the edx online MSW program link of Hospice Social Work',
  },
  'prospectus.footer.onlineMSWTypes.psychiatricSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.psychiatricSocialWork',
    defaultMessage: 'Psychiatric Social Work',
    description: 'The label for the edx online MSW program link of Psychiatric Social Work',
  },
  'prospectus.footer.onlineMSWTypes.mezzoSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.mezzoSocialWork',
    defaultMessage: 'Mezzo Social Work',
    description: 'The label for the edx online MSW program link of Mezzo Social Work',
  },
  'prospectus.footer.onlineMSWTypes.microSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.microSocialWork',
    defaultMessage: 'Micro Social Work',
    description: 'The label for the edx online MSW program link of Micro Social Work',
  },
  'prospectus.footer.onlineMSWTypes.internationalSocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.internationalSocialWork',
    defaultMessage: 'International Social Work',
    description: 'The label for the edx online MSW program link of International Social Work',
  },
  'prospectus.footer.onlineMSWTypes.policySocialWork': {
    id: 'prospectus.footer.onlineMSWTypes.policySocialWork',
    defaultMessage: 'Policy Social Work',
    description: 'The label for the edx online MSW program link of Policy Social Work',
  },
  'prospectus.footer.onlineMSWTypes.moreMSWTYpes': {
    id: 'prospectus.footer.onlineMSWTypes.moreMSWTYpes',
    defaultMessage: 'more MSW Types',
    description: 'The label for the edx online MSW program link of more MSW Types',
  },

  'prospectus.footer.onlineMSWTheories.socialLearningTheory': {
    id: 'prospectus.footer.onlineMSWTheories.socialLearningTheory',
    defaultMessage: 'Social Learning Theory',
    description: 'The label for the edx online MSW program link of Social Learning Theory',
  },
  'prospectus.footer.onlineMSWTheories.socialExchangeTheory': {
    id: 'prospectus.footer.onlineMSWTheories.socialExchangeTheory',
    defaultMessage: 'Social Exchange Theory',
    description: 'The label for the edx online MSW program link of Social Exchange Theory',
  },
  'prospectus.footer.onlineMSWTheories.psychodynamicTheory': {
    id: 'prospectus.footer.onlineMSWTheories.psychodynamicTheory',
    defaultMessage: 'Psychodynamic Theory',
    description: 'The label for the edx online MSW program link of Psychodynamic Theory',
  },
  'prospectus.footer.onlineMSWTheories.systemsTheory': {
    id: 'prospectus.footer.onlineMSWTheories.systemsTheory',
    defaultMessage: 'Systems Theory',
    description: 'The label for the edx online MSW program link of Systems Theory',
  },
  'prospectus.footer.onlineMSWTheories.rationalChoiceTheory': {
    id: 'prospectus.footer.onlineMSWTheories.rationalChoiceTheory',
    defaultMessage: 'Rational Choice Theory',
    description: 'The label for the edx online MSW program link of Rational Choice Theory',
  },
  'prospectus.footer.onlineMSWTheories.psychosocialDevelopmentTheory': {
    id: 'prospectus.footer.onlineMSWTheories.psychosocialDevelopmentTheory',
    defaultMessage: 'Psychosocial Development Theory',
    description: 'The label for the edx online MSW program link of Psychosocial Development Theory',
  },
  'prospectus.footer.onlineMSWTheories.seeMoreMSWTheories': {
    id: 'prospectus.footer.onlineMSWTheories.seeMoreMSWTheories',
    defaultMessage: 'see more MSW Theories',
    description: 'The label for the edx online MSW program link of see more MSW Theories',
  },

  'prospectus.footer.onlineMSWLocations.california': {
    id: 'prospectus.footer.onlineMSWLocations.california',
    defaultMessage: 'MSW in California',
    description: 'The label for the edx online MSW program link of California',
  },
  'prospectus.footer.onlineMSWLocations.massachusetts': {
    id: 'prospectus.footer.onlineMSWLocations.massachusetts',
    defaultMessage: 'MSW in Massachusetts',
    description: 'The label for the edx online MSW program link of Massachusetts',
  },
  'prospectus.footer.onlineMSWLocations.michigan': {
    id: 'prospectus.footer.onlineMSWLocations.michigan',
    defaultMessage: 'MSW in Michigan',
    description: 'The label for the edx online MSW program link of Michigan',
  },
  'prospectus.footer.onlineMSWLocations.chicago': {
    id: 'prospectus.footer.onlineMSWLocations.chicago',
    defaultMessage: 'MSW in Chicago',
    description: 'The label for the edx online MSW program link of Chicago',
  },
  'prospectus.footer.onlineMSWLocations.northCarolina': {
    id: 'prospectus.footer.onlineMSWLocations.northCarolina',
    defaultMessage: 'MSW in North Carolina',
    description: 'The label for the edx online MSW program link of North Carolina',
  },
  'prospectus.footer.onlineMSWLocations.newYork': {
    id: 'prospectus.footer.onlineMSWLocations.newYork',
    defaultMessage: 'MSW in New York',
    description: 'The label for the edx online MSW program link of New York',
  },
  'prospectus.footer.onlineMSWLocations.florida': {
    id: 'prospectus.footer.onlineMSWLocations.florida',
    defaultMessage: 'MSW in Florida',
    description: 'The label for the edx online MSW program link of Florida',
  },
  'prospectus.footer.onlineMSWLocations.texas': {
    id: 'prospectus.footer.onlineMSWLocations.texas',
    defaultMessage: 'MSW in Texas',
    description: 'The label for the edx online MSW program link of Texas',
  },
  'prospectus.footer.onlineMSWLocations.colorado': {
    id: 'prospectus.footer.onlineMSWLocations.colorado',
    defaultMessage: 'MSW in Colorado',
    description: 'The label for the edx online MSW program link of Colorado',
  },
  'prospectus.footer.onlineMSWLocations.oregon': {
    id: 'prospectus.footer.onlineMSWLocations.oregon',
    defaultMessage: 'MSW in Oregon',
    description: 'The label for the edx online MSW program link of Oregon',
  },
  'prospectus.footer.onlineMSWLocations.seeMoreMSWLocations': {
    id: 'prospectus.footer.onlineMSWLocations.seeMoreMSWLocations',
    defaultMessage: 'see more MSW Locations',
    description: 'The label for the edx online MSW program link of see more MSW Locations',
  },

};

export const getFooterLinks = ({
  messages, intl, enterpriseMarketingLinkData, toggleConsentModal,
}) => {
  const socialLinks = [
    {
      title: 'Facebook',
      url: 'https://www.facebook.com/EdxOnline',
      icon: <Icon src={Facebook} className="text-white" />,
      screenReaderText: intl.formatMessage(messages['prospectus.footer.socialLinks.srText.facebook']),
    },
    {
      title: 'Twitter',
      url: 'https://twitter.com/edXOnline',
      icon: <Icon src={BsTwitter} className="text-white" />,
      screenReaderText: intl.formatMessage(messages['prospectus.footer.socialLinks.srText.twitter']),
    },
    {
      title: 'LinkedIn',
      url: 'https://www.linkedin.com/company/edx',
      icon: <Icon src={BsLinkedin} className="text-white" size="lg" />,
      screenReaderText: intl.formatMessage(messages['prospectus.footer.socialLinks.srText.linkedin']),
    },
    {
      title: 'Reddit',
      url: 'https://www.reddit.com/r/edx',
      icon: <Icon src={Reddit} className="text-white" size="lg" />,
      screenReaderText: intl.formatMessage(messages['prospectus.footer.socialLinks.srText.reddit']),
    },
  ];

  const edXLinks = [
    {
      title: intl.formatMessage(messages['prospectus.footer.edxLinks.bootcamps']),
      url: withI18nPrefix('/boot-camps', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.edxLinks.about']),
      url: withI18nPrefix('/about-us', intl.locale),
    },
    { ...enterpriseMarketingLinkData },
    {
      title: intl.formatMessage(messages['prospectus.footer.edxLinks.affiliates']),
      url: withI18nPrefix('/affiliate-program', intl.locale),
      hideForES: false,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.edxLinks.openEdx']),
      url: 'http://open.edx.org',
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.edxLinks.advisory']),
      url: 'http://www.edx.org/advisory-groups',
      hideForES: false,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.edxLinks.careers']),
      url: withI18nPrefix('/careers', intl.locale),
      hideForES: false,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.edxLinks.news']),
      url: `${intl.locale === 'es' ? 'https://press.edx.org/es' : 'https://press.edx.org'}`,
    },
  ];

  const legalLinks = [
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.termsOfService']),
      url: withI18nPrefix('/edx-terms-service', intl.locale),
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.privacyPolicy']),
      url: withI18nPrefix('/edx-privacy-policy', intl.locale),
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.cookiePolicy']),
      url: withI18nPrefix('/edx-privacy-policy/cookies', intl.locale),
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.a11yPolicy']),
      url: withI18nPrefix('/accessibility', intl.locale),
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.trademarkPolicy']),
      url: withI18nPrefix('/trademarks', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.modernSlaveryStatement']),
      url: withI18nPrefix('/modern-slavery-statement', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.sitemap']),
      url: withI18nPrefix('/sitemap', intl.locale),
      hideForES: false,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.legalLinks.doNotSellData']),
      onClick: () => {
        toggleConsentModal();
      },
      url: '#',
    },
  ];

  const connectLinks = [
    {
      title: intl.formatMessage(messages['prospectus.footer.connectLinks.ideaHub']),
      url: withI18nPrefix('/resources', intl.locale),
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.connectLinks.contact']),
      url: 'https://courses.edx.org/support/contact_us',
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.connectLinks.help']),
      url: 'https://support.edx.org',
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.connectLinks.security']),
      url: withI18nPrefix('/policy/security', intl.locale),
      hideForES: false,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.connectLinks.mediaKit']),
      url: withI18nPrefix('/media-kit', intl.locale),
      hideForES: false,
    },
  ];

  const learnCourses = [
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.spanish']),
      url: withI18nPrefix('/learn/spanish', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.python']),
      url: withI18nPrefix('/learn/python', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.excel']),
      url: withI18nPrefix('/learn/excel', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.softwareEngineering']),
      url: withI18nPrefix('/learn/software-engineering', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.blockchain']),
      url: withI18nPrefix('/learn/blockchain', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.computerProgramming']),
      url: withI18nPrefix('/learn/computer-programming', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.economics']),
      url: withI18nPrefix('/learn/economics', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.architecture']),
      url: withI18nPrefix('/learn/architecture', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.projectManagement']),
      url: withI18nPrefix('/learn/project-management', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.learnCourses.businessAdministration']),
      url: withI18nPrefix('/learn/business-administration', intl.locale),
      hideForES: true,
    },
  ];

  const bootCampCourses = [
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.coding']),
      url: withI18nPrefix('/boot-camps/coding', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.dataAnalytics']),
      url: withI18nPrefix('/boot-camps/data-analytics', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.cybersecurity']),
      url: withI18nPrefix('/boot-camps/cybersecurity', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.fintech']),
      url: withI18nPrefix('/boot-camps/fintech', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.productManagement']),
      url: withI18nPrefix('/boot-camps/product-management', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.digitalMarketing']),
      url: withI18nPrefix('/boot-camps/digital-marketing', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.uxui']),
      url: withI18nPrefix('/boot-camps/ux-ui-user-experience', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bootcampCourses.technologyProjectManagement']),
      url: withI18nPrefix('/boot-camps/tech-project-management', intl.locale),
      hideForES: true,
    },
  ];

  const becomeGuides = [
    {
      title: intl.formatMessage(messages['prospectus.footer.becomeGuides.cybersecurityAnalyst']),
      url: withI18nPrefix('/become/how-to-become-a-cybersecurity-analyst', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.becomeGuides.dataScientist']),
      url: withI18nPrefix('/become/how-to-become-a-data-scientist', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.becomeGuides.socialMediaManager']),
      url: withI18nPrefix('/become/how-to-become-a-social-media-manager ', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.becomeGuides.softwareDeveloper']),
      url: withI18nPrefix('/become/how-to-become-a-software-developer', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.becomeGuides.softwareEngineer']),
      url: withI18nPrefix('/become/how-to-become-a-software-engineer-without-a-degree', intl.locale),
      hideForES: true,
    },
  ];

  const bachelorsCourses = [
    {
      title: intl.formatMessage(messages['prospectus.footer.bachelorsCourses.business']),
      url: withI18nPrefix('/bachelors/business', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bachelorsCourses.computerScience']),
      url: withI18nPrefix('/bachelors/computer-data-sciences', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.bachelorsCourses.health']),
      url: withI18nPrefix('/bachelors/health-social-services', intl.locale),
      hideForES: true,
    },
  ];

  const masterCourses = [
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.businessAdministration']),
      url: withI18nPrefix('/masters/online-mba', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.publicHealth']),
      url: withI18nPrefix('/masters/masters-in-public-health-online', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.socialWork']),
      url: withI18nPrefix('/masters/online-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.nursing']),
      url: withI18nPrefix('/masters/online-msn-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.dataScience']),
      url: withI18nPrefix('/masters/online-masters-in-data-science', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.engineering']),
      url: withI18nPrefix('/masters/online-masters-in-engineering', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.speechPathology']),
      url: withI18nPrefix('/masters/online-slp-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.counseling']),
      url: withI18nPrefix('/masters/counseling-psychology', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.healthcare']),
      url: withI18nPrefix('/masters/healthcare', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.graduate.education']),
      url: withI18nPrefix('/masters/masters-in-education-online', intl.locale),
      hideForES: true,
    },
  ];

  const onlineMSWPrograms = [
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.accreditedOnlineMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/accredited-online-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.fastTrackMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/fast-track-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.affordableMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/affordable-online-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.advancedStandingMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/advanced-standing-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.oneYearMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/one-year-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.acceleratedMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/accelerated-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.onlineMSWProgramsWithNoGRE']),
      url: withI18nPrefix('/masters/social-work/online-msw-programs-no-gre', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.partTimeMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/part-time-msw-programs', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWPrograms.hbcuOnlineMSWPrograms']),
      url: withI18nPrefix('/masters/social-work/hbcu-online-msw-programs', intl.locale),
      hideForES: true,
    },
  ];

  const onlineMSWTypes = [
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.clinicalSocialWork']),
      url: withI18nPrefix('/masters/social-work/clinical-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.medicalSocialWork']),
      url: withI18nPrefix('/masters/social-work/medical-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.macroSocialWork']),
      url: withI18nPrefix('/masters/social-work/macro-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.mentalHealthSocialWork']),
      url: withI18nPrefix('/masters/social-work/mental-health-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.hospiceSocialWork']),
      url: withI18nPrefix('/masters/social-work/hospice-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.psychiatricSocialWork']),
      url: withI18nPrefix('/masters/social-work/psychiatric-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.mezzoSocialWork']),
      url: withI18nPrefix('/masters/social-work/mezzo-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.microSocialWork']),
      url: withI18nPrefix('/masters/social-work/micro-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.internationalSocialWork']),
      url: withI18nPrefix('/masters/social-work/international-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTypes.policySocialWork']),
      url: withI18nPrefix('/masters/social-work/policy-social-work', intl.locale),
      hideForES: true,
    },
  ];
  const onlineMSWTheories = [
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTheories.socialLearningTheory']),
      url: withI18nPrefix('/masters/social-work/social-learning-theory', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTheories.socialExchangeTheory']),
      url: withI18nPrefix('/masters/social-work/social-exchange-theory', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTheories.psychodynamicTheory']),
      url: withI18nPrefix('/masters/social-work/psychodynamic-theory', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTheories.systemsTheory']),
      url: withI18nPrefix('/masters/social-work/systems-theory-social-work', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTheories.rationalChoiceTheory']),
      url: withI18nPrefix('/masters/social-work/rational-choice-theory', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWTheories.psychosocialDevelopmentTheory']),
      url: withI18nPrefix('/masters/social-work/psychosocial-development-theory', intl.locale),
      hideForES: true,
    },
  ];
  const onlineMSWLocations = [
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.california']),
      url: withI18nPrefix('/masters/social-work/msw-programs-california', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.massachusetts']),
      url: withI18nPrefix('/masters/social-work/msw-programs-massachusetts', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.michigan']),
      url: withI18nPrefix('/masters/social-work/msw-programs-michigan', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.chicago']),
      url: withI18nPrefix('/masters/social-work/msw-programs-chicago', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.northCarolina']),
      url: withI18nPrefix('/masters/social-work/msw-programs-north-carolina', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.newYork']),
      url: withI18nPrefix('/masters/social-work/msw-programs-new-york', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.florida']),
      url: withI18nPrefix('/masters/social-work/msw-programs-florida', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.texas']),
      url: withI18nPrefix('/masters/social-work/msw-programs-texas', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.colorado']),
      url: withI18nPrefix('/masters/social-work/msw-programs-colorado', intl.locale),
      hideForES: true,
    },
    {
      title: intl.formatMessage(messages['prospectus.footer.onlineMSWLocations.oregon']),
      url: withI18nPrefix('/masters/social-work/msw-programs-oregon', intl.locale),
      hideForES: true,
    },
  ];

  return {
    socialLinks,
    edXLinks,
    legalLinks,
    connectLinks,
    learnCourses,
    bootCampCourses,
    becomeGuides,
    bachelorsCourses,
    masterCourses,
    onlineMSWPrograms,
    onlineMSWTypes,
    onlineMSWTheories,
    onlineMSWLocations,
  };
};

export const formatUrl = (linkData) => {
  const {
    queryParams,
    url,
  } = linkData;
  const urlSearchParams = new URLSearchParams(queryParams);
  return urlSearchParams.length > 0 ? `${url}/?${urlSearchParams.toString()}` : url;
};

export const renderLinkList = ({
  title, linkList, isSeoLinks = false, topMargin = false,
}) => {
  const linkClick = onClick => {
    if (typeof onClick !== 'function') {
      // do not attach event if we have no onClick
      return undefined;
    }

    return (event) => {
      event.preventDefault();
      onClick();
    };
  };
  if (linkList.length === 0) {
    return null;
  }

  let margin = 'mb-4';

  if (topMargin) {
    margin = 'mb-4 mt-5';
  }

  if (isSeoLinks) {
    return (
      <>
        <h3 className={`${margin} section-title text-uppercase text-roboto-mono font-weight-normal text-left`}>{title}</h3>
        <ul className="list-unstyled p-0 mt-4">
          {linkList.map(link => (
            <li className="mb-2" key={link.title}>
              <a
                className="footer-seo-link"
                href={formatUrl(link)}
                target="_blank"
                rel="noreferrer"
                onClick={linkClick(link.onClick)}
              >{link.title}
              </a>
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <>
      <h2 className="mb-4 section-title text-left">{title}</h2>
      <ul className="list-unstyled p-0 mt-4">
        {linkList.map(link => (
          <li className="mb-2" key={link.title}>
            <a
              className="footer-link"
              href={formatUrl(link)}
              onClick={linkClick(link.onClick)}
            >{link.title}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};
