import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@edx/paragon';
import track from 'react-tracking';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import { highlightMatch } from '@utils/search';
import { appendProgramToProgramType } from '@utils/edxProductDisplay';
import { submitEvent } from '@utils/analytics';
import CloudflareImage from '@prospectus/common/ui/CloudflareImage';

function AutoCompleteProductTitle({ highlightResult, title }) {
  if (highlightResult) {
    return (
      <div
        className="suggestion-title mr-2"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: highlightMatch(highlightResult, title) }}
      />
    );
  }

  return (
    <div className="suggestion-title mr-2">{title}</div>
  );
}

AutoCompleteProductTitle.propTypes = {
  highlightResult: PropTypes.shape({
    partner: PropTypes.arrayOf(PropTypes.shape()),
    partner_keys: PropTypes.arrayOf(PropTypes.shape()),
    primary_description: PropTypes.shape(),
    secondary_description: PropTypes.shape(),
    tertiary_description: PropTypes.shape(),
    title: PropTypes.shape(),
  }),
  title: PropTypes.string.isRequired,
};

AutoCompleteProductTitle.defaultProps = {
  highlightResult: null,
};

function AutoCompleteDropdownItem({
  activeOption,
  highlightResult,
  indexName,
  itemID,
  link,
  objectID,
  organizationShortCodeOverride,
  owners,
  programType,
  position,
  product,
  queryID,
  title,
  uuid,
  productCategoryLabel,
  clickedEventName,
  tracking,
  isPreQuery,
  image,
  cardImageUrl,
}) {
  const CARD_HEADER_IMAGE_LIGHT = 'images/product_pages/default-card-header-light.png';
  const imageSrc = image?.src || cardImageUrl || CARD_HEADER_IMAGE_LIGHT;
  const suggestionImageParams = {
    width: 'auto', height: 'auto', quality: 75, format: 'webp',
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex="0"
      className={`list-inline-item suggestion px-3 py-2 mx-0 ${itemID === activeOption ? 'selected' : ''}`}
      id={itemID}
      role="option"
      aria-selected={activeOption === itemID}
      onClick={() => submitEvent(tracking, {}, clickedEventName)}
    >
      <ProspectusLink
        href={link}
        className="text-decoration-none"
        analytics={{
          eventName: 'edx.bi.user.autoComplete.suggestion.click',
          linkProps: {
            indexName,
            objectID,
            product,
            position,
            queryID,
            uuid,
          },
        }}
      >
        <div className="d-flex flex-column">
          {isPreQuery ? (
            <div className="suggestion-title d-flex align-items-center justify-content-start">
              <CloudflareImage
                title=""
                url={imageSrc}
                className="autocomplete-image mr-2"
                additionalParams={suggestionImageParams}
              />
              <div className="d-flex flex-column">
                <div>{title}</div>
                <div className="suggestion-owners">
                  {owners.map(({ key: ownerKey }, index) => (
                    <span key={ownerKey}>
                      {index === 0 && organizationShortCodeOverride ? organizationShortCodeOverride : ownerKey}
                    </span>
                  ))} | {productCategoryLabel}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center flex-wrap justify-content-start">
                <AutoCompleteProductTitle highlightResult={highlightResult} title={title} />
                {owners.map(({ key: ownerKey }, index) => (
                  <Badge variant="light" key={ownerKey} className="mr-1">
                    {index === 0 && organizationShortCodeOverride ? organizationShortCodeOverride : ownerKey}
                  </Badge>
                ))}
                <Badge variant="light" key={productCategoryLabel} className="mr-1 badge-lob">
                  {productCategoryLabel}
                </Badge>
              </div>
              {programType && programType.map(type => (
                <div className="suggestion-type small" key={type}>{appendProgramToProgramType(type)}</div>
              ))}
            </>
          )}
        </div>
      </ProspectusLink>
    </li>
  );
}

AutoCompleteDropdownItem.propTypes = {
  activeOption: PropTypes.string.isRequired,
  highlightResult: PropTypes.shape({
    partner: PropTypes.arrayOf(PropTypes.shape()),
    partner_keys: PropTypes.arrayOf(PropTypes.shape()),
    primary_description: PropTypes.shape(),
    secondary_description: PropTypes.shape(),
    tertiary_description: PropTypes.shape(),
    title: PropTypes.shape(),
  }),
  indexName: PropTypes.string.isRequired,
  itemID: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    isExternalLink: PropTypes.bool,
  }).isRequired,
  tracking: PropTypes.shape().isRequired,
  objectID: PropTypes.string,
  organizationShortCodeOverride: PropTypes.string,
  owners: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
  })).isRequired,
  position: PropTypes.number.isRequired,
  product: PropTypes.string.isRequired,
  programType: PropTypes.arrayOf(PropTypes.string),
  queryID: PropTypes.string,
  title: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  productCategoryLabel: PropTypes.string.isRequired,
  clickedEventName: PropTypes.string.isRequired,
  isPreQuery: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string,
  }),
  cardImageUrl: PropTypes.string,
};

AutoCompleteDropdownItem.defaultProps = {
  highlightResult: null,
  objectID: '',
  organizationShortCodeOverride: '',
  programType: [],
  queryID: '',
  isPreQuery: false,
  image: null,
  cardImageUrl: '',
};

export default track({
  component: 'inner-autocomplete',
})(AutoCompleteDropdownItem);
