import React, { useEffect, useRef } from 'react';
import track from 'react-tracking';
import { Button } from '@edx/paragon';
import { KeyboardArrowDown, KeyboardArrowUp } from '@edx/paragon/icons';
import { useIntl } from 'gatsby-plugin-react-intl';

import {
  CLOSE_MEGA_NAV,
  OPEN_MEGA_NAV,
  TOGGLE_MEGA_NAV,
} from '@prospectus/common/ui-strict/MegaNav/data/constants';
import { useMegaNav, useMegaNavDispatch } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import { emitEventIfAble } from '@utils/analytics';
import isUserLoggedIn from '@utils/user';

import messages from './messages';

type LearnMenuButtonProps = {
  pageType?: 'default'; // can add to this as we implement more variations of the button
  tracking: object;
};

function LearnMenuButton({ pageType, tracking }: LearnMenuButtonProps) {
  const intl = useIntl();

  const { isOpen } = useMegaNav();
  const dispatch = useMegaNavDispatch();
  const lastEventRef = useRef(null);

  const handleClick = e => {
    e.stopPropagation();
    const eventName = isOpen ? 'close-menu' : 'open-menu';
    lastEventRef.current = 'click';
    emitEventIfAble(`edx.bi.mega-nav.${eventName}`, { ...tracking, loggedIn: isUserLoggedIn(), pageType });
    dispatch({ type: TOGGLE_MEGA_NAV });
  };

  const handleOpen = e => {
    e.stopPropagation();
    if (!isOpen && lastEventRef.current !== 'click') {
      emitEventIfAble('edx.bi.mega-nav.open-menu', { ...tracking, loggedIn: isUserLoggedIn(), pageType });
      dispatch({ type: OPEN_MEGA_NAV });
    }
  };

  const handleMouseLeave = e => {
    e.stopPropagation();
    lastEventRef.current = null;
  };

  useEffect(() => {
    const handleEsc = e => {
      if (e.key === 'Escape') {
        dispatch({ type: CLOSE_MEGA_NAV });
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <Button
      variant="primary"
      onClick={handleClick}
      // @todo figure out how to make this work without trapping the focus on ESC
      // onFocus={handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleMouseLeave}
      iconAfter={isOpen ? KeyboardArrowUp : KeyboardArrowDown}
      className="learn-menu-button"
    >
      {intl.formatMessage(messages[`prospectus.mega-nav.learn-menu-button.${pageType}`])}
    </Button>
  );
}

LearnMenuButton.defaultProps = {
  pageType: 'default',
};

export default track({
  component: 'mega-nav-learn-menu-button',
})(LearnMenuButton);
