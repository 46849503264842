/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* Short-term fix */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import breakpoints from '@edx/paragon/dist/utils/breakpoints';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
} from 'gatsby-plugin-react-intl';
import { sortSubjects } from '@utils/sorting';
import useOptimizelyIsInitialized from '@prospectus/common/hooks/useOptimizelyIsInitialized';

import { intlShape } from '@prospectus/common/types/intlShape';
import { getLmsLink, getEnterpriseMarketingLinkData } from '@utils/edxLinks';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import { SiteNavContext } from '@utils/context';
import {
  useMegaNav,
  useMegaNavDispatch,
} from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import {
  TOGGLE_MEGA_NAV,
  CLEAR_ACTIVE_CATEGORY,
} from '@prospectus/common/ui-strict/MegaNav/data/constants';
import SearchBar from '../SearchBar';
import CoursesSubmenu from './components/CoursesSubmenu';
import ProgramsSubmenu from './components/ProgramsSubmenu';
import MobileAccountLinks from '../mobile-navigation/MobileAccountLinks';
import MobileMenuButton from '../mobile-navigation/MobileMenuButton';
import MobileProductLinks from '../mobile-navigation/MobileProductLinks';
import MobileSearchButton from '../mobile-navigation/MobileSearchButton';
import MobileUserActionLinks from '../mobile-navigation/MobileUserActionLinks';
import BootCampSubmenu from './components/BootCampSubmenu';
import MobileBootCampLinks from '../mobile-navigation/MobileBootCampLinks';

function ForwardFocusToLastMenuElement({ focusLastMenuElement }) {
  return (
    <span onFocus={focusLastMenuElement} tabIndex={0} aria-hidden="true" />
  );
}

ForwardFocusToLastMenuElement.propTypes = {
  focusLastMenuElement: PropTypes.func.isRequired,
};

function ForwardFocusCloseButton({ focusCloseButton }) {
  return <span onFocus={focusCloseButton} tabIndex={0} aria-hidden="true" />;
}

ForwardFocusCloseButton.propTypes = {
  focusCloseButton: PropTypes.func.isRequired,
};

function SiteNav({
  location,
  userDetails,
  intl,
  hideNavLinks,
  userLoggedIn,
  showMegaNav,
  data: {
    allSubject: { edges: subjectList },
  },
}) {
  const {
    setMobileNavIsOpen: setMobileNavIsOpenContext,
    setMobileSearchbarOpen: setMobileSearchbarOpenContext,
    mobileSearchbarIsOpen: mobileSearchbarIsOpenContext,
  } = useContext(SiteNavContext);

  const dispatch = useMegaNavDispatch();
  const { isOpen } = useMegaNav();

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [mobileSearchbarIsOpen, setMobileSearchbarIsOpen] = useState(
    !!mobileSearchbarIsOpenContext,
  );
  const orderedSubjectList = sortSubjects(subjectList, intl.locale);
  const { isLoading: optimizelyIsLoading } = useOptimizelyIsInitialized();

  // Ref used for first focus.
  const menuButtonRef = React.createRef();

  // Refs used for last focus.
  const edxForBusiness = React.createRef();
  const userDetailsLinksCollapsibleTriggerRef = React.createRef();

  // Refs for closing accordions when opening other.
  const mobileProductLinksCollapsibleRef = React.createRef();
  const mobileBootCampLinksCollapsibleRef = React.createRef();
  const userDetailsLinksCollapsibleRef = React.createRef();

  const enterpriseMarketingLinkData = getEnterpriseMarketingLinkData({
    locale: intl.locale,
    utmMedium: process.env.GATSBY_ENTERPRISE_MARKETING_HEADER_UTM_MEDIUM,
    includeUrlWithQueryParams: true,
  });

  const messages = defineMessages({
    'prospectus.header.site_header.site_nav.main': {
      id: 'prospectus.header.site_header.site_nav.main',
      defaultMessage: 'Main',
      description: 'Screenreader description for the main menu navigation.',
    },
    'prospectus.header.searchbar.placeholder.mobile': {
      id: 'prospectus.header.searchbar.placeholder.mobile',
      defaultMessage: 'Search online courses',
      description: 'The placeholder text for the nav search bar on mobile',
    },
    'prospectus.header.site_header.site_nav.mobile.courses_heading': {
      id: 'prospectus.header.site_header.site_nav.mobile.courses_heading',
      defaultMessage: 'All Courses & Programs',
      description:
        'Heading for the courses and programs menu section of the mobile site nav',
    },
  });

  useEffect(() => {
    // SpeedCurve render tracking
    if (
      'performance' in window
      && Object.prototype.hasOwnProperty.call(performance, 'mark')
      && performance.mark !== undefined
    ) {
      performance.mark('site main navigation rendered');
    }
  }, []);

  useEffect(() => {
    if (mobileMenuIsOpen || mobileSearchbarIsOpen) {
      document.body.classList.add('lock-scroll');
    } else {
      document.body.classList.remove('lock-scroll');
    }
    setMobileNavIsOpenContext(mobileMenuIsOpen);
    if (mobileMenuIsOpen) {
      setMobileSearchbarIsOpen(false);
      window.analytics.track('edx.bi.site-nav.mobile-hamburger-menu.opened', {
        category: 'navigation',
      });
    }
  }, [mobileMenuIsOpen, mobileSearchbarIsOpen]);

  useEffect(() => {
    setMobileSearchbarOpenContext(mobileSearchbarIsOpen);
    if (mobileSearchbarIsOpen) {
      setMobileMenuIsOpen(false);
    }
  }, [mobileSearchbarIsOpen]);

  const onEscapeDown = useCallback(
    event => {
      const key = event.code || event.key;
      if (key === 'Escape') {
        if (mobileMenuIsOpen) {
          setMobileMenuIsOpen(false);
          document.querySelector('.mobile-menu-button').focus();
        }
      }
    },
    [mobileMenuIsOpen],
  );

  useEffect(() => {
    window.addEventListener('keydown', onEscapeDown, false);
    return () => {
      window.removeEventListener('keydown', onEscapeDown, false);
    };
  });

  const linkClick = (event, label = event.currentTarget.innerText) => {
    window.analytics.track('edx.bi.link.header_navigation.clicked', {
      category: 'navigation',
      label,
      link: event.currentTarget.href,
      mobile: false,
    });
  };

  const inverse = prev => !prev;

  const mobileMenuClick = () => {
    if (showMegaNav) {
      dispatch({ type: TOGGLE_MEGA_NAV });
      dispatch({ type: CLEAR_ACTIVE_CATEGORY });
    } else {
      setMobileMenuIsOpen(inverse);
    }
  };

  const mobileSearchbarClick = () => {
    setMobileSearchbarIsOpen(inverse);
  };

  const dashboardLinkClick = event => {
    linkClick(event, 'Dashboard');
  };

  const focusCloseButton = event => {
    event.preventDefault();
    menuButtonRef.current.focus();
  };

  const focusLastMenuElement = event => {
    event.preventDefault();
    if (userDetails) {
      userDetailsLinksCollapsibleTriggerRef.current.focus();
      return;
    }
    edxForBusiness.current.focus();
  };

  return (
    <div
      id="primary-menu-bar"
      className={classNames(
        'nav align-items-center justify-content-between',
        { 'd-none': optimizelyIsLoading }, // Remove when megaNav experiment is productized / removed
      )}
      key="site-nav-main"
    >

      <nav
        className="main-menu-nav disco-hack-main-menu-nav"
        aria-label={intl.formatMessage(
          messages['prospectus.header.site_header.site_nav.main'],
        )}
      >
        {mobileMenuIsOpen && (
          <ForwardFocusToLastMenuElement
            focusLastMenuElement={focusLastMenuElement}
          />
        )}
        <MobileMenuButton
          buttonRef={menuButtonRef}
          mobileMenuClick={mobileMenuClick}
          mobileMenuIsOpen={showMegaNav ? isOpen : mobileMenuIsOpen}
        />

        {mobileSearchbarIsOpen && (
          <div className="mobile-search-bar">
            <SearchBar
              placeholder={intl.formatMessage(
                messages['prospectus.header.searchbar.placeholder.mobile'],
              )}
              forceSearchIsActive
              idSuffix="mobile"
              maxWidth={breakpoints.medium.minWidth}
              location={location}
            />
          </div>
        )}

        <ul
          className={classNames(
            'menu',
            'primary-menu',
            'header-search',
            'clearfix',
            'list-group',
            'list-unstyled',
            { 'mobile-menu-show': mobileMenuIsOpen },
            { 'hide-nav-links': hideNavLinks },
          )}
        >
          <li
            className="js-focus-first sr-is-focusable sr-only"
            tabIndex="-1"
            key="menu-first-focus"
          />

          <MobileUserActionLinks
            userLoggedIn={userLoggedIn}
            dashboardLinkClick={dashboardLinkClick}
            dashboardLink={getLmsLink('/dashboard')}
            loginLink={getLmsLink('/login')}
            registerLink={getLmsLink('/register')}
          />

          {!showMegaNav ? (
            <CoursesSubmenu orderedSubjectList={orderedSubjectList} />
          ) : null}

          <li className="menu-link visible-mobile" key="menu-courses">
            <ProspectusLink
              href={intl.locale === 'es' ? 'es/search' : 'search'}
              className="btn btn-tertiary bg-white w-100 text-left px-4"
              analytics={{
                eventName: 'edx.bi.link.site-nav.courses-all.clicked',
              }}
            >
              {intl.formatMessage(
                messages[
                  'prospectus.header.site_header.site_nav.mobile.courses_heading'
                ],
              )}
            </ProspectusLink>
          </li>

          {!showMegaNav ? <ProgramsSubmenu /> : null}

          {!showMegaNav && intl.locale === 'en' && <BootCampSubmenu />}

          {intl.locale === 'en' && (
            <li className="menu-link visible-mobile" key="menu-programs-mobile">
              <MobileProductLinks
                collapsibleRef={mobileProductLinksCollapsibleRef}
                onCollapsibleOpen={() => {
                  window.analytics.track(
                    'edx.bi.site-nav.mobile-program-links.expanded',
                    { category: 'navigation' },
                  );
                  if (userDetails) {
                    userDetailsLinksCollapsibleRef.current.close();
                  }
                }}
              />
            </li>
          )}

          {intl.locale === 'en' && (
            <li
              className="menu-link visible-mobile user-location-visiblity-hidden"
              key="menu-boot-camp-mobile"
            >
              <MobileBootCampLinks
                collapsibleRef={mobileBootCampLinksCollapsibleRef}
                onCollapsibleOpen={() => {
                  window.analytics.track(
                    'edx.bi.site-nav.mobile-bootcamp-links.expanded',
                    { category: 'navigation' },
                  );
                  if (userDetails) {
                    userDetailsLinksCollapsibleRef.current.close();
                  }
                }}
              />
            </li>
          )}

          <li
            className="menu-link visible-mobile"
            key="menu-schools-partners-mobile"
          >
            <ProspectusLink
              href="schools-partners"
              className="btn btn-tertiary bg-white w-100 text-left px-4"
              analytics={{
                eventName: 'edx.bi.link.site-nav.schools.clicked',
              }}
            >
              <FormattedMessage
                id="prospectus.header.site_header.site_nav.partners.partners_link_text"
                defaultMessage="Schools & Partners"
                description="Link text for the 'schools & partners' link in the site nav"
              />
            </ProspectusLink>
          </li>

          {!showMegaNav ? (
            <li
              className="menu-link visible-desktop"
              key="menu-schools-partners-desktop"
            >
              <ProspectusLink
                href="schools-partners?linked_from=sitenav"
                className="menu-item"
                analytics={{
                  eventName: 'edx.bi.link.site-nav.schools.clicked',
                }}
              >
                <FormattedMessage
                  id="prospectus.header.site_header.site_nav.partners.partners_link_text"
                  defaultMessage="Schools & Partners"
                  description="Link text for the 'schools & partners' link in the site nav"
                />
              </ProspectusLink>
            </li>
          ) : null}

          <li
            className="menu-link visible-mobile"
            key="menu-edx-for-business-mobile"
          >
            <ProspectusLink
              linkRef={edxForBusiness}
              href={enterpriseMarketingLinkData.urlWithQueryParams}
              className="btn btn-tertiary bg-white w-100 text-left px-4"
              analytics={{
                eventName: 'edx.bi.link.site-nav.business.clicked',
              }}
            >
              <FormattedMessage
                id="prospectus.header.site_header.site_nav.business.business_link_text"
                defaultMessage="edX For Business"
                description="Link text for the 'edX For Business' link in the site nav"
              />
            </ProspectusLink>
          </li>
          {userDetails && (
            <li className="menu-link visible-mobile" key="menu-account-mobile">
              <MobileAccountLinks
                profileLink={
                  userDetails && userDetails.header_urls
                    ? userDetails.header_urls.learner_profile
                    : ''
                }
                logoutLink={getLmsLink('/logout')}
                settingsLink={getLmsLink('/account/settings')}
                collapsibleRef={userDetailsLinksCollapsibleRef}
                onCollapsibleOpen={() => mobileProductLinksCollapsibleRef.current.close()}
                collapsibleTriggerRef={userDetailsLinksCollapsibleTriggerRef}
              />
            </li>
          )}
        </ul>
        {mobileMenuIsOpen && (
          <ForwardFocusCloseButton focusCloseButton={focusCloseButton} />
        )}
      </nav>

      {!mobileMenuIsOpen && (
        <MobileSearchButton
          mobileSearchbarIsOpen={mobileSearchbarIsOpen}
          onClick={mobileSearchbarClick}
        />
      )}

      <div
        className={classNames('menu-overlay-background', {
          'show-overlay': mobileMenuIsOpen || mobileSearchbarIsOpen,
        })}
        aria-hidden="true"
      />
    </div>
  );
}

SiteNav.propTypes = {
  userLoggedIn: PropTypes.bool,
  settings: PropTypes.shape({}),
  userDetails: PropTypes.oneOfType([
    PropTypes.shape({
      username: PropTypes.string,
      header_urls: PropTypes.shape({
        account_settings: PropTypes.string,
        learner_profile: PropTypes.string,
        logout: PropTypes.string,
        resume_block: PropTypes.string,
      }),
    }),
    PropTypes.bool,
  ]),
  data: PropTypes.shape({
    allSubject: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            uuid: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            subtitle: PropTypes.string,
            slug: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }),
  intl: intlShape.isRequired,
  hideNavLinks: PropTypes.bool,
  showMegaNav: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

SiteNav.defaultProps = {
  userLoggedIn: false,
  settings: {},
  userDetails: false,
  data: {},
  hideNavLinks: true,
  showMegaNav: true,
  location: {},
};
export default injectIntl(props => {
  const data = useStaticQuery(graphql`
    query {
      allSubject {
        edges {
          node {
            uuid
            name
            slug
            labels {
              en
              es
            }
          }
        }
      }
    }
  `);
  return <SiteNav data={data} {...props} />;
});
