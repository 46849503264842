import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
} from 'gatsby-plugin-react-intl';
import SearchIcon from '@svg/material-components/SearchIcon';
import CloseIcon from '@svg/material-components/CloseIcon';

function MobileSearchButton({
  onClick,
  mobileSearchbarIsOpen,
}) {
  if (mobileSearchbarIsOpen) {
    return (
      <button
        className="mobile-searchbar-btn icon-button small"
        type="button"
        onClick={onClick}
      >
        <span className="sr-only">
          <FormattedMessage
            id="prospectus.header.site_header.mobile_search.experiment.close-search-button.screen_reader_text"
            defaultMessage="Hide search bar"
            description="Screen-reader text for the button that hides the search bar in the menu for mobile users"
          />
        </span>
        <CloseIcon />
      </button>
    );
  }

  return (
    <button
      className="mobile-searchbar-btn search-icon icon-button small"
      type="button"
      onClick={onClick}
    >
      <span className="sr-only">
        <FormattedMessage
          id="prospectus.header.site_header.mobile_search.experiment.button.screen_reader_text"
          defaultMessage="Search for courses and programs"
          description="Screen-reader text for the search button in the menu for mobile users"
        />
      </span>
      <SearchIcon />
    </button>
  );
}

MobileSearchButton.propTypes = {
  mobileSearchbarIsOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MobileSearchButton;
