import { useState, useEffect } from 'react';

function useOptimizelyVariation(experimentName) {
  const [experimentVariation, setExperimentVariation] = useState(null);

  useEffect(() => {
    try {
    /* istanbul ignore next */
      const initExperiment = () => {
        if (window.experiments?.[experimentName]) {
        // Optimizely snippet has already been run
        // Now we just set the component state as per variation we got bucketed into
          setExperimentVariation(window.experiments?.[experimentName]?.variation);
        } else {
        // Optimizely snippet has not been run yet (or will never be run if adblockers prevent it)
        // We need to declare a callback function for it to run when it loads and buckets us
          window.experiments = window.experiments || {};
          window.experiments[experimentName] = {};
          window.experiments[experimentName].handleLoaded = () => {
          // If this function is called, we know its the Optimizely variation snippet that called it
          // Therefore variation field will then exist on the experiment object
            setExperimentVariation(window.experiments?.[experimentName]?.variation);
          };
        }
      };
      initExperiment();
    } catch (error) {
    /* istanbul ignore next */
      console.error(error.message); // eslint-disable-line no-console
    }

    // This is required to fix an Optimizely A/B test experiment issue where navigating
    // via app (ie searching different topics, menu click) doesn't set the correct variation.
    // Setting the key on this component to the url forces an unmount when the url changes
    // which reactivates the Optimizely snippet check in order to set the correct variation.
    // This is not a problem when entering the url in the browser and reloading manually.
    return () => {
      window.experiments = {}; // reset experiments
      window.optimizely.push({ type: 'activate' }); // reactivate Optimizely
    };
  }, []);

  return experimentVariation;
}

export default useOptimizelyVariation;
