import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Icon } from '@edx/paragon';
import { CheckCircle } from '@edx/paragon/icons';

import LinkWithVisibilityTracking from '@prospectus/common/ui-strict/LinkWithVisibilityTracking';
import { useMegaNav } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import { formatRichText } from '@utils/formatting';
import isUserLoggedIn from '@utils/user';

import { MenuProductSummaryPropType } from './types';

const formatContent = (text, linkProps) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        // don't wrap divs in p tags since that's bad practice in react
        if (node.content.filter(child => child.nodeType === 'hyperlink').length > 0) {
          return children;
        }
        return (<p className="small mb-2">{children}</p>);
      },
      [BLOCKS.UL_LIST]: (node, children) => <ul className="list-unstyled text-primary-500 mb-.5">{children}</ul>,
      [BLOCKS.LIST_ITEM]: node => {
        // list items will be wrapped in p tag without this, which messes with the styles
        const untaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (unusedNode, children) => children,
            [BLOCKS.LIST_ITEM]: (unusedNode, children) => children,
          },
        });

        return (
          <li className="d-flex my-1 align-items-center small">
            <Icon src={CheckCircle} className="mr-2x mr-2 pr-.5" size="xs" />
            {untaggedChildren}
          </li>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkWithVisibilityTracking
          url={node.data.uri}
          className="link small mb-1"
          eventName="edx.bi.mega-nav.product-summary-link.clicked"
          additionalLinkProps={linkProps}
        >
          {children}
        </LinkWithVisibilityTracking>
      ),
    },
  };

  return formatRichText(text, options);
};

function MenuProductSummary({ title, text }: MenuProductSummaryPropType) {
  const { activeCategory } = useMegaNav();
  const linkProps = { category: activeCategory, loggedIn: isUserLoggedIn() };

  return (
    <div>
      <h4>{title}</h4>
      {formatContent(text?.raw, linkProps)}
    </div>
  );
}

export default MenuProductSummary;
