import React, { KeyboardEvent, MouseEvent } from 'react';
import track from 'react-tracking';
import { navigate } from 'gatsby';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import classNames from 'classnames';

import useAboveFold from '@prospectus/common/hooks/useAboveFold';
import { submitEvent } from '@utils/analytics';
import './styles.scss';

interface LinkWithVisibilityTrackingProps {
  url: string;
  className: string;
  eventName: string;
  additionalLinkProps?: Object;
  tracking: Object;
  children: ReactElement;
}

const formatUrl = (url: string) => {
  if (!url) {
    return '/';
  }

  const base = new URL('https://edx.org');
  // if URL is relative, then edx.org base will be used
  const newUrl = new URL(url, base);
  const newUrlHostname = newUrl.hostname.replace('www.', '');

  // check if URL is internal or edX
  if (newUrlHostname === base.hostname) {
    // add referrer parameter

    // Temporarily disabled as part of [WS-4465](https://2u-internal.atlassian.net/browse/WS-4465)
    // newUrl.searchParams.set('referredFrom', 'edxNavigation');

    // return relative URL + search params
    // we could instead return newUrl.href, but then in localhost
    // it would link to edx.org
    return newUrl.pathname + newUrl.search;
  }

  return newUrl.href;
};

function LinkWithVisibilityTracking({
  url,
  className,
  eventName,
  additionalLinkProps,
  tracking,
  children,
}: LinkWithVisibilityTrackingProps) {
  const [isAboveFold, linkRef] = useAboveFold();
  const formattedUrl = formatUrl(url);

  // isAboveFold only resolves to a boolean after first render
  // it's difficult to bind an event to a link via segment's trackLink method after first render
  // and the event's properties cannot be updated, the event cannot be overwritten
  // so I'm writing this custom event handler that can track the up-to-date value of isAboveFold
  const clickHandler = async (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    const linkProps = {
      ...additionalLinkProps,
      visible_on_load: isAboveFold,
    };
    submitEvent(tracking, linkProps, eventName);
    // setting a timeout of 300ms to give the track call time to finish before the page redirects
    // see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link
    setTimeout(() => {
      navigate(formattedUrl);
    }, 300);

    e.preventDefault();
  };

  const onKeyDown = (e: KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      clickHandler(e);
    }
  };

  return (
    <a
      ref={linkRef}
      className={classNames('link-with-visibility d-block', { [className]: className })}
      onClick={clickHandler}
      onKeyDown={onKeyDown}
      href={formattedUrl}
    >
      {children}
    </a>
  );
}

LinkWithVisibilityTracking.defaultProps = {
  additionalLinkProps: {},
};

export default track(() => ({
  component: 'link-with-visibility-tracking',
}))(LinkWithVisibilityTracking);
