import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@edx/paragon';
import { TrendingUp } from '@edx/paragon/icons';
import { injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import { getIntlSearchPath, SEARCH_PATH } from '@utils/search';

function PopularSearchItem({
  activeOption,
  intl,
  itemID,
  searchTerm,
}) {
  const searchTermFormatted = searchTerm.replace(' ', '+');
  const pathWithSearchTerm = `${getIntlSearchPath(SEARCH_PATH, intl.locale)}?q=${searchTermFormatted}`;

  return (
    <li
      tabIndex="0"
      className="list-inline-item suggestion px-3 py-2 mx-0"
      id={itemID}
      role="option"
      aria-selected={activeOption === itemID}
    >
      <ProspectusLink
        href={pathWithSearchTerm}
        className="text-decoration-none"
        analytics={{ eventName: 'edx.bi.autocomplete.popular.clicked' }}
      >
        <div className="d-flex flex-column">
          <div className="suggestion-title d-flex align-items-center flex-wrap justify-content-start">
            <Icon src={TrendingUp} className="mr-2" />
            {searchTerm}
          </div>
        </div>
      </ProspectusLink>
    </li>
  );
}

PopularSearchItem.propTypes = {
  activeOption: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  itemID: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default injectIntl(PopularSearchItem);
