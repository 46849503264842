import { useState, useEffect } from 'react';

import breakpoints from '@edx/paragon/dist/utils/breakpoints';

type TypeBreakpoint = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | number

/**
 * A React hook used to determine if the current viewport width matches the max-width breakpoint provided.
 * These values may get outdated with Paragon updates:
 *
 * `extraSmall` - `576`
 *
 * `small` - `768`
 *
 * `medium` - `992`
 *
 * `large` - `1200`
 *
 * `extraLarge` - `1400`
 *
 * @param breakpoint - Paragon breakpoint string name or number.
 * Number should be used in edge cases only.
 * Defaults to `small`.
 *
 * Returns `true` if the viewport width is smaller than the provided breakpoint.
 */
const useBreakpointMatch = (breakpoint: TypeBreakpoint) => {
  const [matchesBreakpoint, setMatchesBreakpoint] = useState(false);
  const value = typeof breakpoint === 'number' ? breakpoint : breakpoints[breakpoint]?.maxWidth;

  const checkForBreakpoint = () => {
    setMatchesBreakpoint(window.matchMedia(`(max-width: ${value || breakpoints.small.maxWidth}px)`).matches);
  };

  useEffect(() => {
    checkForBreakpoint();
    window.addEventListener('resize', checkForBreakpoint);

    // return this function here to clean up the event listener
    return () => window.removeEventListener('resize', checkForBreakpoint);
  }, []);

  return matchesBreakpoint;
};

export default useBreakpointMatch;
