import { ProductType } from '../data/types';

export const sortProductsByUuidList = (products: ProductType[], uuidList: string[]) => {
  if (!products) {
    return null;
  }

  if (!uuidList) {
    return products;
  }

  const uuidIndexMap = new Map();
  uuidList.forEach((uuid, index) => {
    uuidIndexMap.set(uuid, index);
  });

  return products.slice().sort((a, b) => {
    const indexA = uuidIndexMap.get(a?.courseUuid || a?.programUuid);
    const indexB = uuidIndexMap.get(b?.courseUuid || b?.programUuid);
    return indexA - indexB;
  });
};
