import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  defineMessages,
  injectIntl,
} from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';
import MenuIcon from '@svg/material-components/MenuIcon';
import CloseIcon from '@svg/material-components/CloseIcon';

const messages = defineMessages({
  'prospectus.header.site_nav.mobile_menu_button.title': {
    id: 'prospectus.header.site_nav.mobile_menu_button.title',
    description: 'Screen reader text for the button title',
    defaultMessage: 'Main menu',
  },
  'prospectus.header.site_nav.mobile_menu_button.screen_reader_text': {
    id: 'prospectus.header.site_nav.mobile_menu_button.screen_reader_text',
    description: 'Screen reader text for the button that toggles the mobile menu',
    defaultMessage: 'Toggle mobile menu',
  },
});

function MobileMenuButton({
  mobileMenuClick,
  mobileMenuIsOpen,
  buttonRef,
  intl,
}) {
  return (
    <button
      className={classNames(
        'mobile-menu-button icon-button small p-0',
        { open: mobileMenuIsOpen },
      )}
      onClick={mobileMenuClick}
      aria-expanded={mobileMenuIsOpen}
      aria-label={intl.formatMessage(messages['prospectus.header.site_nav.mobile_menu_button.title'])}
      title={intl.formatMessage(messages['prospectus.header.site_nav.mobile_menu_button.title'])}
      aria-haspopup="true"
      type="button"
      ref={buttonRef}
    >
      {mobileMenuIsOpen ? <CloseIcon /> : <MenuIcon />}
      <span className="sr-only">
        {intl.formatMessage(messages['prospectus.header.site_nav.mobile_menu_button.screen_reader_text'])}
      </span>
    </button>
  );
}

MobileMenuButton.propTypes = {
  mobileMenuClick: PropTypes.func.isRequired,
  mobileMenuIsOpen: PropTypes.bool.isRequired,
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(MobileMenuButton);
