import './styles.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { Icon, Button } from '@edx/paragon';
import { Close } from '@edx/paragon/icons';
import { useIntl } from 'gatsby-plugin-react-intl';
import { messages } from '@prospectus/common/ui/xpert/experiments/ChatFloatingActionButton/Survey/constants';

const proactiveMessageStyle = {
  bottom: '4.5rem',
  right: '1.5rem',
};

const setCookie = (name, value, days) => {
  const domainInfo = {
    domain: process.env.GATSBY_COOKIE_ENV === 'localhost' ? 'localhost' : 'edx.org',
    expires: days,
    path: '/',
  };

  Cookies.set(name, value, domainInfo);
};

function ProactiveMessage(props) {
  const isSSR = typeof window === 'undefined';
  if (isSSR) {
    return null;
  }

  const [show, setShow] = useState(true);
  const intl = useIntl();

  // show if visitors opted into OneTrust functional cookies and cookie doesn't exist
  const showMessageBasedOnCookieRules = window?.OnetrustActiveGroups?.includes('C0003') && Cookies.get(props.cookieName) === undefined;

  useEffect(() => {
    // remove cookie if visitor opts out of OneTrust functional cookies after cookie is set
    window?.OneTrust?.OnConsentChanged(() => {
      if (!window?.OnetrustActiveGroups?.includes('C0003') && Cookies.get(props.cookieName) !== undefined) {
        Cookies.remove(props.cookieName);
      }
    });
  }, []);

  const handleClose = () => {
    setShow(false);
    // set cookie if visitor opts into OneTrust functional cookies after message is shown
    if (window?.OnetrustActiveGroups?.includes('C0003')) {
      setCookie(props.cookieName, 1, 1);
    }
    props.onClose();
  };

  // set cookie if visitor opts into OneTrust functional cookies if external
  // events hide message
  if (!props.isShown && window?.OnetrustActiveGroups?.includes('C0003')) {
    setCookie(props.cookieName, 1, 1);
  }

  return (
    <div>
      {(((show && props.isShown) && showMessageBasedOnCookieRules) || props.showMessage) && (
        <div className="proactive-message" style={props.style}>
          <div>
            <Button
              data-testid="proactive-message-button-close"
              className="proactive-message__close"
              onClick={handleClose}
              variant="custom"
              aria-label={intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.survey.buttons.close'])}
              type="button"
            >
              <Icon className="proactive-message__close-icon" src={Close} />
            </Button>
          </div>
          <Button
            data-testid="proactive-message-button"
            className="proactive-message__body-container"
            onClick={props.onClick}
            variant="custom"
            aria-label={intl.formatMessage(messages['prospectus.maas.painted.door.chatbot.proactive-message.message'])}
            type="button"
          >
            <div className="proactive-message__body shadow-sm">
              {props.children}
            </div>
          </Button>
        </div>
      )}
    </div>
  );
}

ProactiveMessage.propTypes = {
  style: PropTypes.shape(),
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  isShown: PropTypes.bool,
  showMessage: PropTypes.bool,
  children: PropTypes.node.isRequired,
  cookieName: PropTypes.string.isRequired,
};

ProactiveMessage.defaultProps = {
  style: proactiveMessageStyle,
  onClose: () => {},
  onClick: () => {},
  isShown: true,
  showMessage: false,
};

export default ProactiveMessage;
