import PropTypes from 'prop-types';

const enterprisePortalLinkShape = {
  branding_configuration: PropTypes.shape({
    logo: PropTypes.string,
    banner_border_color: PropTypes.string,
    banner_background_color: PropTypes.string,
  }),
  title: PropTypes.string,
  url: PropTypes.string,
  uuid: PropTypes.string,
};

export default enterprisePortalLinkShape;
