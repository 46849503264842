import './styles.scss';
import React, { useRef, RefObject } from 'react';
import classNames from 'classnames';
import { Button, Icon } from '@edx/paragon';
import { ArrowForwardIos } from '@edx/paragon/icons';

import SafeArea from './SafeArea';

type MenuItemPropType = {
  id?: string;
  item: string;
  isActive: boolean;
  label: string;
  onClick: Function;
  onFocus: Function;
  onMouseEnter: Function;
  section: string;
  menuRef: RefObject<HTMLElement>,
};

function MenuItem({
  id,
  item,
  isActive,
  label,
  onClick,
  onFocus,
  onMouseEnter,
  section,
  menuRef,
}: MenuItemPropType) {
  const menuItemRef = useRef(null);
  const isBootcampLink = id === 'menu-item-bootcamp';

  const handleClick = () => {
    onClick(item);
  };

  const handleMouseEnter = () => {
    onMouseEnter(item);
  };

  const handleFocus = () => {
    onFocus(item);
  };

  return (
    <>
      <Button
        id={id}
        className={classNames(
          'nav-menu-item d-flex justify-content-between w-100 px-4 py-2',
          {
            active: isActive,
            'user-location-visiblity-hidden': isBootcampLink,
          },
        )}
        variant="link"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onFocus={handleFocus}
        ref={menuItemRef}
      >
        <span className="sr-only">Explore the </span>
        <span className="small">{label}</span>
        <span className="sr-only"> {section}</span>
        <Icon src={ArrowForwardIos} size="xs" />
      </Button>
      {isActive && menuItemRef.current && menuRef.current && (
        <SafeArea menuItem={menuItemRef.current} menu={menuRef.current} />
      )}
    </>
  );
}

MenuItem.defaultProps = {
  id: null,
};

export default MenuItem;
