import './styles.scss';
import React, {
  forwardRef,
  createRef,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Collapsible, Icon } from '@edx/paragon';
import { ExpandLess, ExpandMore } from '@edx/paragon/icons';
import classNames from 'classnames';

import NewBadge from '@prospectus/common/ui/NewBadge';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import CollapsibleTrigger from './CollapsibleTrigger';

const MenuAccordion = forwardRef(({
  title,
  id,
  links,
  onCollapsibleOpen,
  className,
  collapsibleTriggerRef: suppliedCollapsibleTriggerRef,
}, ref) => {
  const collapsibleRef = ref || useRef();
  const collapsibleTriggerRef = suppliedCollapsibleTriggerRef || useRef();
  const [activeRefIndex, setActiveRefIndex] = useState(-1);

  // Create placeholder refs for menu items.
  const [refs, setRefs] = useState(links.map(() => createRef()));

  useEffect(() => {
    setRefs((prevRefs) => links.map((_, index) => prevRefs[index] || createRef()));
  }, [links.length]);

  useEffect(() => {
    if (!refs) {
      return;
    }

    if (activeRefIndex < 0) {
      return;
    }

    if (typeof refs[activeRefIndex] !== 'undefined' && refs[activeRefIndex].current) {
      refs.forEach((_, idx) => {
        if (typeof refs[idx] !== 'undefined' && refs[idx].current) {
          refs[idx].current.setAttribute('tabindex', -1);
        }
      });

      refs[activeRefIndex].current.setAttribute('tabindex', 0);
      refs[activeRefIndex].current.focus();
      refs[activeRefIndex].current.setAttribute('tabindex', -1);
    }
  }, [activeRefIndex, refs]);

  const handleKeyDown = (event) => {
    const { key, target } = event;

    if (key === 'Tab') {
      setActiveRefIndex(-1);
    }

    if (key === 'ArrowDown' || key === 'ArrowRight') {
      setActiveRefIndex(prev => (prev + 1) % refs.length);
    }
    if (key === 'ArrowUp' || key === 'ArrowLeft') {
      if (target.classList && target.classList.contains('collapsible-trigger')) {
        return;
      }
      setActiveRefIndex(prev => (prev - 1 < 0 ? refs.length - 1 : prev - 1));
    }
  };

  const arrowStyle = {
    height: '36px',
    width: '36px',
    margin: '-6px -20px',
  };

  const collapsibleButtonId = useMemo(() => id, []);
  const submenuId = useMemo(() => `${collapsibleButtonId}-submenu`, []);

  return (
    <Collapsible.Advanced
      className="nav-mobile-collapsible"
      onOpen={onCollapsibleOpen}
      ref={collapsibleRef}
    >
      <CollapsibleTrigger
        className={
          classNames(
            'collapsible-trigger',
            'btn btn-tertiary bg-white w-100 px-4',
            className,
          )
        }
        onKeyDown={handleKeyDown}
        ref={collapsibleTriggerRef}
        aria-haspopup="menu"
        aria-controls={submenuId}
        id={collapsibleButtonId}
      >
        <div className="mx-auto">
          {title}
        </div>
        <Collapsible.Visible whenClosed>
          <Icon src={ExpandMore} style={arrowStyle} />
        </Collapsible.Visible>
        <Collapsible.Visible whenOpen>
          <Icon src={ExpandLess} style={arrowStyle} />
        </Collapsible.Visible>
      </CollapsibleTrigger>
      <Collapsible.Body
        tag="ul"
        className="collapsible-body list-group list-unstyled"
        id={submenuId}
        role="menu"
        aria-labelledby={collapsibleButtonId}
      >
        {links && links.map(({
          label, href, newBadge, ...rest
        }, idx) => (
          <li className="menu-link visible-mobile" key={href} role="none">
            <ProspectusLink
              {...rest}
              className={
                classNames(
                  'btn btn-tertiary detail-link bg-light-300 w-100 px-4',
                  className,
                )
              }
              href={href}
              linkRef={refs[idx]}
              linkProps={{
                onKeyDown: handleKeyDown,
                role: 'menuitem',
                tabIndex: -1,
              }}
            >
              {label}
              {newBadge && <NewBadge className="mx-2" />}
            </ProspectusLink>
          </li>
        ))}
      </Collapsible.Body>
    </Collapsible.Advanced>
  );
});

MenuAccordion.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  id: PropTypes.string.isRequired, // Used for Aria
  links: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    analytics: PropTypes.shape({
      eventName: PropTypes.string.isRequired,
      linkProps: PropTypes.shape(),
    }).isRequired,
  })).isRequired,
  onCollapsibleOpen: PropTypes.func.isRequired,
  collapsibleTriggerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]),
};

MenuAccordion.defaultProps = {
  className: null,
  collapsibleTriggerRef: null,
};

export default MenuAccordion;
