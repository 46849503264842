// This is a necessary polyfil for async/await. Frontend-platform relies on it
import 'regenerator-runtime/runtime';
import {
  configure as configureAuth,
  getAuthenticatedHttpClient,
  fetchAuthenticatedUser,
  getHttpClient,
  AxiosJwtAuthService,
} from '@edx/frontend-platform/auth';
import {
  configure as configureLogging,
  NewRelicLoggingService,
} from '@edx/frontend-platform/logging';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  const config = {
    BASE_URL: process.env.GATSBY_SITE_DOMAIN,
    LMS_BASE_URL: process.env.GATSBY_LMS_DOMAIN,
    LOGIN_URL: `${process.env.GATSBY_LMS_DOMAIN}/login`,
    LOGOUT_URL: `${process.env.GATSBY_LMS_DOMAIN}/logout`,
    REFRESH_ACCESS_TOKEN_ENDPOINT: `${process.env.GATSBY_LMS_DOMAIN}/login_refresh`,
    ACCESS_TOKEN_COOKIE_NAME: process.env.GATSBY_ACCESS_TOKEN_COOKIE_NAME,
    CSRF_TOKEN_API_PATH: process.env.GATSBY_CSRF_TOKEN_API_PATH,
  };

  configureLogging(NewRelicLoggingService, {
    config,
  });

  configureAuth(AxiosJwtAuthService, {
    // return empty functions to mute errors from @edx/frontend-platform/auth
    loggingService: {
      logError: () => {},
      logInfo: () => {},
    },
    config,
  });
}

const authenticatedHttpClient = isBrowser ? getAuthenticatedHttpClient() : {};
const httpClient = isBrowser ? getHttpClient() : {};
export default authenticatedHttpClient;
export {
  fetchAuthenticatedUser,
  httpClient,
};
