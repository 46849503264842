import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@edx/paragon';
import { Lightbulb } from '@edx/paragon/icons';

import { submitEvent } from '@utils/analytics';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import ProactiveMessage from '@prospectus/common/ui/xpert/shared/ProactiveMessage';
import FloatingActionButton from '@prospectus/common/ui/xpert/shared/FloatingActionButton';
import useOptimizelyVariation from '@prospectus/common/ui/xpert/shared/hooks/useOptimizelyVariation';

import './styles.scss';

function SkillsBuilderFloatingActionButton({ tracking, pageName }) {
  const [isProactiveMessageOpen, setIsProactiveMessageOpen] = useState(false);
  const isMobile = useBreakpointMatch('medium');
  const experimentVariation = useOptimizelyVariation('maasSBPopupExperiment');

  if (!experimentVariation) {
    return null;
  }

  const handleProactiveMessageClose = () => {
    setIsProactiveMessageOpen(false);
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'skillsbuilder',
    }, 'edx.bi.experiment.skillsbuilder.popup.proactive.message.closed');
  };

  const handleSkillsQuizClick = () => {
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'skillsbuilder',
    }, 'edx.bi.experiment.skillsbuilder.popup.skills.quiz.button.clicked');
  };

  const handleProactiveMessageOpen = () => {
    setIsProactiveMessageOpen(true);
  };

  return (
    <>
      <ProactiveMessage
        showMessage={isProactiveMessageOpen}
        style={{ bottom: isMobile ? '10rem' : '5.5rem', right: isMobile ? '.8rem' : '1.6rem' }}
        onClose={handleProactiveMessageClose}
        cookieName="edx-proactive-message-skillsbuilder"
      >
        <span className="sb-proactive-popup__text">
          Guess what! 🤠 We can recommend courses based on your career goals. Give our new skills quiz a try.
        </span>
        <a
          href="https://profile.edx.org/skills"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleSkillsQuizClick}
          variant="custom"
          className="sb-proactive-popup__link"
          aria-label="Take our skills quiz"
        >
          Take our skills quiz
        </a>
      </ProactiveMessage>
      <FloatingActionButton
        onClick={handleProactiveMessageOpen}
        label="Open Skills Builder message"
        style={{
          ...(!isMobile && { right: '2.3rem' }),
          backgroundColor: '#002121',
        }}
      >
        <Icon src={Lightbulb} />
      </FloatingActionButton>
    </>
  );
}

SkillsBuilderFloatingActionButton.propTypes = {
  tracking: PropTypes.shape().isRequired,
  pageName: PropTypes.string.isRequired,
};

export default SkillsBuilderFloatingActionButton;
