import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '@edx/paragon';
import {
  Lightbulb,
  Recommend,
  ArrowForward,
  KeyboardArrowDown,
} from '@edx/paragon/icons';
import Cookies from 'js-cookie';

import { submitEvent } from '@utils/analytics';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import ProactiveMessage from '@prospectus/common/ui/xpert/shared/ProactiveMessage';
import FloatingActionButton from '@prospectus/common/ui/xpert/shared/FloatingActionButton';
import FloatingActionPopup from '@prospectus/common/ui/xpert/shared/FloatingActionPopup';
import useOptimizelyVariation from '@prospectus/common/ui/xpert/shared/hooks/useOptimizelyVariation';
import CloudflareImage from '@prospectus/common/ui/CloudflareImage';

import './styles.scss';

const messageList = [
  {
    id: '1',
    text: `Hi! We're glad you're interested in
            earning an official certificate from the
            institution providing the course. You
            can do so through the verified track
            when you enroll.`,
  },
  {
    id: '2',
    text: 'Other benefits of the verified track are:',
    subTextList: [
      {
        id: '2a',
        text: `Showcase a verfied certificate of
        completion on your resumé to
        advance your career`,
      },
      {
        id: '2b',
        text: `Full access to course content and
        materials, even after course ends`,
      },
      {
        id: '2c',
        text: `You're more likely to complete your
        course! We've observed that those
        who are working towards a verified
        certificate are more engaged and
        motivated to complete their course 😁`,
      },
    ],
  },
  {
    id: '3',
    text: 'Want more help? Visit our',
  },
];

const faqPageLink = 'https://support.edx.org/hc/en-us/sections/360002747833-About-Audit-and-Verified-Courses';

const helpDeskLink = 'https://support.edx.org/hc/en-us';

const isTestUser = Cookies.get('optly_edx_test') === 'ocm_action_button';

function OCMFloatingActionButton({ tracking, pageName }) {
  const [open, setOpen] = useState(false);
  const [showAfterDelay, setShowAfterDelay] = useState(false);
  const [shouldShowThankyouMessage, setShouldShowThankyouMessage] = useState(false);

  const isMobile = useBreakpointMatch('medium');
  const experimentVariation = useOptimizelyVariation('xpertOCMPopupExperiment');

  useEffect(() => {
    let timeout;
    if (experimentVariation === 'A' || isTestUser) {
      timeout = setTimeout(() => setShowAfterDelay(true), 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [experimentVariation]);

  if ((experimentVariation !== 'A' || showAfterDelay === false) && !isTestUser) {
    return null;
  }

  const handleFloatingActionButtonClick = () => {
    setOpen(true);
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'ocm',
    }, 'edx.bi.experiment.ocm.popup.action.button.clicked');
  };

  const handleProactiveMessageOpen = () => {
    setOpen(true);
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'ocm',
    }, 'edx.bi.experiment.ocm.popup.proactive.message.clicked');
  };

  const handleProactiveMessageClose = () => {
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'ocm',
    }, 'edx.bi.experiment.ocm.popup.proactive.message.closed');
  };

  const handleMinimize = () => {
    setOpen(false);
    setShouldShowThankyouMessage(false);

    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'ocm',
    }, 'edx.bi.experiment.ocm.popup.minimize');
  };

  const handleHelpfulClick = (isHelpful) => {
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'ocm',
    }, `edx.bi.experiment.ocm.popup.thumbs.${isHelpful ? 'up' : 'down'}.clicked`);
    setShouldShowThankyouMessage(true);
  };

  const handleFAQClick = () => {
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'ocm',
    }, 'edx.bi.experiment.ocm.popup.faq.clicked');
  };

  const handleHelpDeskClick = () => {
    submitEvent(tracking, {
      category: '2u',
      page: pageName,
      label: 'ocm',
    }, 'edx.bi.experiment.ocm.popup.help.desk.clicked');
  };

  return (
    <>
      <ProactiveMessage
        isShown={!open}
        style={{ bottom: isMobile ? '9.75rem' : '5.25rem', right: isMobile ? '.8rem' : '1.6rem' }}
        onClose={handleProactiveMessageClose}
        onClick={handleProactiveMessageOpen}
        cookieName="edx-proactive-message-ocm"
      >
        <div className="ocm-action-proactive-message">
          <div className="ocm-action-proactive-message__text">
            Interested in earning a certificate from this course?
            <span className="ocm-action-proactive-message__learn-more-container">
              <span>Learn more</span>
              <div><Icon className="ocm-action-proactive-message__learn-more-container--icon" src={ArrowForward} /></div>
            </span>
          </div>
        </div>
      </ProactiveMessage>
      <FloatingActionButton
        onClick={handleFloatingActionButtonClick}
        label="Open the Open Course and Marketplace message"
        style={{
          ...(!isMobile && { right: '2.3rem' }),
          backgroundColor: '#002121',
        }}
        aria-haspopup="dialog"
        aria-expanded={open}
      >
        <Icon src={Lightbulb} />
      </FloatingActionButton>
      {open && (
      <FloatingActionPopup>
        <div
          style={{
            background: 'linear-gradient(to left, #3a656c 0, #001d22)',
          }}
          className="d-flex justify-content-end align-items-center py-3"
        >
          <CloudflareImage
            url="/images/experiments/xpert/xpert-logo.svg"
            style={{ transform: 'translateX(2rem)' }}
            description="xpert logo"
            data-ot-ignore=""
            className="ocm-action-popup__logo mx-auto"
          />
          <Button
            onClick={handleMinimize}
            aria-label="Minimize"
            className="bg-transparent border-0 m-0 p-0 mr-3"
            variant="inverse-outline-primary"
          >
            <Icon src={KeyboardArrowDown} />
          </Button>
        </div>

        <div className="ocm-action-popup__body">
          <div className="ocm-action-popup__bubble-header">
            <div className="ocm-action-popup__bubble">
              {messageList.map((message, index) => (
                <div key={message.id}>
                  <div style={{ marginBottom: index === messageList.length - 1 ? '' : '10px' }}>
                    <div>
                      {message.text}
                      {(index === (messageList.length - 1)) && (
                        <span className="ocm-action-popup__bubble--link-text-container">
                          <a
                            data-testid="ocm-action-popup-faq-page-link"
                            href={faqPageLink}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={handleFAQClick}
                          >
                            FAQ page
                          </a>
                          <span className="ocm-action-popup__bubble--help-desk-link">or</span>
                          <a
                            data-testid="ocm-action-popup-help-desk-link"
                            href={helpDeskLink}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={handleHelpDeskClick}
                          >
                            Help Desk
                          </a>.
                        </span>
                      )}
                    </div>
                    {message.subTextList && (
                      <ul>
                        {message.subTextList?.map(subtext => (
                          <li key={subtext.id} className="ocm-action-popup__bubble--list">
                            {subtext.text}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="ocm-action-popup__bubble-helper">Was this helpful?</div>
          {!shouldShowThankyouMessage && (
            <div className="ocm-action-popup__recommendation-container">
              <Button
                data-testid="ocm-action-popup-thumbs-up"
                className="ocm-action-popup__recommendation-button"
                onClick={() => handleHelpfulClick(true)}
                variant="outline-primary"
                aria-label="This was helpful thumbs up"
                type="button"
              >
                <Icon className="ocm-action-popup__recommendation-button-icon-up" src={Recommend} />
              </Button>
              <Button
                data-testid="ocm-action-popup-thumbs-down"
                className="ocm-action-popup__recommendation-button"
                onClick={() => handleHelpfulClick(false)}
                variant="outline-primary"
                aria-label="This was not helpful thumbs down"
                type="button"
              >
                <Icon className="ocm-action-popup__recommendation-button-icon-down" src={Recommend} />
              </Button>
            </div>
          )}
          {shouldShowThankyouMessage && (
            <div data-testid="ocm-action-popup-thank-you" className="ocm-action-popup__thank-you">
              Thank you for your feedback! 🎉
            </div>
          )}
        </div>
      </FloatingActionPopup>
      )}
    </>
  );
}

OCMFloatingActionButton.propTypes = {
  tracking: PropTypes.shape().isRequired,
  pageName: PropTypes.string.isRequired,
};

export default OCMFloatingActionButton;
