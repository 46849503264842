const getOptimizelyProps = ({ sync, oneTrustIgnore }) => {
  // Optimizely script on production is proxied using Cloudflare to spare some
  // loading time by avoiding SSL handshake
  const prodSrc = `${process.env.GATSBY_SITE_DOMAIN}/optimizelyjs/${process.env.GATSBY_OPTIMIZELY_PROJECT_ID}.js`;
  const devSrc = `https://cdn.optimizely.com/js/${process.env.GATSBY_OPTIMIZELY_PROJECT_ID}.js`;
  const src = process.env.GATSBY_ENVIRONMENT === 'development' ? devSrc : prodSrc;
  let optimizelyProps = { src };

  if (oneTrustIgnore === true) {
    optimizelyProps = { ...optimizelyProps, 'data-ot-ignore': '' };
  }

  if (!sync) {
    optimizelyProps.async = true;
  }

  return { ...optimizelyProps };
};

export default getOptimizelyProps;
