import { ProductType } from '@prospectus/common/types/productTypes';

// This file is designed to convert the disorganized metadata coming from the server into
// the discrete type options we defined for prospectus in the productTypes file above.

const courseTypeToProductTypeMap: { [key: string]: ProductType } = {
  course: 'Course',
  'verified-audit': 'Course',
  verified: 'Course',
  audit: 'Course',
  'credit-verified-audit': 'Course',
  'spoc-verified-audit': 'Course',
  professional: 'Professional Certificate',
  'bootcamp-2u': 'Boot Camp',
  'executive-education-2u': 'Executive Education',
  'executive-education': 'Executive Education',
  masters: "Master's",
  'masters-verified-audit': "Master's",
};

const programTypeToProductTypeMap: { [key: string]: ProductType } = {
  xseries: 'XSeries',
  micromasters: 'MicroMasters',
  microbachelors: 'MicroBachelors',
  'professional certificate': 'Professional Certificate',
  "bachelor's": "Bachelor's",
  bachelors: "Bachelor's",
  "master's": "Master's",
  masters: "Master's",
  doctorate: 'Doctorate',
  license: 'License',
  certificate: 'Certificate',
};

const useProductType = (courseType?: string, programType?: string): ProductType | undefined => {
  const courseTypeLowerCase = courseType?.toLowerCase();
  if (courseTypeToProductTypeMap[courseTypeLowerCase]) {
    return courseTypeToProductTypeMap[courseTypeLowerCase];
  }

  const programTypeLowerCase = programType?.toLowerCase();
  if (programTypeToProductTypeMap[programTypeLowerCase]) {
    return programTypeToProductTypeMap[programTypeLowerCase];
  }

  return undefined;
};

export default useProductType;
