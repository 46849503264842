/* eslint-disable react/no-danger */
import './styles.scss';
import React, {
  useRef,
  useEffect,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import track from 'react-tracking';
import PropTypes from 'prop-types';
import { Button } from '@edx/paragon';
import { defineMessages, injectIntl, FormattedMessage } from 'gatsby-plugin-react-intl';

import { submitEvent } from '@utils/analytics';
import { AutoCompleteContext } from '@utils/context';
import { shouldRedirect } from '@utils/externalRedirect';
import { PRODUCT_CATEGORY_BADGES_MAP, EXTERNAL_PRODUCT_SOURCE_SLUGS } from '@utils/discoveryConstants';

import useSearchParams from '@prospectus/common/hooks/useSearchParams';
import { intlShape } from '@prospectus/common/types/intlShape';
import { formatExternalUrl } from '@prospectus/common/ui/ProductCard/utils';

import AutoCompleteDropdownItem from '../AutoCompleteDropdownItem';
import PopularSearchItem from '../PopularSearchItem';

const messages = defineMessages({
  'prospectus.search.autoComplete.courses.title': {
    id: 'prospectus.search.autoComplete.courses.title',
    description: 'Heading for the Courses section of autoComplete suggestions.',
    defaultMessage: 'Courses',
  },
  'prospectus.search.autoComplete.programs.title': {
    id: 'prospectus.search.autoComplete.programs.title',
    description: 'Heading for the Programs section of autoComplete suggestions.',
    defaultMessage: 'Programs',
  },
  'prospectus.search.autoComplete.degrees.title': {
    id: 'prospectus.search.autoComplete.degrees.title',
    description: 'Heading for the Degrees section of autoComplete suggestions.',
    defaultMessage: 'Degrees',
  },
  'prospectus.search.searchbar.placeholder': {
    id: 'prospectus.search.searchbar.placeholder',
    description: 'Placeholder text in a search input field',
    defaultMessage: 'What do you want to learn?',
  },
  'prospectus.search.autocomplete.cta': {
    id: 'prospectus.search.autocomplete.cta',
    description: 'Button text to take users to the full page of search results for their query',
    defaultMessage: 'View all results',
  },
  'prospectus.search.autocomplete.most-popular': {
    id: 'prospectus.search.autocomplete.most-popular',
    description: 'Heading for popular programs',
    defaultMessage: 'Most popular programs',
  },
  'prospectus.search.autocomplete.trending': {
    id: 'prospectus.search.autocomplete.trending',
    description: 'Describes search terms that are trending',
    defaultMessage: 'Trending now',
  },
});

const getCourseType = courseTypeSlug => {
  switch (courseTypeSlug) {
    case 'bootcamp-2u':
      return 'Boot Camp';
    case 'executive-education-2u':
      return 'Executive Education';
    default:
      return 'Course';
  }
};

const getProductCategoryLabel = (productKey) => PRODUCT_CATEGORY_BADGES_MAP[productKey];
const getClickedEventName = (product, programType) => {
  const prefix = ['Course', 'Program'].includes(product) ? 'oc-' : '2u-';
  const type = (['Program', '2U Degree'].includes(product)) ? programType : product;
  return `edx.bi.user.autocomplete.${(prefix + type).toLowerCase().replaceAll(' ', '-')}.clicked`;
};

const getPreQueryByPageType = (preQuery, currentPage) => {
  if (preQuery[currentPage]?.products?.length > 0) {
    return preQuery[currentPage];
  }
  return preQuery.default || [];
};

function InnerAutoComplete({
  // search config for tracking
  indexName,
  // focus management tools from parent component
  id,
  activeOption,
  setActiveOption,
  searchInputId,
  viewAllId,
  searchRef,
  autocompleteRef,
  // optional properties for the <form> element
  onFocus,
  onBlur,
  intl,
  tracking,
  sendAnalytics,
  searchBoxProps,
  searchBoxComponent: SearchBoxComponent,
}) {
  const { preQuery } = useSelector(state => state.autoComplete);
  const currentPage = useSelector(state => state.currentPage);

  const { products: preQueryProducts, popularSearchTerms } = getPreQueryByPageType(preQuery, currentPage);
  const preQueryProductsWithId = preQueryProducts?.map((product, index) => (
    {
      ...product,
      id: `${id}-prequery-product-${index}`,
    }));

  const autocompleteDivRef = autocompleteRef || useRef();
  // search state from top-level controlling component
  const { searchParams, setSearchParams } = useSearchParams();
  const autocompleteState = useContext(AutoCompleteContext);
  const {
    query,
    autocompleteResults: {
      products,
    },
    preQueryOpen,
    setPreQueryOpen,
    autocompleteOpen,
    setAutocompleteOpen,
  } = autocompleteState;

  // popper is a styling tool for placing the autocomplete menu directly under the search bar
  // without having to do a lot of css calculations
  const { styles, attributes, forceUpdate } = usePopper(
    searchRef.current,
    autocompleteDivRef.current,
    {
      placement: 'bottom-start',
    },
  );

  // HACK: recompute the position of the autocomplete box on each query change to ensure it's in the correct place
  // by the time autocomplete opens
  // Would be better to base this on autocompleteOpen but then there's a flicker where the menu is in the wrong place
  useEffect(() => {
    if (forceUpdate) {
      forceUpdate();
    }
  }, [query]);

  const useExternalLink = (product, productSource, uuid) => (
    // if product is emeritus, shorelight, or is in @utils/externalRedirect.js, link externally
    (productSource && EXTERNAL_PRODUCT_SOURCE_SLUGS.includes(productSource)) || shouldRedirect(uuid)
  );

  const getSuggestionLink = ({
    product,
    productSource,
    marketingUrl,
    externalUrl,
    uuid,
    resultIndex,
    queryID,
  }) => {
    const autocompleteSuffix = '&linked_from=autocomplete&c=autocomplete';

    if (useExternalLink(product, productSource, uuid)) {
      const formattedExternalUrl = formatExternalUrl({
        externalUrl,
        is2UDegreeProgram: product === '2U Degree',
        urlParams: {},
      });
      return `${formattedExternalUrl}&term=${query}${autocompleteSuffix}`;
    }

    const internalPath = (new URL(marketingUrl)).pathname;
    return `${internalPath}?index=${indexName}&queryID=${queryID}&position=${resultIndex + 1}${autocompleteSuffix}`;
  };

  const getPreQueryLink = ({
    product,
    productSource,
    marketingUrl,
    externalUrl,
    uuid,
    resultIndex,
  }) => {
    const autocompleteSuffix = '?linked_from=autocomplete-prequery&c=autocomplete-prequery';

    if (useExternalLink(product, productSource, uuid)) {
      const formattedExternalUrl = formatExternalUrl({
        externalUrl,
        is2UDegreeProgram: product === '2U Degree',
        urlParams: {},
      });
      return `${formattedExternalUrl}${autocompleteSuffix}`;
    }

    const internalPath = (new URL(marketingUrl)).pathname;
    return `${internalPath}${autocompleteSuffix}&position=${resultIndex + 1}`;
  };

  const searchAutocompleteId = `${id}-search-autocomplete`;

  const autocompleteBtn = 'autocomplete-view-all';
  const autocompleteBtnRegex = /(?:^|\s)autocomplete-view-all(?:\s|$)/;

  const productListWithIds = products.map((course, resultIndex) => (
    {
      ...course,
      id: `${id}-product-result-${resultIndex}`,
    }));

  const sendAnalyticsOnSubmit = (fromViewAllButton = false) => {
    if (sendAnalytics && typeof sendAnalytics === 'function') {
      sendAnalytics(autocompleteState, fromViewAllButton);
      return;
    }
    const componentProps = {
      category: 'search',
      label: query,
      fromViewAllButton,
    };

    submitEvent(tracking, componentProps, 'edx.bi.user.search-query.submitted');
  };

  const handleOnClick = () => {
    submitEvent(tracking, {
      category: 'search',
    }, 'edx.bi.user.search.box.clicked');
  };

  const updateSearchParams = (q) => {
    // Preserve the tab value if it exists.
    const params = {};
    if (searchParams.has('tab')) {
      params.tab = searchParams.get('tab');
    }
    if (searchParams.has('geotargeting')) {
      params.geotargeting = searchParams.get('geotargeting');
    }
    if (searchParams.has('learning_type')) {
      params.learning_type = searchParams.get('learning_type');
    }
    setSearchParams({ q, ...params });
  };

  const checkFocus = () => {
    const condition = document?.activeElement?.tagName === 'INPUT'
    || document?.activeElement?.tagName === 'LI'
    || !!document?.activeElement?.className?.match(autocompleteBtnRegex);

    if (!condition) {
      setPreQueryOpen(false);
      setAutocompleteOpen(false);
    }

    return condition;
  };

  return (
    <div>
      <div>
        <div
          className={`search-box pb-0 ${id}-autocomplete-searchbox`}
          id={`${id}-autocomplete-form`}
          key={`${id}-autocomplete-form`}
        >
          <SearchBoxComponent
            {...searchBoxProps}
            onBlur={onBlur}
            onClear={() => { setPreQueryOpen(false); }}
            onFocus={onFocus}
            onClick={handleOnClick}
            onSubmit={() => {
              setActiveOption(searchInputId);
              setPreQueryOpen(false);
              setAutocompleteOpen(false);
              sendAnalyticsOnSubmit();
              updateSearchParams(query);
            }}
          />
        </div>
      </div>
      <div
        ref={autocompleteDivRef}
        id={searchAutocompleteId}
        style={{ ...styles.popper, zIndex: 2000 }}
        {...attributes.popper}
        key={searchAutocompleteId}
        className={classNames('autocomplete-wrapper', { open: autocompleteOpen })}
      >
        {autocompleteOpen && checkFocus()
          && (
            <div className="bg-light-100 d-flex flex-column autocomplete">
              {products.length > 0 && (
                <div>
                  <ul
                    className="list-group suggestion-list"
                    role="listbox"
                    id={`${id}-product-list`}
                  >
                    {productListWithIds.map(({
                      _highlightResult,
                      id: courseLiId,
                      marketing_url: marketingUrl,
                      objectID,
                      organization_short_code_override: organizationShortCodeOverride,
                      owners,
                      product,
                      title,
                      uuid,
                      queryID,
                      program_type: programType,
                      product_source: productSource,
                      external_url: externalUrl,
                    }, resultIndex) => {
                      const productCategoryLabel = getProductCategoryLabel((['Program', '2U Degree'].includes(product)) ? programType[0] : product);
                      const clickedEventName = getClickedEventName(product, programType[0]);
                      return (
                        <AutoCompleteDropdownItem
                          key={courseLiId}
                          activeOption={activeOption}
                          highlightResult={_highlightResult}
                          indexName={indexName}
                          itemID={courseLiId}
                          link={
                            getSuggestionLink({
                              product,
                              productSource,
                              marketingUrl,
                              externalUrl,
                              uuid,
                              resultIndex,
                              queryID,
                            })
                          }
                          objectID={objectID}
                          organizationShortCodeOverride={organizationShortCodeOverride}
                          owners={owners}
                          position={resultIndex}
                          product={product}
                          queryID={queryID}
                          title={title}
                          uuid={uuid}
                          productCategoryLabel={productCategoryLabel}
                          clickedEventName={clickedEventName}
                        />
                      );
                    })}
                  </ul>
                </div>
              )}
              <Button
                variant="light"
                className={autocompleteBtn}
                id={viewAllId}
                onClick={() => {
                  setActiveOption(searchInputId);
                  setAutocompleteOpen(false);
                  sendAnalyticsOnSubmit(true);
                  updateSearchParams(query);
                }}
              >{intl.formatMessage(messages['prospectus.search.autocomplete.cta'])}
              </Button>
            </div>
          )}
        {preQueryOpen && !autocompleteOpen && checkFocus()
          && (
            <div className="bg-light-100 d-flex flex-column autocomplete autocomplete-pre-query py-2.5">
              {preQueryProductsWithId.length > 0 && (
                <div>
                  <h5 className="mx-3 mb-0">
                    {intl.formatMessage(messages['prospectus.search.autocomplete.most-popular'])}
                  </h5>
                  <ul
                    className="list-group suggestion-list"
                    role="listbox"
                    id={`${id}-prequery-product-list`}
                  >
                    {preQueryProductsWithId.map((item, resultIndex) => {
                      let product;
                      if (item.courseType) {
                        product = getCourseType(item.courseType);
                      } else {
                        product = item.type;
                      }
                      const clickedEventName = (
                        `edx.bi.user.autocomplete.prequery.${item.cardType}.clicked`
                      );
                      return (
                        <AutoCompleteDropdownItem
                          isPreQuery
                          key={item.id}
                          activeOption={activeOption}
                          indexName={indexName}
                          itemID={item.id}
                          link={
                            getPreQueryLink({
                              product,
                              productSource: item.productSource?.slug,
                              marketingUrl: item.marketingUrl,
                              externalUrl: item.externalUrl,
                              uuid: item.uuid,
                              resultIndex,
                            })
                          }
                          position={resultIndex}
                          product={product}
                          productCategoryLabel={product}
                          clickedEventName={clickedEventName}
                          {...item}
                        />
                      );
                    })}
                  </ul>
                </div>
              )}
              {popularSearchTerms?.length > 0 && (
                <div className="mt-2">
                  <h5 className="mx-3 mb-0">
                    {intl.formatMessage(messages['prospectus.search.autocomplete.trending'])}
                  </h5>
                  <ul className="list-group suggestion-list">
                    {popularSearchTerms.map((term, index) => {
                      const searchTermId = `${id}-popular-search-${index}`;
                      return (
                        <PopularSearchItem
                          key={searchTermId}
                          searchTerm={term}
                          activeOption={activeOption}
                          itemID={searchTermId}
                        />
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
      </div>
      <div aria-live="polite" role="status" className="sr-only" aria-relevant="all">
        {products.length > 0 && (
          <FormattedMessage
            id="prospectus.search.autoComplete.suggestionCount"
            description="Screenreader text telling users how many suggestions were found."
            defaultMessage="{suggestionCount, plural,
                            one {# suggestion available}
                            other {# suggestions available}}."
            values={{
              suggestionCount: products.length,
            }}
          />
        )}
      </div>
    </div>
  );
}

InnerAutoComplete.propTypes = {
  indexName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  activeOption: PropTypes.string,
  setActiveOption: PropTypes.func.isRequired,
  searchInputId: PropTypes.string.isRequired,
  viewAllId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  tracking: PropTypes.shape().isRequired,
  searchRef: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  autocompleteRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  ]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  sendAnalytics: PropTypes.func,
  searchBoxComponent: PropTypes.func.isRequired,
  searchBoxProps: PropTypes.shape({}).isRequired,
};

InnerAutoComplete.defaultProps = {
  activeOption: '',
  searchRef: null,
  onFocus: () => { },
  onBlur: () => { },
  autocompleteRef: null,
  sendAnalytics: undefined,
};

export default track({
  component: 'inner-autocomplete',
})(injectIntl(InnerAutoComplete));
