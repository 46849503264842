import { useMemo } from 'react';
import { getUserLocation } from '@utils/location';

const EMERITUS_BANNER_ID = 'edx-emeritus-banner';

// Will return only the first banner in the list that matches the criteria
// where other matching banners will be ignored (hence usage of .find instead of .filter)
const useActiveBanner = (availableBanners, intl) => {
  const userLocation = getUserLocation();
  const activeBanner = useMemo(() => {
    // the first conditional is a manual banner visiblity overrides, code should be removed
    // once targetCountry field is properly populated in Contentful for these banners.
    if (userLocation === 'IN') {
      return availableBanners.find(banner => banner.campaignId === EMERITUS_BANNER_ID);
    }

    return availableBanners.find((banner) => (
      banner.active === true
      && banner.languageCode === intl.locale
      && (
        banner.targetCountry
          ? banner.targetCountry === userLocation
          : true // visible for all countries if a specific targetCountry wasn't specified.
      )
    ));
  }, [userLocation]);

  return activeBanner;
};

export default useActiveBanner;
