import './styles.scss';

import React, { useEffect, useRef } from 'react';
import track from 'react-tracking';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';

import {
  CLOSE_MEGA_NAV,
  FLYOUT_ID,
  SET_ACTIVE_CATEGORY,
  SET_CATEGORIES,
  SET_MENU_ITEMS,
  CLEAR_ACTIVE_CATEGORY,
} from '@prospectus/common/ui-strict/MegaNav/data/constants';
import {
  useMegaNav,
  useMegaNavDispatch,
} from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import useMegaNavQuery from '@prospectus/common/ui-strict/MegaNav/hooks/useMegaNavQuery';

import { submitEvent } from '@utils/analytics';
import isUserLoggedIn from '@utils/user';
import { getLmsLink } from '@utils/edxLinks';

import MenuItem from '../MenuItem';
import MenuLink from '../MenuLink';
import NavigationFlyoutView from '../NavigationFlyoutView';
import MobileUserActionLinks from '../MobileUserActionLinks';
import { useCareerFlyoutData } from '../NavigationCareerFlyout/useCareerFlyoutData';

const messages = defineMessages({
  'prospectus.mega-nav.course': {
    id: 'prospectus.mega-nav.course',
    description: 'Link to courses',
    defaultMessage: 'Take a course',
  },
  'prospectus.mega-nav.career-resources': {
    id: 'prospectus.mega-nav.career-resources',
    description: 'Link to the career resources tab',
    defaultMessage: 'Career resources',
  },
  'prospectus.mega-nav.schools-and-partners': {
    id: 'prospectus.mega-nav.schools-and-partners',
    description: 'Link to the schools and partners page',
    defaultMessage: 'Schools & Partners',
  },
});

const spanishMessages = defineMessages({
  'prospectus.mega-nav.course': {
    id: 'prospectus.mega-nav.course',
    description: 'Link to courses - Spanish',
    defaultMessage: 'Tomar un curso',
  },
  'prospectus.mega-nav.career-resources': {
    id: 'prospectus.mega-nav.career-resources',
    description: 'Link to the career resources tab - Spanish',
    defaultMessage: 'Recursos profesionales',
  },
  'prospectus.mega-nav.schools-and-partners': {
    id: 'prospectus.mega-nav.schools-and-partners',
    description: 'Link to the schools and partners page - Spanish',
    defaultMessage: 'Escuelas y socios',
  },
});

const focusMenuItem = (id, isCategory = true) => {
  if (typeof document === 'undefined') {
    return;
  }

  const el = isCategory
    ? document.querySelector<HTMLElement>(`#menu-item-${id}`)
    : document.querySelector<HTMLElement>(`#${id}`);

  if (el) {
    el.focus({ preventScroll: true });
  }
};

interface IVerticalNavigationMenuProps {
  tracking: object;
}

function VerticalNavigationMenu({ tracking }: IVerticalNavigationMenuProps) {
  const intl = useIntl();
  const ref = useRef(null);
  const isMobile = useBreakpointMatch('medium');

  const dispatch = useMegaNavDispatch();
  const { isOpen, activeCategory, menuItems } = useMegaNav();
  const careerFlyoutData = useCareerFlyoutData();
  const megaNavData = useMegaNavQuery(intl.locale);

  useEffect(() => {
    const { categoryId: careerId } = careerFlyoutData;
    const categories = {
      ...megaNavData.categories,
      [careerId]: careerFlyoutData,
    };
    dispatch({ type: SET_MENU_ITEMS, payload: megaNavData.menuItems });
    dispatch({ type: SET_CATEGORIES, payload: categories });
  }, []);

  const onClose = () => {
    submitEvent(
      tracking,
      { category: 'mega-nav' },
      'edx.bi.mega-nav.close-menu',
    );
    dispatch({ type: CLOSE_MEGA_NAV });
  };

  const mappedCategories = menuItems?.flatMap(({ items }) => items.map(({ category }) => category));
  const categories = [...mappedCategories, 'career', 'partners'];

  // mount listener for navigating the sidebar menu with arrows
  useEffect(() => {
    const handleKeydown = event => {
      // return if menu's closed or no handled key event
      if (
        !isOpen
        || ![
          'Escape',
          'Tab',
          'ArrowDown',
          'ArrowUp',
          'ArrowRight',
          'ArrowLeft',
        ].includes(event?.key)
      ) {
        return;
      }

      // adds an event listener to close MegaMenu with esc key
      if (event.key === 'Escape') {
        event.stopPropagation();
        if (activeCategory) {
          dispatch({ type: CLEAR_ACTIVE_CATEGORY });
        } else {
          onClose();
        }
      }

      const activeCategoryIndex = categories?.findIndex(
        category => category === activeCategory,
      );

      const first = categories[0];
      const last = categories[categories.length - 1];
      const next = categories[activeCategoryIndex + 1];
      const previous = categories[activeCategoryIndex - 1];

      if (activeCategory && event?.key === 'Tab') {
        const byGoal = ['certificate', 'bootcamp', 'degree'].includes(
          activeCategory,
        );
        const byTopic = [
          'artificial-intelligence',
          'data-science',
          'finance',
          'business-administration',
        ].includes(activeCategory);
        const allTopics = activeCategory === 'view-all-topics';
        const careerResources = activeCategory === 'career';

        if (byGoal) {
          const expandableButtonExists = document.querySelector(
            '.expandable-list-btn',
          );
          const isExpandableButton = event?.target?.className?.includes(
            'expandable-list-btn',
          );
          const isLastChip = event?.target?.className?.includes('last-chip');

          if ((isLastChip && !expandableButtonExists) || isExpandableButton) {
            dispatch({ type: SET_ACTIVE_CATEGORY, payload: next });
            focusMenuItem(next);
          }
        }
        if (byTopic || allTopics || careerResources) {
          const lastCta = careerResources
            ? event?.target?.className?.includes('last-cta')
            : event?.target?.parentElement?.className?.includes('last-cta');
          if (lastCta) {
            dispatch({ type: SET_ACTIVE_CATEGORY, payload: next });
            focusMenuItem(next, !careerResources);
          }
        }
      }

      if (event.key === 'ArrowDown') {
        if (activeCategoryIndex === categories.length - 1) {
          dispatch({ type: SET_ACTIVE_CATEGORY, payload: first });
          focusMenuItem(first);
        } else {
          dispatch({ type: SET_ACTIVE_CATEGORY, payload: next });
          focusMenuItem(next);
        }
      }

      if (event.key === 'ArrowUp') {
        if (activeCategoryIndex === 0) {
          dispatch({ type: SET_ACTIVE_CATEGORY, payload: last });
          focusMenuItem(last);
        } else {
          dispatch({ type: SET_ACTIVE_CATEGORY, payload: previous });
          focusMenuItem(previous);
        }
      }

      if (event.key === 'ArrowRight') {
        focusMenuItem(FLYOUT_ID, false);
      }

      if (event.key === 'ArrowLeft') {
        focusMenuItem(activeCategory);
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen, activeCategory]);

  // render the correct flyout
  useEffect(() => {
    if (isOpen && activeCategory) {
      if (categories[activeCategory]) {
        dispatch({ type: SET_ACTIVE_CATEGORY, payload: activeCategory });
      }
    }
  }, [isOpen, activeCategory]);

  useEffect(() => {
    // adds an event listener to close MegaMenu when clicking search input
    const handleClickOutside = event => {
      if (ref?.current && !ref.current?.contains(event.target)) {
        if (event.target.name === 'searchfield-input') {
          onClose();
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, ref]);

  // Locks the body scroll when the MegaMenu is open
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('lock-scroll-mega-nav');
    } else {
      document.body.classList.remove('lock-scroll-mega-nav');
    }
  }, [isOpen]);

  const handleFocus = (category: string) => {
    if (category !== activeCategory && !isMobile) {
      dispatch({ type: SET_ACTIVE_CATEGORY, payload: category });
    }
  };

  const handleSelect = (category: string) => {
    dispatch({ type: SET_ACTIVE_CATEGORY, payload: category });
    focusMenuItem(FLYOUT_ID, false);
  };

  return (
    <div
      className={classNames('nav-menu-wrapper position-absolute', {
        'd-none': !isOpen,
        'w-100 mobile-menu-height': isMobile,
        'h-100': !isMobile,
      })}
    >
      {/* Dark overlay */}
      <div
        className={classNames(
          'nav-menu-overlay bg-gray-900 w-full position-fixed',
          { 'nav-menu-overlay__is-open': isOpen },
        )}
        aria-hidden // screen readers don't really need this
        onClick={onClose}
      />

      {/* Menu */}
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{ clickOutsideDeactivates: true }}
      >
        <div
          className={classNames('nav-menu position-relative h-100', {
            'nav-menu__is-open': isOpen,
            'flex-column': isMobile,
          })}
          ref={ref}
        >
          {isMobile && (
            <MobileUserActionLinks
              userLoggedIn={isUserLoggedIn()}
              dashboardLinkClick={(
                event,
                label = event.currentTarget?.innerText,
              ) => {
                // @ts-ignore
                window.analytics.track(
                  'edx.bi.link.header_navigation.clicked',
                  {
                    category: 'navigation',
                    label,
                    link: event.currentTarget.href,
                    mobile: false,
                  },
                );
              }}
              dashboardLink={getLmsLink('/dashboard')}
              loginLink={getLmsLink('/login')}
              registerLink={getLmsLink('/register')}
            />
          )}

          <div
            className={classNames(
              'flex-column pb-3 mega-nav-menu',
              { 'bg-gray-100 pt-2': !isMobile },
              { 'w-100 h-100 bg-white': isMobile },
              { 'd-none': isMobile && activeCategory },
            )}
          >
            {menuItems.map(({ section, label, items }) => (
              <nav key={section} className="py-2">
                <h4 className="px-4">{label}</h4>
                {section === 'goal' && (
                  <MenuLink
                    // Temporarily disabled as part of [WS-4465](https://2u-internal.atlassian.net/browse/WS-4465)
                    // href="/search?tab=course&referredFrom=edxNavigation"
                    href="/search?tab=course"
                    label={intl.formatMessage(
                      intl.locale === 'en'
                        ? messages['prospectus.mega-nav.course']
                        : spanishMessages['prospectus.mega-nav.course'],
                    )}
                    eventName="edx.bi.mega-nav.courses.click"
                  />
                )}
                {items.map(item => (
                  <MenuItem
                    id={`menu-item-${item.category}`}
                    key={item.category}
                    item={item.category}
                    isActive={item.category === activeCategory}
                    label={item.label}
                    onClick={handleSelect}
                    onFocus={handleFocus}
                    onMouseEnter={handleFocus}
                    section={section}
                    menuRef={ref}
                  />
                ))}
              </nav>
            ))}
            <nav className="nav-menu__resources-section py-3">
              <MenuItem
                id="menu-item-career"
                item="career"
                isActive={activeCategory === 'career'}
                label={intl.formatMessage(
                  intl.locale === 'en'
                    ? messages['prospectus.mega-nav.career-resources']
                    : spanishMessages['prospectus.mega-nav.career-resources'],
                )}
                onClick={handleSelect}
                onFocus={handleFocus}
                onMouseEnter={handleFocus}
                section="resources"
                menuRef={ref}
              />
              <div id="partners" tabIndex={-1}>
                <MenuLink
                  // Temporarily disabled as part of [WS-4465](https://2u-internal.atlassian.net/browse/WS-4465)
                  // href="/schools-partners?referredFrom=edxNavigation"
                  href="/schools-partners"
                  label={intl.formatMessage(
                    intl.locale === 'en'
                      ? messages['prospectus.mega-nav.schools-and-partners']
                      : spanishMessages[
                        'prospectus.mega-nav.schools-and-partners'
                      ],
                  )}
                  eventName="edx.bi.mega-nav.schools-and-partners.click"
                />
              </div>
            </nav>
          </div>

          {/* Flyout Container */}
          {activeCategory && <NavigationFlyoutView />}
        </div>
      </FocusTrap>
    </div>
  );
}

export default track({ component: 'mega-nav-menu' })(VerticalNavigationMenu);
