import { useEffect, useState } from 'react';
import useIsInRuntime from '@prospectus/common/hooks/useIsInRuntime';

const useRuntimeRenderable = () => {
  // this hook is used to avoid rendering components during build time
  const isInRuntime = useIsInRuntime();
  const [runtimeRenderable, setRuntimeRenderable] = useState(false);

  useEffect(() => {
    if (!isInRuntime) {
      return;
    }

    setTimeout(() => {
      setRuntimeRenderable(true);
    }, 50); // we purposefully delay the rendering of the component to avoid hydration mismatch errors
  }, [isInRuntime]);

  return runtimeRenderable;
};

export default useRuntimeRenderable;
