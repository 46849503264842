import './styles.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@edx/paragon';
import { defineMessages, injectIntl } from 'gatsby-plugin-react-intl';

import { intlShape } from '@prospectus/common/types/intlShape';

const messages = defineMessages({
  'prospectus.badges.new_text': {
    id: 'prospectus.badges.new_text',
    defaultMessage: 'New',
    description: 'Label text on an badge item indicating that the item is new',
  },
});

function NewBadge({
  className,
  intl,
}) {
  return (
    <Badge className={`px-2 ${className}`} variant="warning">
      {intl.formatMessage(messages['prospectus.badges.new_text'])}
    </Badge>
  );
}

NewBadge.propTypes = {
  className: PropTypes.string,
  intl: intlShape.isRequired,
};

NewBadge.defaultProps = {
  className: '',
};

export default injectIntl(NewBadge);
