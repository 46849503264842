import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

import { formatSelectKey } from './formatting';

const markup = chunks => <sup className="superscript">{chunks}</sup>;

export const formatProgramType = (programType = '') => (programType ? (
  <FormattedMessage
    id="prospectus.product_display.formatted_program_type"
    description="Format program type names with necessary trademark and possessive punctuation"
    defaultMessage={'{typeKey, select, '
    + 'micromasters {{programType}<markup>®</markup> Program} '
    + 'masters {Master\'s} '
    + 'microbachelors {{programType} Program} '
    + 'other {{programType}}}'}
    values={{
      typeKey: programType.toLowerCase(),
      programType,
      markup,
    }}
  />
) : '');

export const appendProgramToProgramType = (programType = '') => (programType ? (
  <span>
    <FormattedMessage
      id="prospectus.product_display.append_program_to_type"
      description="Append 'Program' to program type names with necessary trademark and possessive punctuation"
      defaultMessage={'{typeKey, select, '
      + 'micromasters {{programType}<markup>®</markup> Program} '
      + 'masters {Master\'s Degree} '
      + 'xseries {{programType} Program} '
      + 'microbachelors {{programType}<markup>®</markup> Program}'
      + 'professional_certificate {Professional Certificate}'
      + 'other {{programType}}}'}
      values={{
        typeKey: formatSelectKey(programType),
        programType,
        markup,
      }}
    />
  </span>
) : '');

const getPossessiveString = (partnerString, programTitle) => (
  `${partnerString}${partnerString.toLowerCase().endsWith('s') ? '\'' : '\'s'} ${programTitle}`
);

export const prependProgramPartnersToTitle = (partners, programTitle) => {
  const partnerCount = partners.length;

  if (partnerCount === 1) {
    return getPossessiveString(partners[0].key, programTitle);
  }
  if (partnerCount === 2) {
    const twoPartnerString = `${partners[0].key} and ${partners[1].key}`;
    return getPossessiveString(twoPartnerString, programTitle);
  }

  const multiplePartnerString = partners.reduce((partnerString, partner, index) => {
    if (index + 1 < partnerCount) {
      return `${partnerString}${partner.key}, `;
    }

    return `${partnerString}and ${partner.key}`;
  }, '');

  return getPossessiveString(multiplePartnerString, programTitle);
};

/**
 * Given a list of products, ensures that only a max of partnerLimit products
 * are from the same partner.
 * @param {productList} - A list of product objects ranked in priority
 *                        order as defined and processed by a product area.
 * @param {partnerLimit} - The maximum number of products by the same partner
 *                         desired in the final list.
 * @returns A product list where the products from the same partner are limited to productLimit.
 */
export const limitPartnerProducts = (productList, partnerLimit) => {
  const partnerBuckets = {};
  return productList.reduce((acc, product) => {
    // Sometimes, we get owners but not authoringOrganizations.
    const orgs = product.authoringOrganizations ? product.authoringOrganizations : product.owners;
    orgs.forEach((org) => {
      // We haven't seen this org before, so set its value to 0.
      if (!(org.key in partnerBuckets)) {
        partnerBuckets[org.key] = 0;
      }
      // If we're still under the limit, increase the count and include the current element.
      if (partnerBuckets[org.key] < partnerLimit) {
        partnerBuckets[org.key] += 1;
        if (!(acc.includes(product))) {
          acc.push(product);
        }
      }
    });
    return acc;
  }, []);
};

export const getCourseCardType = (product) => {
  if (product.programs.length > 0) {
    return 'program_course';
  }

  return 'course';
};

/**
 * Given a product object, either use the given override cardType or
 * determine the type from the product's properties.
 * @param {product} - A product object representing a course, program, or
 *                    course within a program.
 * @returns A string representing what type of card should represent the
 * product.
 */
export const getCardType = (product) => {
  if (product.cardType) {
    return product.cardType;
  }

  if (Object.prototype.hasOwnProperty.call(product, 'courses')) {
    return 'program';
  }

  return getCourseCardType(product);
};

/**
 * Given a product object, and all of the programs in Prospectus, either use
 * the given override courseCount, or determine the count from the product's
 * properties.
 * For courses within a program, if they have a program in Prospectus,
 * return the number of courses in that program. Otherwise, just
 * return the number of courses in the first program on the course.
 * @param {product} - A product object representing a course, program, or
 *                    course within a program.
 * @param {allPrograms} - A list of program objects, representing all of the
 *                        programs in Prospectus.
 * @returns The count to be displayed on the product's card
 */
export const getCourseCount = (product, allPrograms) => {
  if (product.courseCount) {
    return product.courseCount;
  }

  if (Object.prototype.hasOwnProperty.call(product, 'courses')) {
    return product.courses.length;
  }

  if (product.programs.length > 0) {
    const programUuids = allPrograms.map(program => program.node.uuid);
    const matchedPrograms = product.programs.filter(program => programUuids.includes(program.uuid));

    return matchedPrograms.length > 0
      ? matchedPrograms[0].numberOfCourses
      : product.programs[0].numberOfCourses;
  }

  return 1;
};

/**
 * Given a list of products and a subject, return a list that only contains
 * products that have the given subject as its primary subject.
 * @param {subjectSlug} - a string that represents the subject's slug
 * @param {products} - A list of products. Each product much contain a subjects field,
 *                     which is an ordered list of subject objects that contain a slug.
 * @returns a list of products where the primary subject of each product is the given subject
 */
export const filterProductsBySubject = (subjectSlug, products) => products.filter(
  product => (product.subjects.length > 0 ? product.subjects[0].slug === subjectSlug : false),
);

/**
 * Given a list of products and a list of topics, return a list that only contains
 * products that have been tagged with one of the given topics.
 * @param {topics} - a list of strings that represents each topic tag
 * @param {products} - A list of products. Each product much contain a topics field,
 *                     which is a list of topic strings for that product.
 * @returns a list of products where each product contains at least one of the topics given
 */
export const filterProductsByTopics = (topics, products) => products.filter(
  product => topics.some(topic => product.legacyTopics.includes(topic)),
);

/**
 * Given a list of products, sort the list by recent enrollments, from highest to lowest
 * @param {products} - A list of products. Each product much contain a
 *                     recentEnrollmentCount field.
 * @returns a sorted list of products
 */
export const sortProductsByEnrollment = products => products.sort(
  (a, b) => b.recentEnrollmentCount - a.recentEnrollmentCount,
);

/**
 * This function fetches products based on the topics specified. Also allows the optional ability to
 * restrict those fetched products by the given subject.
 * @param {topics} - A list of strings where each string represents a topic
 * @param {sourceProducts} - A list of products. Each product much contain a subjects field,
 *                     which is an ordered list of subject objects that contain a slug and a topics
 *                     field, which is a list of topic strings.
 * @param {subjectSlug} - a string that represents the subject's slug. Only has a value if
 *                        we're forced to filter by subject.
 * @returns a list of products that contain the given topics
 */
export const fetchProductsByTopics = (topics, sourceProducts, subjectSlug) => {
  let products = filterProductsByTopics(topics, sourceProducts);

  if (subjectSlug) {
    products = filterProductsBySubject(subjectSlug, products);
  }
  return products;
};

/**
 * Given a list of products, a subject, and a list of topics, return the correct set of
 * products representing an intersection of topics and subjects.
 * @param {topics} - A list of strings where each string represents a topic
 * @param {sourceProducts} - A list of products. Each product much contain a subjects field,
 *                     which is an ordered list of subject objects that contain a slug and a topics
 *                     field, which is a list of topic string.
 * @param {forceSubject} - boolean value indicating whether or not to restrict returned products to
 *                        the given subject
 * @param {subjectSlug} - A string that matches the slug of the subject of this product area
 * @returns a list of products matching the topics or subject
 */
export const fetchProducts = (topics, sourceProducts, forceSubject, subjectSlug) => {
  if (topics && topics.length > 0) {
    const topicNames = topics.map(topic => topic.topicName);
    return fetchProductsByTopics(topicNames, sourceProducts, forceSubject && subjectSlug);
  }
  return filterProductsBySubject(subjectSlug, sourceProducts);
};

/**
 * Given two lists of products, remove any duplicates between the first and second lists from
 * the second list.
 * @param {definedProducts} - A list of products. Each product has a uuid.
 * @param {fetchedProducts} - A list of products.  Each product has a uuid.
 * @returns a copy of fetchedProducts with any duplicates between it an definedProducts removed.
 */
export const removeDuplicateProducts = (definedProducts, fetchedProducts) => {
  const productUuids = definedProducts.map(product => product.uuid);
  return fetchedProducts.filter(product => productUuids.indexOf(product.uuid) < 0);
};

/**
 * Derive the complete product list based on our existing filters.
 * @param {productArea} - The product area object pulled from the Netlify data
 * @param {definedProducts} - A list of products that contains all of the typical
 *                            information from the discovery API responses.
 * @param {courses} - A list of course objects taken from discovery API responses
 * @param {programs} - A list of programs objects taken from discovery API responses
 * @param {allProducts} - A list of products that have been displayed already.
 * @param {subjectSlug} - A string that matches the slug of the subject of this product area
 * @returns a list of products with all of the filters applied
 */
export const fetchFilteredProductList = (
  productArea,
  definedProducts,
  courses,
  programs,
  allProducts,
  subjectSlug,
) => {
  const {
    productLimit,
    partnerLimit,
    productType,
    forceSubject,
    topics,
  } = productArea;

  let fetchedProducts = [];
  let sourceProducts = [];
  let completeProductList = [];

  switch (productType) {
    case 'program':
      sourceProducts = programs;
      break;
    case 'mixed': {
      sourceProducts = courses.concat(programs);
      break;
    }
    case 'course':
    default:
      sourceProducts = courses;
      break;
  }

  fetchedProducts = fetchProducts(topics, sourceProducts, forceSubject, subjectSlug);
  fetchedProducts = sortProductsByEnrollment(fetchedProducts);
  // Remove duplicates that are already defined in the product list.
  fetchedProducts = removeDuplicateProducts(definedProducts.concat(allProducts), fetchedProducts);
  completeProductList = definedProducts.concat(fetchedProducts);

  // Limit the number of products per partner that can appear in the list.
  completeProductList = limitPartnerProducts(completeProductList, partnerLimit);

  // Limit the number of total products returned.
  return completeProductList.slice(0, Math.min(productLimit, completeProductList.length));
};
