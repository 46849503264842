import './styles.scss';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import CondensedCardContainer from '@prospectus/common/ui-strict/CondensedCard/wrapper';

import { NavigationCardListPropType } from './types';

type NavigationCardViewPropType = {
  items: NavigationCardListPropType[];
};

const addNavParamToCta = (cta, isLast = false) => {
  if (cta?.url) {
    // const delimiter = cta.url.includes('?') ? '&' : '?';
    return {
      ...cta,
      isLast,
      // Temporarily disabled as part of [WS-4465](https://2u-internal.atlassian.net/browse/WS-4465)
      // url: `${cta.url}${delimiter}referredFrom=edxNavigation`,
    };
  }
  return cta;
};

function NavigationCardView({ items }: NavigationCardViewPropType) {
  return (
    <div className="mega-nav-grid-view d-flex flex-row flex-wrap">
      {items.length === 1 ? (
        <CondensedCardContainer
          productList={items[0].products}
          productsTitle={items[0].heading}
          cta={addNavParamToCta(items[0].cta)}
          shouldWrap
        />
      ) : items.map((item, index) => {
        const isLast = index === items.length - 1;
        return (
          <div className="mega-nav-grid-item" key={`nav-grid-${uuidv4()}`}>
            <CondensedCardContainer
              productList={item.products}
              productsTitle={item.heading}
              cta={addNavParamToCta(item.cta, isLast)}
              additionalClassNames="flex-column"
              shouldWrap
            />
          </div>
        );
      })}
    </div>
  );
}

export default NavigationCardView;
