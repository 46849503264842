const degreesToRedirect = [
  '4c073cbf-7447-4659-8ef2-b0123432d282', // AU Master of Science in Marketing Analytics
  'cd6ca42b-a847-4964-a2e8-f06458cb2bc8', // Pepperdine Master of Dispute Resolution
  '099a275f-63d0-43cf-96b0-c1fc624dc466', // Tufts Master of Public Health
  '7316b4ae-adba-4dde-a14e-b3e7b1574ce0', // Tufts Master of Science in Health Informatics and Analytics
  '8fe332a9-b4ae-49b6-b131-31f8587cc9d8', // UDayton Principal Licensure Preparation Program
  'bddda93e-04bc-42e6-8708-7eb7571582e9', // UNC-CH Master of Public Health, Registered Dietitian
  '518a3af8-36fa-485d-868e-43f3b52599ee', // Denver Master of Business Administration
  '1f5b1e98-65a5-409d-818b-32fdfb22c67d', // Vanderbilt Master of Engineering in Engineering Management
  '8eed56a6-7655-405e-bccc-d18d1a538e4b', // Vanderbilt Master of Science in Computer Science
];

const bootCampsToRedirect = [
  '55255e36-1791-4721-bd3d-802eab45c1dd', // ASU Coding Boot Camp
  'f8b6b5e6-2509-4d51-957c-bc85f348d26d', // Berkeley Technology Project Management Boot Camp
  '90caf06b-bc16-466f-80bf-3aa938e659d8', // Monash Digital Marketing Boot Camp
  'ff3ede1b-e83d-4f3d-b307-174b10afbc3b', // Monash Cybersecurity Boot Camp
  'e14e84e2-21dd-401d-b4cf-078832fa4493', // Monash Data Analytics Boot Camp
  '0d0395c4-5724-48b1-8d86-4a3fadb656bf', // Monash FinTech Boot Camp
  '455cbd48-c39a-4872-852c-cc3f2d8934eb', // Monash Coding Boot Camp
  '8aa6af4c-339e-4786-8577-9e30b75f3b14', // Monash UX/UI Boot Camp
];

const productsToRedirect = [...degreesToRedirect, ...bootCampsToRedirect];

const shouldRedirect = (uuid) => productsToRedirect.includes(uuid);

module.exports = {
  degreesToRedirect,
  shouldRedirect,
};
