import './styles.scss';
import React from 'react';
import track from 'react-tracking';
import { Icon, IconButton } from '@edx/paragon';
import { Close } from '@edx/paragon/icons';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';

import { CLOSE_MEGA_NAV } from '@prospectus/common/ui-strict/MegaNav/data/constants';
import { useMegaNav, useMegaNavDispatch } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';
import { emitEventIfAble } from '@utils/analytics';
import isUserLoggedIn from '@utils/user';

interface IDissmissButtonProps {
  tracking: object;
}

const messages = defineMessages({
  'prospectus.mega-nav.dismiss': {
    id: 'prospectus.mega-nav.dismiss',
    description: 'Button to dismiss the main menu',
    defaultMessage: 'Dismiss main menu',
  },
});

function DismissButton({ tracking }: IDissmissButtonProps) {
  const intl = useIntl();
  const { activeCategory } = useMegaNav();
  const dispatch = useMegaNavDispatch();

  const handleClick = () => {
    emitEventIfAble('edx.bi.mega-nav.close-menu', {
      ...tracking,
      category: activeCategory,
      loggedIn: isUserLoggedIn(),
    });
    dispatch({ type: CLOSE_MEGA_NAV });
  };

  return (
    <IconButton
      className="mega-nav-dismiss-btn position-absolute text-white d-none d-md-flex"
      variant="light"
      src={Close}
      iconAs={Icon}
      onClick={handleClick}
      alt={intl.formatMessage(messages['prospectus.mega-nav.dismiss'])}
    />
  );
}

export default track({
  component: 'mega-nav-dismiss-button',
})(DismissButton);
