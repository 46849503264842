import React from 'react';
import slugify from 'slugify';
import classNames from 'classnames';

import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import LinkWithVisibilityTracking from '@prospectus/common/ui-strict/LinkWithVisibilityTracking';
import { FLYOUT_ID } from '@prospectus/common/ui-strict/MegaNav/data/constants';
import isUserLoggedIn from '@utils/user';

import MenuHero from '../MenuHero';
import MobileBackButton from '../MobileBackButton';
import { useCareerFlyoutData } from './useCareerFlyoutData';

function NavigationCareerFlyout() {
  const { menuLabel, hero, navigationLinkLists } = useCareerFlyoutData();
  const isMobile = useBreakpointMatch('medium');

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    <div
      id={FLYOUT_ID}
      className="mega-nav-flyout d-flex flex-column w-100 bg-white"
      tabIndex={0}
    >
      {isMobile && <MobileBackButton />}
      {hero && <MenuHero {...hero} title={menuLabel} />}
      {navigationLinkLists?.at(0) && (
        <div className="row p-4.5 m-0">
          {navigationLinkLists.map((item, index) => (
            <div key={item.heading} className="col col-12 col-md-6 col-lg-4 mb-5">
              <h4>{item.heading}</h4>
              <p className="small">{item.description}</p>
              {item.links?.at(0) && (
                <ul className="list-unstyled">
                  {item.links.map((link) => (
                    <li>
                      <LinkWithVisibilityTracking
                        className="small"
                        url={link.url}
                        eventName={`edx.bi.mega-nav.career-flyout.${slugify(
                          link.label,
                          { lower: true },
                        )}`}
                        additionalLinkProps={{
                          loggedIn: isUserLoggedIn(),
                          navigationLink: true,
                        }}
                      >
                        {link.label}
                      </LinkWithVisibilityTracking>
                    </li>
                  ))}
                </ul>
              )}
              {item.cta && (
                <LinkWithVisibilityTracking
                  className={classNames('career-flyout-cta small', {
                    'btn btn-outline-primary btn-sm': item.ctaVariant === 'Button',
                    'last-cta': index === navigationLinkLists.length - 1,
                  })}
                  url={item.cta.url}
                  eventName={`edx.bi.mega-nav.career-flyout.cta.${slugify(
                    item.cta.label,
                    { lower: true },
                  )}`}
                  additionalLinkProps={{
                    loggedIn: isUserLoggedIn(),
                    navigationLink: true,
                  }}
                >
                  {item.cta.label}
                </LinkWithVisibilityTracking>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default NavigationCareerFlyout;
