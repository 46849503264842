import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';
import { submitEvent } from '@utils/analytics';

import useOptimizelyVariation from '@prospectus/common/ui/xpert/shared/hooks/useOptimizelyVariation';
import ProactiveMessage from '@prospectus/common/ui/xpert/shared/ProactiveMessage';

const setting = {
  color: {
    theme: '#454545',
  },
  webWidget: {
    offset: {
      vertical: '170px',
      mobile: {
        vertical: '250px',
      },
    },
    launcher: {
      chatLabel: {
        '*': 'Chat',
      },
      label: {
        '*': 'Chat',
      },
      mobile: {
        labelVisible: false,
      },
    },
    contactForm: {
      ticketForms: [{
        id: 12915314479383,
        subject: false,
      }],
      attachments: false,
    },
  },
};

function ZendeskChat(props) {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const isMobile = useBreakpointMatch('medium');
  const experimentVariation = useOptimizelyVariation('maasZendeskChatExperiment');

  const handleEvent = (event) => {
    if (event?.action === 'Web Widget Opened') {
      submitEvent(
        props.tracking,
        {
          category: '2u',
          page: props.pageName,
          label: 'zendeskChat',
        },
        'edx.bi.experiment.chatbot.painted.door.clicked.v2',
      );

      setOpen(true);
    }
  };

  const onZendeskLoaded = () => {
    ZendeskAPI('webWidget:on', 'userEvent', handleEvent);
  };

  if (!experimentVariation || !process.env.GATSBY_ZENDESK_KEY) {
    return null;
  }

  const handleProactiveMessageClick = () => {
    setOpen(true);
    submitEvent(props.tracking, {
      category: '2u',
      page: props.pageName,
      label: 'chatbot',
    }, 'edx.bi.experiment.chatbot.proactive.message.clicked');
  };

  const handleProactiveMessageClose = () => {
    submitEvent(props.tracking, {
      category: '2u',
      page: props.pageName,
      label: 'chatbot',
    }, 'edx.bi.experiment.chatbot.proactive.message.closed');
  };

  return (
    <div>
      <ProactiveMessage
        style={{ bottom: isMobile ? '20.5rem' : '15.5rem', right: isMobile ? '10px' : '1.5rem' }}
        isShown={!open}
        onClose={handleProactiveMessageClose}
        onClick={handleProactiveMessageClick}
      />
      <Zendesk defer ref={ref} zendeskKey={process.env.GATSBY_ZENDESK_KEY} {...setting} onLoaded={onZendeskLoaded} />
    </div>
  );
}

ZendeskChat.propTypes = {
  tracking: PropTypes.shape().isRequired,
  pageName: PropTypes.string.isRequired,
};

export default ZendeskChat;
