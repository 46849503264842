import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, FormattedMessage } from 'gatsby-plugin-react-intl';
import { getTopicUrl } from '@utils/topics';
import { coursesList } from '@utils/courses';
import { intlShape } from '@prospectus/common/types/intlShape';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import SiteNavLink from './SiteNavLink';

const messages = defineMessages({
  'prospectus.header.site_header.site_nav.courses_heading': {
    id: 'prospectus.header.site_header.site_nav.courses_heading',
    defaultMessage: 'Courses',
    description: 'Heading for the courses menu on nav bar',
  },
  'prospectus.header.site_header.site_nav.courses_heading.description': {
    id: 'prospectus.header.site_header.site_nav.courses_heading.description',
    defaultMessage: 'Courses menu',
    description: 'Screen reader description for the heading of a courses menu',
  },
});

function CoursesSubmenu({ orderedSubjectList, intl }) {
  return (
    <SiteNavLink
      id="courses"
      className="visible-desktop"
      title={intl.formatMessage(messages['prospectus.header.site_header.site_nav.courses_heading'])}
      url="search/?tab=course"
      buttonDescription={intl.formatMessage(messages['prospectus.header.site_header.site_nav.courses_heading.description'])}
      eventName="edx.bi.link.site-nav.courses.clicked"
      htmlHeader={(
        <header className="tab-header">
          <h2>
            <ProspectusLink
              href="subjects?linked_from=sitenav"
              className="main-nav-heading"
              analytics={{
                eventName: 'edx.bi.link.site-nav.subject-heading.clicked',
              }}
            >
              <FormattedMessage
                id="prospectus.header.site_header.site_nav.courses.subjects_heading.link_text"
                defaultMessage="Courses by Subject"
                description="Links user to subject page"
              />
            </ProspectusLink>
          </h2>
          <ProspectusLink
            className="btn "
            href="search?tab=course"
            analytics={{
              eventName: 'edx.bi.link.site-nav.all-courses.clicked',
            }}
          >
            <FormattedMessage
              id="prospectus.header.site_header.site_nav.courses.all_courses_button.button_text"
              defaultMessage="View All Subjects"
              description="Text for button that takes user to see courses in catalog"
            />
          </ProspectusLink>
        </header>
    )}
      htmlContent={(
        <div className="tab-content">
          <div className="item-list">
            <ul className="col-ul disco-hack-main-nav-ul">
              {intl.locale === 'en' && coursesList.map(({ name, link }) => (
                <li className="col-li" key={name}>
                  <ProspectusLink
                    href={link.concat('?linked_from=sitenav&list=subjects')}
                    analytics={{
                      eventName: 'edx.bi.link.site-nav.subjects.clicked',
                      linkProps: {
                        subject: name,
                      },
                    }}
                  >
                    {name}
                  </ProspectusLink>
                </li>
              ))}
              {intl.locale === 'es' && orderedSubjectList.map(({ node }) => (
                <li className="col-li" key={node.uuid}>
                  <ProspectusLink
                    href={getTopicUrl(intl.locale, node.slug).concat('?linked_from=sitenav&list=subjects')}
                    analytics={{
                      eventName: 'edx.bi.link.site-nav.subjects.clicked',
                      linkProps: {
                        subject: node.name,
                      },
                    }}
                  >
                    {node.labels[intl.locale]}
                  </ProspectusLink>
                </li>
              ))}
              <li className="col-li" key="all-subjects">
                <ProspectusLink
                  href={`${intl.locale === 'es' ? 'es/aprende' : 'learn'}?linked_from=sitenav`}
                  analytics={{
                    eventName: 'edx.bi.link.site-nav.all-subjects.clicked',
                  }}
                >
                  <FormattedMessage
                    id="prospectus.header.site_header.site_nav.courses.all_subjects_link.link_text"
                    defaultMessage="All Subjects »"
                    description="Link text for the 'all subjects' link in the courses section of the site nav"
                  />
                </ProspectusLink>
              </li>
            </ul>
          </div>
        </div>
      )}
    />
  );
}

CoursesSubmenu.propTypes = {
  orderedSubjectList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CoursesSubmenu);
