import React from 'react';
import classNames from 'classnames';
import { Button, Icon } from '@edx/paragon';
import { ArrowBackIos } from '@edx/paragon/icons';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';

import { CLEAR_ACTIVE_CATEGORY } from '@prospectus/common/ui-strict/MegaNav/data/constants';
import { useMegaNavDispatch } from '@prospectus/common/ui-strict/MegaNav/data/MegaNavContext';

const messages = defineMessages({
  'prospectus.mega-nav.mobile-back-button': {
    id: 'prospectus.mega-nav.mobile-back-button',
    description: 'Button to go back to the main menu',
    defaultMessage: 'Back to menu',
  },
});

function MobileBackButton() {
  const intl = useIntl();
  const dispatch = useMegaNavDispatch();

  const handleBack = () => {
    dispatch({ type: CLEAR_ACTIVE_CATEGORY });
  };

  return (
    <Button
      className={classNames(
        'd-flex justify-content-start align-items-center flyout-back-button',
      )}
      variant="link"
      onClick={handleBack}
    >
      <Icon src={ArrowBackIos} size="xs" />
      <span className="small ml-2">{intl.formatMessage(messages['prospectus.mega-nav.mobile-back-button'])}</span>
    </Button>
  );
}

export default MobileBackButton;
