import { CategoriesType } from '../data/types';
import { sortProductsByUuidList } from './sortProductsByUuidList';

export const sortMegaNavCategories = (categories: CategoriesType) => {
  if (!categories) {
    return null;
  }

  const sortedCategories = Object.entries(categories).reduce((acc, [key, value]) => {
    if (value?.navigationLinkLists?.length > 0) {
      const sortedNavigationLinkLists = value.navigationLinkLists.map((list) => {
        if (list?.products?.length > 0) {
          const sortedProducts = sortProductsByUuidList(list.products, list.productUuids);

          return {
            ...list,
            products: sortedProducts,
          };
        }

        return list;
      });

      return {
        ...acc,
        [key]: { ...value, navigationLinkLists: sortedNavigationLinkLists },
      };
    }

    return { ...acc, [key]: value };
  }, {});

  return sortedCategories;
};
