import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { BasicLinkPropType } from '@prospectus/common/types/commonPropTypes';
import LinkWithVisibilityTracking from '@prospectus/common/ui-strict/LinkWithVisibilityTracking';
import isUserLoggedIn from '@utils/user';

type TypeAllTopicsView = {
  topicLinkLists: Array<{
    heading: string;
    headingLink: string;
    links: Array<BasicLinkPropType>;
  }>;
};

function AllTopicsView({ topicLinkLists }: TypeAllTopicsView) {
  return (
    <div className="container mb-5">
      <div className="row" data-cy="all-topics-container">
        {topicLinkLists.map((linkList, index) => {
          const lastList = index === topicLinkLists.length - 1;
          return (
            <div key={`topic-link-list-${uuidv4()}`} className="col col-12 col-md-6 col-lg-4 mb-5" data-cy="topic-list">
              <h4 className="h4">
                {linkList?.headingLink
                  ? (
                    <LinkWithVisibilityTracking
                      url={linkList.headingLink}
                      eventName="edx.bi.user.mega-nav.all-topics-view.link.clicked"
                      additionalLinkProps={{
                        loggedIn: isUserLoggedIn(),
                        navigationLink: true,
                      }}
                    >
                      {linkList.heading}
                    </LinkWithVisibilityTracking>
                  )
                  : linkList.heading}
              </h4>
              {linkList.links.map((link, idx) => (
                <div
                  key={`topic-link-${uuidv4()}`}
                  className={classNames(
                    'd-block small',
                    { 'last-cta': idx === linkList.links.length - 1 && lastList },
                  )}
                  data-cy="topic-link"
                >
                  <LinkWithVisibilityTracking
                    url={link.url}
                    eventName="edx.bi.user.mega-nav.all-topics-view.link.clicked"
                    additionalLinkProps={{
                      loggedIn: isUserLoggedIn(),
                      navigationLink: true,
                      category: link.label,
                    }}
                  >
                    {link.label}
                  </LinkWithVisibilityTracking>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AllTopicsView;
