import { defineMessages } from 'gatsby-plugin-react-intl';

const messages = defineMessages({
  'prospectus.mega-nav.learn-menu-button.default': {
    id: 'prospectus.mega-nav.learn-menu-button.default',
    description: 'Link text for the Account accordion label in the menu for logged-in users',
    defaultMessage: 'Learn',
  },
});

export default messages;
