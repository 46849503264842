import React from 'react';
import InnerBaseCard from '@prospectus/common/ui-strict/BaseCard/InnerBaseCard';
import LinkWithVisibilityTracking from '@prospectus/common/ui-strict/LinkWithVisibilityTracking';
import {
  getFinalUrl,
  getAnalytics,
} from '@prospectus/common/ui-strict/CondensedCard/utils/utils';
import { CondensedCardType, CondensedCardDefaultProps } from './types';

function CondensedCard(props: CondensedCardType) {
  const {
    schoolLogo,
    title,
    schoolName,
    handleOnClick,
    isLoading,
    uuid,
    variant,
    shouldfirePreQuerySegmentEvent,
    owners,
  } = props;

  const lobExperimentVariation = '';
  const analytics = getAnalytics(props);
  const finalUrl = getFinalUrl({ ...props, lobExperimentVariation });

  const multipleSchoolName = [];
  const isMultipleOwner = owners?.length > 1;
  if ((isMultipleOwner)) {
    owners.forEach((owner, index, arr) => {
      let school;
      if (index === arr.length - 1) {
        school = (
          <span>{owner.name}</span>
        );
      } else {
        school = (
          <>
            <span>{owner.name}</span>
            <br />
          </>
        );
      }

      multipleSchoolName.push(school);
    });
  }

  return (
    <div className="base-card-wrapper">
      <LinkWithVisibilityTracking
        className="base-card-link"
        url={finalUrl}
        eventName={analytics.eventName}
        additionalLinkProps={analytics.linkProps}
      >
        <InnerBaseCard
          aria-label={title}
          titleTruncate={1}
          customHeaderImage=""
          schoolLogo={isMultipleOwner ? '' : schoolLogo}
          title={title}
          uuid={uuid}
          subtitle={isMultipleOwner ? multipleSchoolName : schoolName}
          productTypeCopy=""
          variant={variant}
          handleOnClick={handleOnClick}
          isLoading={isLoading}
          shouldfirePreQuerySegmentEvent={shouldfirePreQuerySegmentEvent}
          footer={null}
        />
      </LinkWithVisibilityTracking>
    </div>
  );
}

CondensedCard.defaultProps = CondensedCardDefaultProps;

export default CondensedCard;
