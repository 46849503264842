import './styles.scss';
import React from 'react';
import classNames from 'classnames';

import useBreakpointMatch from '@prospectus/common/hooks/useBreakpointMatch';

import { MenuProductSummaryPropType } from '../MenuProductSummary/types';
import MenuProductSummary from '../MenuProductSummary';

type NavigationContainerPropType = {
  desktopCols: number;
  cardList: MenuProductSummaryPropType[];
};

function NavigationContainer({
  desktopCols,
  cardList,
}: NavigationContainerPropType) {
  const isMobile = useBreakpointMatch('small');

  return (
    <div className={classNames('navigation-container', !isMobile ? 'row' : '')}>
      <div className={isMobile ? 'pt-3 pb-3' : 'col pl-3'}>
        <MenuProductSummary {...cardList[0]} />
      </div>
      <div
        className={
          isMobile
            ? 'pt-3 pb-3 divider-nav-container-top'
            : 'col divider-nav-container-left pl-3'
        }
      >
        <MenuProductSummary {...cardList[1]} />
      </div>

      {desktopCols === 3 && cardList.length === 3 && (
        <div
          className={
            isMobile
              ? 'pt-3 pb-3 divider-nav-container-top'
              : 'col divider-nav-container-left pl-3'
          }
        >
          <MenuProductSummary {...cardList[2]} />
        </div>
      )}
    </div>
  );
}

export default NavigationContainer;
