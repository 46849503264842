import React, {
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon, IconButton } from '@edx/paragon';
import { ExpandMore } from '@edx/paragon/icons';

import { useTracking } from 'react-tracking';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import { submitEvent } from '@utils/analytics';

function SiteNavLink({
  id,
  className,
  title,
  url,
  buttonDescription,
  htmlHeader,
  htmlContent,
  eventName,
}) {
  const [active, setActive] = useState(false);
  const isActive = active === id;
  const triggerId = `menu-trigger-${id}`;
  const classNameList = isActive ? '' : 'hidden';
  const tracking = useTracking();
  let tabGroup;
  let triggerButton;

  const closeTab = () => {
    setActive(false);
  };

  const handleKeypress = (event) => {
    const key = event.code || event.key;
    const { classList } = event.target;

    if (key === 'Escape') {
      closeTab();
      triggerButton.focus();
    } else if (key === 'Tab' && event.shiftKey && classList.contains('first-focus')) {
      event.preventDefault();
      tabGroup.querySelector('.last-focus').focus();
    } else if (key === 'Tab' && !event.shiftKey && classList.contains('last-focus')) {
      triggerButton.focus();
    }
  };

  const onHoverEvent = () => {
    const componentProps = {
      category: 'navigation',
    };
    if (eventName.includes('programs')) {
      submitEvent(tracking, componentProps, 'edx.bi.link.site-nav.programs-degrees.expanded');
    } else if (eventName.includes('courses')) {
      submitEvent(tracking, componentProps, 'edx.bi.link.site-nav.courses.expanded');
    } else if (eventName.includes('bootcamps')) {
      submitEvent(tracking, componentProps, 'edx.bi.link.site-nav.bootcamps.expanded');
    }
  };

  const hoverToggleTabDisplay = () => {
    const isActived = id === active ? null : id;
    if (isActived) { onHoverEvent(); }
    setActive(isActived);
  };

  return (
    <li
      className={classNames('menu-link menu-open', className)}
      onKeyDown={handleKeypress}
      onMouseEnter={hoverToggleTabDisplay}
      onMouseLeave={hoverToggleTabDisplay}
      role="presentation"
    >
      <ProspectusLink
        href={url}
        className={classNames('menu-item', { 'is-open': isActive })}
        analytics={{
          eventName,
        }}
      >
        {title}
      </ProspectusLink>
      <div
        className="nav-bar-group"
        ref={(group) => { tabGroup = group; }}
      >
        <IconButton
          src={ExpandMore}
          iconAs={Icon}
          alt={buttonDescription}
          id={triggerId}
          className="menu-btn h-100"
          aria-expanded={isActive}
          onClick={hoverToggleTabDisplay}
          type="button"
          ref={(button) => { triggerButton = button; }}
        />
        {isActive
            && (
            <div
              id={`main-nav-${id}`}
              role="region"
              aria-labelledby={`menu-tab-view-${id}`}
              className={classNames('menu-tab menu-open', id, classNameList)}
              onMouseEnter={onHoverEvent}
            >
              {htmlHeader}
              {htmlContent}
            </div>
            )}
      </div>
    </li>
  );
}

SiteNavLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  buttonDescription: PropTypes.string.isRequired,
  htmlHeader: PropTypes.element.isRequired,
  htmlContent: PropTypes.element.isRequired,
  eventName: PropTypes.string.isRequired,
};

SiteNavLink.defaultProps = {
  className: '',
};

export default SiteNavLink;
