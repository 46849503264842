const coursesList = [
  {
    name: 'Artificial Intelligence',
    link: '/learn/artificial-intelligence',
  },
  {
    name: 'Python',
    link: '/learn/python',
  },
  {
    name: 'Data Science',
    link: '/learn/data-science',
  },
  {
    name: 'Finance',
    link: '/learn/finance',
  },
  {
    name: 'Machine Learning',
    link: '/learn/machine-learning',
  },
  {
    name: 'Computer Programming',
    link: '/learn/computer-programming',
  },
  {
    name: 'Data Analysis',
    link: '/learn/data-analysis',
  },
  {
    name: 'SQL',
    link: '/learn/sql',
  },
  {
    name: 'Business Administration',
    link: '/learn/business-administration',
  },
  {
    name: 'Leadership',
    link: '/learn/leadership',
  },
  {
    name: 'Economics',
    link: '/learn/economics',
  },
  {
    name: 'Statistics',
    link: '/learn/statistics',
  },
  {
    name: 'Writing',
    link: '/learn/writing',
  },
  {
    name: 'Sales',
    link: '/learn/sales',
  },
  {
    name: 'Psychology',
    link: '/learn/psychology',
  },
  {
    name: 'Biology',
    link: '/learn/biology',
  },
  {
    name: 'JavaScript',
    link: '/learn/javascript',
  },
  {
    name: 'Supply Chain Management',
    link: '/learn/supply-chain-management',
  },
  {
    name: 'R',
    link: '/learn/r-programming',
  },
  {
    name: 'Algorithms',
    link: '/learn/algorithms',
  },
  {
    name: 'Physics',
    link: '/learn/physics',
  },
  {
    name: 'Marketing',
    link: '/learn/marketing',
  },
  {
    name: 'Linux',
    link: '/learn/linux',
  },
  {
    name: 'C',
    link: '/learn/c-programming',
  },
  {
    name: 'Spanish',
    link: '/learn/spanish',
  },
  {
    name: 'Probability',
    link: '/learn/probability',
  },
  {
    name: 'Data Structures',
    link: '/learn/data-structures',
  },
  {
    name: 'Innovation',
    link: '/learn/innovation',
  },
  {
    name: 'Cybersecurity',
    link: '/learn/cybersecurity',
  },
  {
    name: 'HTML',
    link: '/learn/html',
  },
  {
    name: 'Software Engineering',
    link: '/learn/software-engineering',
  },
  {
    name: 'Web Development',
    link: '/learn/web-development',
  },
];

const noRatingCourses = [
  'a086516d-a287-4d49-8af7-b8b1ecb5dd33',
  'be6f00d4-5047-459e-8249-954e2526f68a',
  '5cc1b3b5-e6cd-474d-b08b-7b4d672ac7a3',
  'd5a7dfe1-6579-4b2a-b16c-8900f60968ef',
  'f315bd15-ca6c-4c5d-aa20-8584656e30d1',
  '539e0847-deeb-43d2-a6d0-258fad9fcba9',
  '28d42997-fce9-4586-af18-113b9cd8e916',
  '956ae690-25d8-4524-9d7a-ceb33204fe8f',
  '8a1c5e35-6538-428c-a707-27d94c907187',
  'cb9eacc2-8af3-4216-808e-daa4b41bdd95',
  '6a1a75c6-e208-4a8d-a73b-62d4a3e0f5a0',
  '100103b4-353a-457b-927c-4a3f518cecc5',
  '5d81639b-9ad6-4288-90de-8f733e1845d6',
  '389d5e5e-7679-467e-b5bb-b21c9c86ca1f',
  '20e2ce7f-0e98-43eb-a956-ac820d822afd',
  '20e2ce7f-0e98-43eb-a956-ac820d822afd',
  '1453a854-cccb-4f7f-8668-d6b15ec4fb18',
  '1ee8e1c7-85c7-4182-a08d-e2a48e3946f8',
  '1e13fdc5-8cb9-4559-ab59-222ec1855d34',
  '1bf266b1-0a55-43e5-ae9f-f0c9a51aa515',
  'a4d6f55d-1a06-4736-a297-88e964f35e98',
  '197f9db0-cc55-43fe-aac7-c5a951b0e3b4',
  '374f2ac3-b3d8-4f77-89f3-2bf0311d129c',
  '84251067-b212-4355-a9d3-246d91896b90',
  '52ec288b-8283-4c96-96ca-4da99828701b',
  '3dbb7e34-528e-4bf9-a64a-f021c4161fdd',
  'd12ac3f6-79c9-4a53-89de-96f927808538',
  '6d3bb404-bb82-490f-874d-ae3d3f25be2a',
  'c49dec27-a764-409d-b909-74923f629ebb',
  'c628b4fa-2f66-4f44-b24b-97210ae774c5',
  '9f0c024a-13b5-4ccc-9de6-2558e0db592f',
  '5dfdf21b-64cc-4810-b3b6-f3e3c3341af7',
];

module.exports = {
  coursesList,
  noRatingCourses,
};
