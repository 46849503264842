import Cookies from 'js-cookie';

export default function isValidAudience() {
  const currentUrl = window.location.href;
  const hasBaseDomainUrl = currentUrl.startsWith('https://www.edx.org') || currentUrl.startsWith('http://localhost:5335');

  const languageCookieValue = Cookies.get('prod-edx-language-preference');
  const hasEnglishCookieValue = languageCookieValue?.startsWith('en');
  const isSpanishUrl = currentUrl.startsWith('https://www.edx.org/es') || currentUrl.startsWith('http://localhost:5335/es');

  if (!hasBaseDomainUrl) {
    return false;
  }

  if (languageCookieValue && !hasEnglishCookieValue) {
    return false;
  }

  if (isSpanishUrl) {
    return false;
  }

  return true;
}
