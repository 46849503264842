const PRODUCT_INDEX_EN = 'product';
const PRODUCT_INDEX_ES = 'spanish_product';

const useAlgoliaProductIndex = (intl) => {
  if (intl.locale === 'es') {
    return PRODUCT_INDEX_ES;
  }

  return PRODUCT_INDEX_EN;
};

export default useAlgoliaProductIndex;
