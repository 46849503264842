import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  ReactNode,
} from 'react';
import { Button } from '@edx/paragon';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

function DefaultMoreText() {
  return (
    <FormattedMessage
      id="prospectus.expandable-list.show-more"
      description="Button to show more content"
      defaultMessage="Show more"
    />
  );
}

function DefaultLessText() {
  return (
    <FormattedMessage
      id="prospectus.expandable-list.show-less"
      description="Button to show less content"
      defaultMessage="Show less"
    />
  );
}

type ExpandableListType = {
  children: ReactNode[];
  className?: string;
  lessText?: string | ReactNode;
  maxRows?: number;
  moreText?: string | ReactNode;
  // Include any extra height added by margins/gap/etc. Padding and borders are already accounted for in offsetHeight.
  extraHeight?: number;
};

function ExpandableList({
  children,
  className,
  lessText,
  maxRows,
  moreText,
  extraHeight,
}: ExpandableListType) {
  const containerRef = useRef(null);
  const listRef = useRef(null);

  const [isMounted, setIsMounted] = useState(false);
  const [rowHeight, setRowHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const handleFocus = () => {
    // When tabbing to the element, expand it automatically so that it doesn't start scrolling through hidden items
    if (isOverflow) {
      setExpanded(true);
    }
  };

  const handleClick = e => {
    e.preventDefault();
    // Prevent focus from jumping to another element
    containerRef?.current?.focus();
    setExpanded(!expanded);
  };

  useLayoutEffect(() => {
    const { current } = listRef;

    if (current) {
      if (current.childNodes) {
        // Calculate row height by element's offset height + extra height if specified
        const firstChild = [...current.childNodes].find(child => child.offsetHeight);
        const elementHeight = firstChild?.offsetHeight || 0;
        setRowHeight(elementHeight + extraHeight);
      }

      const trigger = () => {
        const hasOverflow = current.scrollHeight > current.clientHeight;
        setIsOverflow(hasOverflow);
      };

      // Determine if there is overflow content. If there isn't, then the expand button won't appear.
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [listRef]);

  useEffect(() => {
    // Prevent "show more" button from flashing
    setIsMounted(true);
  }, []);

  return (
    <div className={className} ref={containerRef} tabIndex={-1}>
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      {/* This is hacky. We need a way to expand the container on tab without being able to target the child
        * elements. */}
      <div
        ref={listRef}
        className="expandable-list-items d-flex flex-wrap overflow-hidden"
        style={{ maxHeight: expanded ? 'none' : maxRows * rowHeight }}
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        onFocus={handleFocus}
        onMouseDown={e => { e.preventDefault(); }} // Prevent container from expanding when clicking a list item
        data-testid="expandable-list-items"
      >
        {children}
      </div>
      {/* eslint-enable jsx-a11y/no-static-element-interactions */}
      {(isMounted && (expanded || isOverflow)) && (
        <Button
          className="expandable-list-btn px-0"
          variant="link"
          size="inline"
          onClick={handleClick}
          aria-hidden
        >
          {expanded ? lessText : moreText}
        </Button>
      )}
    </div>
  );
}

ExpandableList.defaultProps = {
  className: '',
  extraHeight: 0,
  lessText: <DefaultLessText />,
  maxRows: 2,
  moreText: <DefaultMoreText />,
};

export default ExpandableList;
