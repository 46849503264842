import React, { useEffect, useState } from 'react';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { intlShape } from '@prospectus/common/types/intlShape';
import ProspectusLink from '@prospectus/common/ui-strict/ProspectusLink';
import NewBadge from '@prospectus/common/ui/NewBadge';
import SiteNavLink from './SiteNavLink';
import messages from '../constant/messages';

function BootCampSubmenu({ intl }) {
  const initialBootCampLinks = [
    {
      key: 'coding-link',
      href: '/boot-camps/coding?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.coding_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.coding.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.coding_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'microbootcamps-link',
      href: 'boot-camps/ai?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.microbootcamps_link_text'])}
        </>
      ),
      hasNewBadge: true,
      eventName: 'edx.bi.link.site-nav.microbootcamps.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.programs.microbootcamps_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'cybersecurity-link',
      href: '/boot-camps/cybersecurity?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.cybersecurity_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.cybersecurity.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.cybersecurity_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'analytics-link',
      href: '/boot-camps/data-analytics?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.analytics_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.analytics.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.analytics_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'fintech-link',
      href: '/boot-camps/fintech?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.fintech_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.fintech.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.fintech_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'digital-marketing-link',
      href: '/boot-camps/digital-marketing?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.digital-marketing_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.digital-marketing.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.digital-marketing_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'product-managment-link',
      href: '/boot-camps/product-management?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.product-managment_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.product-managment.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.product-managment_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'project-managment-link',
      href: '/boot-camps/tech-project-management?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.project-managment_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.project-managment.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.project-managment_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'ux-ui-link',
      href: '/boot-camps/ux-ui-user-experience?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.ux-ui-user-experience_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.ux-ui-user-experience.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.ux-ui-user-experience_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
    {
      key: 'all-link',
      href: '/boot-camps?linked_from=sitenav',
      label: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.all_link_text'])}
        </>
      ),
      eventName: 'edx.bi.link.site-nav.allBootCamp.clicked',
      subtitle: (
        <>
          {intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.all_subtitle_text'])}
        </>
      ),
      showEs: false,
    },
  ];

  const [bootCampLinks, setBootCampLinks] = useState(initialBootCampLinks);

  useEffect(() => {
    let links = initialBootCampLinks;

    if (intl.locale === 'es') {
      links = links.filter(link => link.showEs === true);
    }

    setBootCampLinks(links);
  }, []);

  return (
    <SiteNavLink
      id="bootcamps"
      className="visible-desktop user-location-visiblity-hidden"
      title={intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp_heading'])}
      url="boot-camps?linked_from=sitenav"
      buttonDescription={intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp_heading.description'])}
      eventName="edx.bi.link.site-nav.boot-camps.clicked"
      htmlHeader={(
        <header className="tab-header">
          <div className="program-header-menu">
            <h2 id="menu-tab-view-programs">
              { intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp_heading'])}
            </h2>
            <ProspectusLink
              className="btn btn-outline-primary"
              href="boot-camps?linked_from=sitenav"
              analytics={{
                eventName: 'edx.bi.link.site-nav.compare-boot-camps.clicked',
              }}
            >
              { intl.formatMessage(messages['prospectus.header.site_header.site_nav.boot-camp.all_link_text'])}
            </ProspectusLink>
          </div>
        </header>
      )}
      htmlContent={(
        <div className="tab-content">
          <ul className="program-list list-group list-group-flush">
            {bootCampLinks && bootCampLinks.map(({
              key,
              href,
              eventName,
              label,
              hasNewBadge,
              subtitle,
            }) => (
              <li className="list-group-item" key={key}>
                <div className="content-block">
                  <h3 className="content-heading">
                    <ProspectusLink
                      className="title-link"
                      href={href}
                      analytics={{
                        eventName,
                      }}
                    >
                      {label}
                      {hasNewBadge && <NewBadge className="position-absolute px-2 mx-2" />}
                    </ProspectusLink>
                  </h3>
                  <p className="text-muted program-description">
                    {subtitle}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    />
  );
}

BootCampSubmenu.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(BootCampSubmenu);
